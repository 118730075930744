/* eslint-disable import/prefer-default-export */

export const selectStateApp = (state) => { 
  if (state?.app) { 
    return state.app;
  }

  // else assume state is already at slice "app".
  return state;
};
