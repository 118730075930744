"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCustomerGroupname = exports.getTechnicianGroupnames = exports.getSupervisorGroupnames = exports.getProjectManagerGroupnames = exports["default"] = exports.customerNameToCustomerGroupname = exports.customerGroupnameToCustomerName = exports.TESTS = void 0;

var _mappings = require("./mappings");

var KEY_TECHNICIAN = 'technician';
var KEY_PROJECT_MANAGER = 'projectManager';
var KEY_SUPERVISOR = 'supervisor';

var getMappingsAndKeys = function getMappingsAndKeys(allMappings, customer) {
  var mappings = allMappings[customer] || allMappings.DEFAULT;
  var keys = Object.keys(mappings);
  return {
    mappings: mappings,
    keys: keys // not currently in use

  };
};

var isCustomerGroupname = function isCustomerGroupname() {
  var customerGroupname = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return /^Customer:/.test(customerGroupname);
};

exports.isCustomerGroupname = isCustomerGroupname;

var customerNameToCustomerGroupname = function customerNameToCustomerGroupname() {
  var customerName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "Customer:".concat(customerName.trim().split(' ').filter(function (v) {
    return !!v;
  }).join('_'));
};

exports.customerNameToCustomerGroupname = customerNameToCustomerGroupname;

var customerGroupnameToCustomerName = function customerGroupnameToCustomerName() {
  var customerGroupname = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return customerGroupname.replace(/^Customer:/, '').split('_').filter(function (v) {
    return v;
  }).join(' ');
};

exports.customerGroupnameToCustomerName = customerGroupnameToCustomerName;

var _getTechnicianGroupnames = function _getTechnicianGroupnames(allMappings, customer) {
  var _getMappingsAndKeys = getMappingsAndKeys(allMappings, customer),
      mappings = _getMappingsAndKeys.mappings;

  return mappings[KEY_TECHNICIAN];
};

var _getProjectManagerGroupnames = function _getProjectManagerGroupnames(allMappings, customer) {
  var _getMappingsAndKeys2 = getMappingsAndKeys(allMappings, customer),
      mappings = _getMappingsAndKeys2.mappings;

  return mappings[KEY_PROJECT_MANAGER];
};

var _getSupervisorGroupnames = function _getSupervisorGroupnames(allMappings, customer) {
  var _getMappingsAndKeys3 = getMappingsAndKeys(allMappings, customer),
      mappings = _getMappingsAndKeys3.mappings;

  return mappings[KEY_SUPERVISOR];
};

var createGroupnameMappingGetters = function createGroupnameMappingGetters() {
  var allMappings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _mappings.MAPPINGS;
  return {
    getTechnicianGroupnames: function getTechnicianGroupnames(customer) {
      return _getTechnicianGroupnames(allMappings, customer);
    },
    getProjectManagerGroupnames: function getProjectManagerGroupnames(customer) {
      return _getProjectManagerGroupnames(allMappings, customer);
    },
    getSupervisorGroupnames: function getSupervisorGroupnames(customer) {
      return _getSupervisorGroupnames(allMappings, customer);
    }
  };
};

var _createGroupnameMappi = createGroupnameMappingGetters(),
    getTechnicianGroupnames = _createGroupnameMappi.getTechnicianGroupnames,
    getProjectManagerGroupnames = _createGroupnameMappi.getProjectManagerGroupnames,
    getSupervisorGroupnames = _createGroupnameMappi.getSupervisorGroupnames;

exports.getSupervisorGroupnames = getSupervisorGroupnames;
exports.getProjectManagerGroupnames = getProjectManagerGroupnames;
exports.getTechnicianGroupnames = getTechnicianGroupnames;
var TESTS = {
  isCustomerGroupname: isCustomerGroupname,
  customerNameToCustomerGroupname: customerNameToCustomerGroupname,
  customerGroupnameToCustomerName: customerGroupnameToCustomerName,
  createGroupnameMappingGetters: createGroupnameMappingGetters
};
exports.TESTS = TESTS;
var _default = {
  isCustomerGroupname: isCustomerGroupname,
  customerNameToCustomerGroupname: customerNameToCustomerGroupname,
  customerGroupnameToCustomerName: customerGroupnameToCustomerName,
  getTechnicianGroupnames: getTechnicianGroupnames,
  getProjectManagerGroupnames: getProjectManagerGroupnames,
  getSupervisorGroupnames: getSupervisorGroupnames,
  TESTS: TESTS
};
exports["default"] = _default;