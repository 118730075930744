/* eslint-disable import/prefer-default-export */
export const protectSliceConfig = (sliceConfig = {}, ROOT_SLICE) => { 
  const {
    prop = ROOT_SLICE,
    arrAncestorProps = [],
    prefix
  } = sliceConfig

  const _prefix = prefix || [arrAncestorProps[arrAncestorProps.length-1], prop].filter(v => v).join('+');

  return {
    prop,
    arrAncestorProps,
    prefix: _prefix
  }
}

export const createSliceSelectors = (sliceConfig = {}) => {
  const {
    prop,
    arrAncestorProps
  } = sliceConfig

  const arrSliceProps = [...(arrAncestorProps || []), prop];

  const arrSliceSelectors = arrSliceProps.reduce((acc, _prop) => {
    const _selectParentSlice = acc?.length > 0 ? acc[acc.length-1] : (state) => state;

    const _selectSlice = (state) => {
      const slice = _selectParentSlice(state);
      if ((slice || {})[_prop]) {
        return slice[_prop];
      }
      return slice; // else assume slice is already at this slice.
    }
    return [...acc, _selectSlice];
  }, []);

  const selectThisSlice = arrSliceSelectors[arrSliceSelectors.length - 1];
  const selectParentSlice = arrSliceSelectors.length > 1 ? arrSliceSelectors[arrSliceSelectors.length - 2] : undefined;

  return {
    selectThisSlice,
    selectParentSlice
  }
};
