/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
// eslint-disable-next-line import/no-cycle
import scanlineUtils from '../scanlineUtils';

import {
	getCustomerRemediationActions,
	getCustomerRemediationActionFields,
	getCustomerRemediationActionsKeys
} from '../../util/customer/CustomerConfig';
import { getCustomer } from '../../util/user';

const {
	downloadReport,
	receiveReport,
	requestReport,
	getReportData,

	receiveReportData,
	requestReportData,
	receiveSaveReportData,
	requestSaveReportData,
	receiveHasChangesReportData,
	requestHasChangesReportData,
	// eslint-disable-next-line no-unused-vars
	getProjectReportData,

	requestDownloadPdf,
	receiveDownloadPdf,
	receiveDownloadPdfError,
	hideDownloadPdfError,
	clearDownloadPdf,

	requestDownloadPdfZip,
	receiveDownloadPdfZip,
	receiveDownloadPdfZipError,
	hideDownloadPdfZipError,
	clearDownloadPdfZip,

	requestDownloadDoc,
	receiveDownloadDoc,
	receiveDownloadDocError,
	hideDownloadDocError,
	clearDownloadDoc,

	requestDownloadDocZip,
	receiveDownloadDocZip,
	receiveDownloadDocZipError,
	hideDownloadDocZipError,
	clearDownloadDocZip,

	requestDownloadClosure,
	receiveDownloadClosure,
	receiveDownloadClosureError,
	hideClosureDownloadErrors,
	clearDownloadClosure,

	requestDownloadD40Pdf,
	receiveDownloadD40Pdf,
	receiveDownloadD40PdfError,
	hideDownloadD40PdfError,
	clearDownloadD40Pdf,

	hideActionPlanDownloadErrors
} = createActions({
	DOWNLOAD_REPORT: (jobId, lineName) => doDownloadReport(jobId, lineName),
	RECEIVE_REPORT: json => ({ json }),
	REQUEST_REPORT: () => ({}),
	GET_REPORT_DATA: (jobId, lineName) => doGetReportData(jobId, lineName),
	RECEIVE_REPORT_DATA: json => ({ json }),
	REQUEST_REPORT_DATA: () => ({}),
	RECEIVE_SAVE_REPORT_DATA: json => ({ json }),
	REQUEST_SAVE_REPORT_DATA: () => ({}),
	RECEIVE_HAS_CHANGES_REPORT_DATA: hasChanges => ({ hasChanges }),
	REQUEST_HAS_CHANGES_REPORT_DATA: () => ({}),
	GET_PROJECT_REPORT_DATA: (projectId, projectName) =>
		doGetProjectReportData(projectId, projectName),

	REQUEST_DOWNLOAD_PDF: () => {},
	RECEIVE_DOWNLOAD_PDF: link => ({ link }),
	RECEIVE_DOWNLOAD_PDF_ERROR: error => ({ error }),
	HIDE_DOWNLOAD_PDF_ERROR: () => ({}),
	CLEAR_DOWNLOAD_PDF: () => ({}),

	REQUEST_DOWNLOAD_PDF_ZIP: () => {},
	RECEIVE_DOWNLOAD_PDF_ZIP: link => ({ link }),
	RECEIVE_DOWNLOAD_PDF_ZIP_ERROR: error => ({ error }),
	HIDE_DOWNLOAD_PDF_ZIP_ERROR: () => ({}),
	CLEAR_DOWNLOAD_PDF_ZIP: () => ({}),

	REQUEST_DOWNLOAD_DOC: () => {},
	RECEIVE_DOWNLOAD_DOC: link => ({ link }),
	RECEIVE_DOWNLOAD_DOC_ERROR: error => ({ error }),
	HIDE_DOWNLOAD_DOC_ERROR: () => ({}),
	CLEAR_DOWNLOAD_DOC: () => ({}),

	REQUEST_DOWNLOAD_DOC_ZIP: () => {},
	RECEIVE_DOWNLOAD_DOC_ZIP: link => ({ link }),
	RECEIVE_DOWNLOAD_DOC_ZIP_ERROR: error => ({ error }),
	HIDE_DOWNLOAD_DOC_ZIP_ERROR: () => ({}),
	CLEAR_DOWNLOAD_DOC_ZIP: () => ({}),

	REQUEST_DOWNLOAD_CLOSURE: () => {},
	RECEIVE_DOWNLOAD_CLOSURE: link => ({ link }),
	RECEIVE_DOWNLOAD_CLOSURE_ERROR: error => ({ error }),
	HIDE_CLOSURE_DOWNLOAD_ERRORS: () => ({}),
	CLEAR_DOWNLOAD_CLOSURE: () => ({}),

	REQUEST_DOWNLOAD_D40_PDF: () => {},
	RECEIVE_DOWNLOAD_D40_PDF: link => ({ link }),
	RECEIVE_DOWNLOAD_D40_PDF_ERROR: error => ({ error }),
	HIDE_DOWNLOAD_D40_PDF_ERROR: () => ({}),
	CLEAR_DOWNLOAD_D40_PDF: () => ({}),

	HIDE_ACTION_PLAN_DOWNLOAD_ERRORS: () => ({})
});

const doDownloadReport = (jobId, lineName, data, callback = () => {}) => {
	return dispatch => {
		dispatch(requestReport());

		return scanlineUtils.downloadReport(jobId, lineName, data, (err, res) => {
			if (err) {
				callback(err);
				// console.error(err);
			} else {
				callback(null, res);
				dispatch(receiveReport(res));
			}
		});
	};
};

const createRefsActionTypes = (_customer) => {
  const customer = _customer || getCustomer() || '';

	const actionFields = getCustomerRemediationActionFields(customer);
	const actionFieldKeys = Object.keys(actionFields);

	return actionFieldKeys.map(key => {
		const actionField = actionFields[key];
		return {
			...actionField,
			key
		};
	});
};

const createRefsActions = (_customer) => {
	const customer = _customer || getCustomer() || '';

	const actionKeys = getCustomerRemediationActionsKeys(customer);
	const actions = getCustomerRemediationActions(customer);

	return actionKeys.map(key => {
		const action = actions[key];
		return {
			...action,
			key
		};
	});
};

const createRefsProperty = (customer) => {
	return {
		actionTypes: createRefsActionTypes(customer),
		actions: createRefsActions(customer)
	};
};

const createReportV3RequestObject = (
	meta = {},
	formData, // no longer passed in request, I suspect it is pulled from psql during report download.
	customer,
	survey,
	surveys,
	isBcCalculatedByDepol
) => {
	const { linename, downloadType, extensionType } = meta;

	return {
		lineName: linename,
		downloadType,
		extensionType,
		actionPlanCustomer: customer,
		survey,
		surveys,
		isBcCalculatedByDepol,
		refs: createRefsProperty(customer)
	};
};

const createClosureReportV2RequestObject = (meta = {}, formData, customer) => {
	const { linename } = meta;

	const newMeta = {
		customer
	};

	return {
		lineName: linename,
		state: {
			meta: newMeta,
			formData
		}
	};
};

const doPostDownloadPdf = (
	linename,
	lineGuid,
	projectId,
	formData,
	customer,
	survey,
	surveys = [],
	isBcCalculatedByDepol,
	openFileDownloadFallback,
	onDownloadComplete = () => {}
) => {
	const meta = {
		linename,
		downloadType: 'master',
		extensionType: 'pdf'
	};

	const requestObj = createReportV3RequestObject(
		meta,
		formData,
		customer,
		survey,
		surveys,
		isBcCalculatedByDepol
	);

	return dispatch => {
		dispatch(hideActionPlanDownloadErrors());
		dispatch(requestDownloadPdf());

		const { jobId } = survey || {};

		const additionalProps = {
			downloadAction: 'actionPlanV3',
			reportTypeKey: 'ACTION_PLAN',
			lineGuid,
			projectId,
			surveyGuid: jobId
		};

		return scanlineUtils.downloadV2Report(
			requestObj,
			additionalProps,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadPdfError(err));
					onDownloadComplete();
				} else {
					const { url } = response;
					dispatch(receiveDownloadPdf(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
					onDownloadComplete();
				}
			}
		);
	};
};

const doPostDownloadPdfZip = (
	linename,
	lineGuid,
	projectId,
	formData,
	customer,
	survey,
	surveys = [],
	isBcCalculatedByDepol,
	openFileDownloadFallback,
	onDownloadComplete = () => {}
) => {
	const meta = {
		linename,
		downloadType: 'zip',
		extensionType: 'pdf'
	};
	const requestObj = createReportV3RequestObject(
		meta,
		formData,
		customer,
		survey,
		surveys,
		isBcCalculatedByDepol
	);
	return dispatch => {
		dispatch(hideActionPlanDownloadErrors());
		dispatch(requestDownloadPdfZip());

		const { jobId } = survey || {};
		const additionalProps = {
			downloadAction: 'actionPlanV3',
			reportTypeKey: 'ACTION_PLAN',
			lineGuid,
			projectId,
			surveyGuid: jobId
		};

		return scanlineUtils.downloadV2Report(
			requestObj,
			additionalProps,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadPdfZipError(err));
					onDownloadComplete();
				} else {
					const { url } = response;
					dispatch(receiveDownloadPdfZip(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
					onDownloadComplete();
				}
			}
		);
	};
};

const doPostDownloadDoc = (
	linename,
	lineGuid,
	projectId,
	formData,
	customer,
	survey,
	surveys = [],
	isBcCalculatedByDepol,
	openFileDownloadFallback,
	onDownloadComplete = () => {}
) => {
	const meta = {
		linename,
		downloadType: 'master',
		extensionType: 'word'
	};
	const requestObj = createReportV3RequestObject(
		meta,
		formData,
		customer,
		survey,
		surveys,
		isBcCalculatedByDepol
	);
	return dispatch => {
		dispatch(hideActionPlanDownloadErrors());
		dispatch(requestDownloadDoc());

		const { jobId } = survey || {};
		const additionalProps = {
			downloadAction: 'actionPlanV3',
			reportTypeKey: 'ACTION_PLAN',
			lineGuid,
			projectId,
			surveyGuid: jobId
		};

		return scanlineUtils.downloadV2Report(
			requestObj,
			additionalProps,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadDocError(err));
					onDownloadComplete();
				} else {
					const { url } = response;
					dispatch(receiveDownloadDoc(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
					onDownloadComplete();
				}
			}
		);
	};
};

const doPostDownloadDocZip = (
	linename,
	lineGuid,
	projectId,
	formData,
	customer,
	survey,
	surveys = [],
	isBcCalculatedByDepol,
	openFileDownloadFallback,
	onDownloadComplete = () => {}
) => {
	const meta = {
		linename,
		downloadType: 'zip',
		extensionType: 'word'
	};
	const requestObj = createReportV3RequestObject(
		meta,
		formData,
		customer,
		survey,
		surveys,
		isBcCalculatedByDepol
	);
	return dispatch => {
		dispatch(hideActionPlanDownloadErrors());
		dispatch(requestDownloadDocZip());

		const { jobId } = survey || {};
		const additionalProps = {
			downloadAction: 'actionPlanV3',
			reportTypeKey: 'ACTION_PLAN',
			lineGuid,
			projectId,
			surveyGuid: jobId
		};

		return scanlineUtils.downloadV2Report(
			requestObj,
			additionalProps,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadDocZipError(err));
					onDownloadComplete();
				} else {
					const { url } = response;
					dispatch(receiveDownloadDocZip(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
					onDownloadComplete();
				}
			}
		);
	};
};

const doPostDownloadClosurePdf = (
	linename,
	formData,
	customer = getCustomer(),
	openFileDownloadFallback = () => {}
) => {
	const meta = {
		linename
	};

	const requestObj = createClosureReportV2RequestObject(
		meta,
		formData,
		customer
	);
	return dispatch => {
		dispatch(hideClosureDownloadErrors());
		dispatch(requestDownloadClosure());

		return scanlineUtils.downloadV2ClosureReport(
			requestObj,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadClosureError(err));
				} else {
					const { url } = response;
					dispatch(receiveDownloadClosure(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
				}
			}
		);
	};
};

const doPostDownloadD40Pdf = (
	linename,
	lineGuid,
	projectId,
	formData,
	customer,
	survey,
	surveys = [],
	isBcCalculatedByDepol,
	openFileDownloadFallback,
	onDownloadComplete = () => {}
) => {
	const meta = {
		linename,
		downloadType: 'd40',
		extensionType: 'pdf'
	};

	const requestObj = createReportV3RequestObject(
		meta,
		formData,
		customer,
		survey,
		surveys,
		isBcCalculatedByDepol
	);

	return dispatch => {
		dispatch(hideActionPlanDownloadErrors());
		dispatch(requestDownloadD40Pdf());

		const { jobId } = survey || {};

		const additionalProps = {
			downloadAction: 'actionPlanV3',
			reportTypeKey: 'ACTION_PLAN',
			lineGuid,
			projectId,
			surveyGuid: jobId
		};

		return scanlineUtils.downloadV2Report(
			requestObj,
			additionalProps,
			(err, response) => {
				if (err) {
					dispatch(receiveDownloadD40PdfError(err));
					onDownloadComplete();
				} else {
					const { url } = response;
					dispatch(receiveDownloadD40Pdf(url));
					const w = window || { open: () => {} };
					if (!w.open(url)) {
						openFileDownloadFallback(w, url);
					}
					onDownloadComplete();
				}
			}
		);
	};
};
const doGetReportData = (
	jobId,
	lineId,
	projectid,
	type,
	callback = () => {}
) => {
	return dispatch => {
		dispatch(requestReportData());

		return scanlineUtils.getReportDataV2(
			jobId,
			lineId,
			projectid,
			type,
			(err, res) => {
				if (err) {
					callback(err);
					// console.error(err);
				} else {
					callback(null, res);
					dispatch(receiveReportData(res));
				}
			}
		);
	};
};

const doGetProjectReportData = (
	projectId,
	projectName,
	type,
	callback = () => {}
) => {
	return dispatch => {
		return scanlineUtils.getProjectReportData(
			projectId,
			projectName,
			type,
			(err, res) => {
				if (err) {
					callback(err);
					// console.error(err);
				} else {
					callback(null, res);
					dispatch(receiveReportData(res));
				}
			}
		);
	};
};

const thunkPromiseSaveReportData = (
	{
		jobId,
		lineId,
		projectId,
		reportType,
		reportStatusKey = 'IN_PROGRESS',
		data
	},
	callback = () => {}
) => {
	return dispatch => {
		dispatch(requestSaveReportData());

		return scanlineUtils
			.saveReportDataV2({
				jobId,
				lineId,
				projectId,
				reportType,
				reportStatusKey,
				data
			})
			.then(res => {
				dispatch(receiveSaveReportData(res));
				callback(undefined, res);
				return res;
			})
			.catch(err => {
				callback(err);
			});
	};
};

const thunkPromiseHasChangesReportData = (
	{
		jobId,
		lineId,
		projectId,
		reportType,
		reportStatusKey = 'IN_PROGRESS',
		data
	},
	callback = () => {}
) => {
	return dispatch => {
		dispatch(requestHasChangesReportData());

		return scanlineUtils
			.hasChangesReportDataV2({
				jobId,
				lineId,
				projectId,
				reportType,
				reportStatusKey,
				data
			})
			.then(({ hasChanges } = {}) => {
				dispatch(receiveHasChangesReportData(hasChanges));
				callback(undefined, hasChanges);
				return hasChanges;
			})
			.catch(err => {
				callback(err);
			});
	};
};

export {
	downloadReport,
	receiveReport,
	requestReport,
	getReportData,
	receiveReportData,
	requestReportData,
	receiveSaveReportData,
	requestSaveReportData,
	receiveHasChangesReportData,
	requestHasChangesReportData,
	doDownloadReport,
	doGetReportData,
	thunkPromiseSaveReportData,
	thunkPromiseHasChangesReportData,
	doGetProjectReportData,
	doPostDownloadPdf,
	requestDownloadPdf,
	receiveDownloadPdf,
	receiveDownloadPdfError,
	hideDownloadPdfError,
	clearDownloadPdf,
	doPostDownloadPdfZip,
	requestDownloadPdfZip,
	receiveDownloadPdfZip,
	receiveDownloadPdfZipError,
	hideDownloadPdfZipError,
	clearDownloadPdfZip,
	doPostDownloadDoc,
	requestDownloadDoc,
	receiveDownloadDoc,
	receiveDownloadDocError,
	hideDownloadDocError,
	clearDownloadDoc,
	doPostDownloadDocZip,
	requestDownloadDocZip,
	receiveDownloadDocZip,
	receiveDownloadDocZipError,
	hideDownloadDocZipError,
	clearDownloadDocZip,
	doPostDownloadClosurePdf,
	requestDownloadClosure,
	receiveDownloadClosure,
	receiveDownloadClosureError,
	hideClosureDownloadErrors,
	clearDownloadClosure,
	doPostDownloadD40Pdf,
	requestDownloadD40Pdf,
	receiveDownloadD40Pdf,
	receiveDownloadD40PdfError,
	hideDownloadD40PdfError,
	clearDownloadD40Pdf,
	hideActionPlanDownloadErrors
};
