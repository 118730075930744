import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';
import TransLabel from '../../../common/TransLabel';
import { LIST_FILTER_REMEDIATION_STATUSES } from '../../../../../util/customer/customer.remediation.actions.targa';

const STATUSES_POSITION_MAP = (LIST_FILTER_REMEDIATION_STATUSES || []).reduce((acc, _status, idx) => {
	acc[_status] = idx + 1;
	return acc;
}, {});

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Targa Group Status';
const PROP_KEY = 'targaGroupStatusCounts';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT }) => {
  const value = useMemo(() => { 
    const _map = (item || {})[PROP_KEY] || {};
    const statuses = Object.keys(_map);
    return statuses.map(s => `${_map[s]} - ${s}`).join('~');
  }, [item]);

	return (
		<td>
			<HelperRenderStringList strList={value} direction="vertical" />
		</td>
	);
};

const SortFunction = item => {
	const positionMultiplier = 1000;
    const _map = (item || {})[PROP_KEY] || {};
    const statuses = Object.keys(_map);
	let sortPriority = 0;
	statuses.forEach((_status) => {
		const count = _map[_status];
		const priority = (STATUSES_POSITION_MAP[_status] * positionMultiplier) + count;
		if (priority > sortPriority) {
			sortPriority = priority
		}
	})
	return sortPriority;
};


const ColTargaGroupStatusesWithCounts = {
	TH,
	TD,
	CSV_PROP: PROP_KEY,
	DEFAULT_HEADER,
	SortFunction
};

export default ColTargaGroupStatusesWithCounts;
