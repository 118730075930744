/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setIsLoadingLoaded, clear } from './actions';
import { listDdbCustomersEntities } from './api';
import { selectIsLoaded, selectIsLoading } from '../selectors';
import { listPsqlCustomersEntities } from '../../../../../admin/api/customersEntities';

const thunkLoad = () => dispatch => {
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);

	listPsqlCustomersEntities().then(({ data: psqlData }) => {
		dispatch(set(psqlData));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
	const isLoaded = selectIsLoaded(getState());
	const isLoading = selectIsLoading(getState());
	if (!isLoaded && !isLoading) {
		dispatch(thunkLoad());
	}
};

export { thunkLoad, thunkLoadIfNotLoaded };
