// MAIN
import mainReducerParts from './Main/reducerParts';
import { initialState as mainInitialState } from './Main/initialState';

// ChartsSettings
import chartsSettingsReducerParts from './slices/ChartsSettings/reducerParts';
import { initialState as chartsSettingsInitialState } from './slices/ChartsSettings/initialState';

// ChartsBelowCriterions
import chartsBelowCriterionsReducerParts from './slices/ChartsBelowCriterions/reducerParts';
import { initialState as chartsBelowCriterionsInitialState } from './slices/ChartsBelowCriterions/initialState';

// ChartsRemediations
import chartsRemediationsReducerParts from '../../../_reusable/ScanAppAndReportStates/ChartsRemediations/reducerParts';
import { initialState as chartsRemediationsInitialState } from '../../../_reusable/ScanAppAndReportStates/ChartsRemediations/initialState';

// ActionPlanChangeNotesV2
import actionPlanChangeNotesV2ReducerParts from '../ActionPlanChangeNotesV2/reducerParts';
import { initialState as actionPlanChangeNotesV2InitialState } from '../ActionPlanChangeNotesV2/initialState';

export const initialState = {
	...actionPlanChangeNotesV2InitialState,
	...chartsRemediationsInitialState,
	...chartsBelowCriterionsInitialState,
	...chartsSettingsInitialState,
	...mainInitialState
};

export const reducerParts = {
	...actionPlanChangeNotesV2ReducerParts,
	...chartsRemediationsReducerParts,
	...chartsBelowCriterionsReducerParts,
	...chartsSettingsReducerParts,
	...mainReducerParts
};

export default {
	initialState,
	reducerParts
};
