import { transLabel as _transLabel } from "aegion_common_utilities/lib/LabelTransform";
import { getCustomer } from "../../util/user";


// automatically determines customer 
export const transLabel = (label, config = {}) => { 
  const _config = config.customer ? config : { ...config, customer: getCustomer() };
  return _transLabel(label, _config);
}

export default transLabel;