/* eslint-disable eqeqeq */
const isDefined = v => v !== undefined && v !== null;
const xorNotDefined = (a, b) =>
	(!isDefined(a) && isDefined(b)) || (isDefined(a) && !isDefined(b));

const gtNotDefinedCompare = (a, b) => {
	if (isDefined(a) && !isDefined(b)) {
		return true;
	}
	return false;
};

const ltNotDefinedCompare = (a, b) => {
	if (!isDefined(a) && isDefined(b)) {
		return true;
	}
	return false;
};

export const gt = (a, b, opts = {}) => {
	const { invertNotDefined = false } = opts;
	const notDefinedCompare = invertNotDefined
		? ltNotDefinedCompare
		: gtNotDefinedCompare;

	if (xorNotDefined(a, b)) {
		return notDefinedCompare(a, b);
	}

	return a > b;
};

export const lt = (a, b, opts = {}) => {
	const { invertNotDefined = false } = opts;
	const notDefinedCompare = invertNotDefined
		? gtNotDefinedCompare
		: ltNotDefinedCompare;

	if (xorNotDefined(a, b)) {
		return notDefinedCompare(a, b);
	}

	return a < b;
};
const equivalent = (a, b, opts) => !gt(a, b, opts) && !lt(a, b, opts);

const createSortCompare = (dir, opts = {}) => {
	const { invertNotDefined = false } = opts;
	const comparator = dir === 'ASC' ? gt : lt;
	const compare = (val1, val2) => {
		const a = typeof val1 === 'string' ? val1.toUpperCase() : val1;
		const b = typeof val2 === 'string' ? val2.toUpperCase() : val2;
		if (equivalent(a, b, { invertNotDefined })) {
			return 0;
		}

		if (comparator(a, b, { invertNotDefined })) {
			return 1;
		}

		return -1;
	};

	return compare;
};

export default createSortCompare;
