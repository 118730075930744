"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MAPPINGS = void 0;
/* eslint-disable import/prefer-default-export */

var MAPPINGS = {
  DEFAULT: {
    technician: ['CorrosionTechnician'],
    projectManager: ['ProjectManager'],
    supervisor: [] // none

  },
  Targa: {
    technician: ['CorrosionTechnician', 'Permission:Scan.Targa.Read_Only', 'Permission:Scan.Targa.Technician'],
    projectManager: ['ProjectManager', 'Permission:Scan.Targa.Project_Manager'],
    supervisor: ['Permission:Scan.Targa.Supervisor']
  }
};
exports.MAPPINGS = MAPPINGS;