import { LIST_FILTER_REMEDIATION_STATUSES as LIST_FILTER_TARGA_GROUP_STATUES } from "../../../../../util/customer/customer.remediation.actions.targa";
import { getCustomerRemediationActionTypes } from "../../../../../util/customer/CustomerConfig";
import { getCustomer } from "../../../../../util/user";

/** ***************************
 * AEGION
 */

export const injectAegionFilters = (filters) => {
  let results = filters;
  const assumeIsAegionRequest = filters?.customers?.length > 0; // customers should not be returned unless the request was made by an aegion user.

  if (assumeIsAegionRequest) {
    results = { ...filters };
    Object.keys(filters).forEach(filterGroup => {
      if (filterGroup !== 'customers') {
        const companyKeys = Object.keys(filters[filterGroup]);
        const aegionItems = companyKeys.map(comp => filters[filterGroup][comp]).flat();
        results[filterGroup].Aegion = aegionItems;
      }
    })
  }

  return results;
};


/** ***************************
 * CLIENT INJECTED FILTERS
 */

const KEY_PROJECT_TYPES = 'projectTypes';
const KEY_REMEDIATION_TYPES = 'remediationTypes';
const KEY_TARGA_GROUP_STATUSES = 'targaGroupStatuses';


const _createProjectTypesFilter = () => {
  return [
    { id: 'LINE', name: 'LINE'},
    { id: 'LINE_BASED_PROJECT', name: 'LINE_BASED_PROJECT'},
    { id: 'PROJECT', name: 'PROJECT'}
  ];
};

const _injectProjectTypesFilter = (filters) => { 
  const modifiedFilters = { ...filters };
  modifiedFilters[KEY_PROJECT_TYPES] = _createProjectTypesFilter();
  return modifiedFilters;
};

const _createTargaGroupStatusesFilter = () => { 
  return LIST_FILTER_TARGA_GROUP_STATUES.map(s => ({ id: s, name: s }));
}

const _injectTargaGroupStatusesFilter = (filters) => {
  const modifiedFilters = { ...filters };
  modifiedFilters[KEY_TARGA_GROUP_STATUSES] = {};
  modifiedFilters[KEY_TARGA_GROUP_STATUSES].Targa = _createTargaGroupStatusesFilter();
  modifiedFilters[KEY_TARGA_GROUP_STATUSES].Aegion = _createTargaGroupStatusesFilter();
  return modifiedFilters;
};


const _createRemediationTypesFilter = (customer) => { 
  const remediationActionTypes = getCustomerRemediationActionTypes(customer);
  return remediationActionTypes.map(type => ({ id: type, name: type }));
};

const _injectRemediationTypes = (filters) => {
  const modifiedFilters = { ...filters };
  const customers = filters?.customers || [];
  const customer = getCustomer();
  const assumeIsAegionRequest = customers.length > 0; // customers should not be returned unless the request was made by an aegion user.
  modifiedFilters[KEY_REMEDIATION_TYPES] = {};

  if (assumeIsAegionRequest) {
    customers.forEach(c => {
      modifiedFilters[KEY_REMEDIATION_TYPES][c.name] = _createRemediationTypesFilter(c.name);
    });
  } else {
    modifiedFilters[KEY_REMEDIATION_TYPES][customer] = _createRemediationTypesFilter(customer);
  }

  return modifiedFilters;
};

export const injectClientFilters = (filters) => { 
  let modifiedFilters = _injectProjectTypesFilter(filters);
  modifiedFilters = _injectRemediationTypes(modifiedFilters);
  modifiedFilters = _injectTargaGroupStatusesFilter(modifiedFilters);
  return modifiedFilters;
};