/* eslint-disable import/no-cycle */
import { createActions as createReduxActions } from 'redux-actions';
import { isEmptyObject } from './util';

export const _createActions = ({ sliceConfig, SELECTORS = {} }) => {
  const { prefix } = sliceConfig;
  const { setNotes, setShowModal, setSideEffects } = createReduxActions(
    {
      // NOTES
      SET_NOTES: (chartId, notes) => {
        return { chartId, notes };
      },
      SET_SHOW_MODAL: (chartId, show) => {
        return { chartId, show };
      },
      SET_SIDE_EFFECTS: (chartId, onCancelChangeNotes, onSaveChangeNotes) => {
        return { chartId, onCancelChangeNotes, onSaveChangeNotes };
      }
    },
    {
      prefix
    }
  );
  
  const thunkInitState = chartId => (dispatch, getState) => {
    const { app } = getState();
    const state = SELECTORS.selectChartActionPlanChangeNotes(app, { chartId });
    if (isEmptyObject(state)) {
      dispatch(setNotes(chartId));
    }
  };
  
  const thunkClearNotes = chartId => dispatch => { 
    dispatch(setNotes(chartId))
  };
  
  const thunkClearSideEffects = chartId => dispatch => { 
    dispatch(setSideEffects(chartId));
  }

  return {
    thunkInitState,
    setNotes,
    thunkClearNotes,
    setShowModal,
    setSideEffects,
    thunkClearSideEffects
  }
}

export default _createActions;
