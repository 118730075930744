const TAB = 'AnalysisPage';

const _getMethod = (fname) => {
  return ((window?.TabSync || {})[TAB] || {})[fname];
}

const sendSelectedReading = (survey, reading) => {
  const _sendSelectedReading = _getMethod('sendSelectedReading');
  if (_sendSelectedReading) { 
    _sendSelectedReading(survey, reading);
  }
}

const sendMapPopoutStateSync = (SOURCE_TAB_TYPE = undefined) => {
  const _sendMapPopoutStateSync = _getMethod('sendMapPopoutStateSync');
  if (_sendMapPopoutStateSync) {     
    // use set timeout to prevent calling sendMapPopoutStateSync's store.getState to early.  It can be called before the store is available. 
    setTimeout(() => { 
      _sendMapPopoutStateSync(SOURCE_TAB_TYPE);
    }, 1)
  }
}

const sendUpdateSelectedSurveys = (survey, reading) => {
  const _sendUpdateSelectedSurveys = _getMethod('sendUpdateSelectedSurveys');
  if (_sendUpdateSelectedSurveys) { 
    setTimeout(() => { 
      _sendUpdateSelectedSurveys(survey, reading);
    }, 1)
  }
}

const sendUpdateFocusedChart = () => {
  const _sendUpdateFocusedChart = _getMethod('sendUpdateFocusedChart');
  if (_sendUpdateFocusedChart) { 
    setTimeout(() => { 
      _sendUpdateFocusedChart();
    }, 1)
  }
}

const sendUpdateHoveredChart = () => {
  const _sendUpdateHoveredChart = _getMethod('sendUpdateHoveredChart');
  if (_sendUpdateHoveredChart) { 
    setTimeout(() => { 
      _sendUpdateHoveredChart();
    }, 1)
  }
}

const sendRequestReloadState = () => {
  const _sendRequestReloadState = _getMethod('sendRequestReloadState');
  if (_sendRequestReloadState) { 
    setTimeout(() => { 
      _sendRequestReloadState();
    }, 1)
  }
}

const sendSelectGroup = (group, action) => {
  const _sendSelectGroup = _getMethod('sendSelectGroup');
  if (_sendSelectGroup) { 
    setTimeout(() => { 
      _sendSelectGroup(group, action);
    }, 1)
  }
}



const GlobalTabSync = {
  sendMapPopoutStateSync,
  sendUpdateSelectedSurveys,
  sendSelectedReading,
  sendUpdateFocusedChart,
  sendUpdateHoveredChart,
  sendRequestReloadState,
  sendSelectGroup
}

export default GlobalTabSync;

export { 
  GlobalTabSync
}
