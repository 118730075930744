import scanlineMapUtils from '../../../../../../mapUtils';
import { getChartId } from '../../../../../../utils/chart';
import { selectDefaultSurvey } from '../../../../../AppAnalysisPage/selectors/surveys';
import { cleanContent } from './helpers';

// PRIVATE
const computeAssumedSurveyId = (pageLoadState, appState) => {
	const defaultSurvey = selectDefaultSurvey(appState) || {};
	return defaultSurvey.id;
};

export const transformPageLoadStateFromServer = (
	serverPageLoadState,
	getState
) => {
	const state = getState();
	const { app } = state;
	const {
		content = {},
		depricatedSurveyGuid,
		depricatedSurveyType
	} = serverPageLoadState;

	const assumedSurveyId = computeAssumedSurveyId(content, app);
	const fallbackSurveyId =
		depricatedSurveyGuid && depricatedSurveyType
			? scanlineMapUtils.createSurveyId(
					'CIS',
					depricatedSurveyType,
					depricatedSurveyGuid
			  )
			: undefined;

	const surveyId = content.surveyId || fallbackSurveyId || assumedSurveyId;
	const chartId = content.chartId || getChartId({ id: surveyId });

	return {
		...cleanContent(content),
		surveyId,
		chartId
	};
};

export default {
	transformPageLoadStateFromServer
};
