export const ROOT_SLICE = 'ActionPlanChangeNotesV2';

export const EMPTY_OBJECT = {};

export const EMPTY_ARRAY = [];

export const LAMBDA = () => {};

export default {
  ROOT_SLICE,
	EMPTY_OBJECT,
	EMPTY_ARRAY,
	LAMBDA
};
