import { createSelector } from 'reselect';
import { getSurveyIdFromChartId } from '../../../utils/chart';
import {
	// selectPrimarySurveyFromDerived,
	selectSurvey,
	selectSurveysForAnalytics
} from './surveys';
import { selectMyReadingDataStructures } from './alignedReadings';
import { selectStateApp } from './main';

const isDefined = v => v !== undefined && v !== null;

export const selectIsReadingsLoading = state => { 
  const slice = selectStateApp(state);
  const { isReadingsLoading } = slice;
  return isReadingsLoading;
}

export const selectIsReadingsInitialized = state => { 
  const slice = selectStateApp(state);
  const { isReadingsInitialized } = slice;
  return isReadingsInitialized;
}

export const selectReadingsStore = state => {
  const slice = selectStateApp(state);
	const { readings } = slice;
	return readings;
};

export const selectReadingsMapStore = state => {
  const slice = selectStateApp(state);
	const { readingsMap } = slice;
	return readingsMap;
};

export const selectReadingsIndexMapStore = state => {
  const slice = selectStateApp(state);
	const { readingsIndexMap } = slice;
	return readingsIndexMap;
};

export const selectReadingsWithChartGapsStore = state => {
  const slice = selectStateApp(state);
  const { readingsWithChartGaps } = slice;
	return readingsWithChartGaps;
};

export const selectSimplifiedReadingsStore = state => {
  const slice = selectStateApp(state);
  const { simplifiedReadings } = slice;
	return simplifiedReadings;
};

export const getId = val => {
	if (typeof val === 'object') {
		return val.id;
	}
	return val;
};

export const selectReadings = (state, survey) => {
	const surveyId = getId(survey);
	const readingsStore = selectReadingsStore(state) || {};
	return readingsStore[surveyId];
};

export const selectReadingsMap = (state, survey) => {
	const surveyId = getId(survey);
	const readingsMapStore = selectReadingsMapStore(state) || {};
	return readingsMapStore[surveyId];
};

export const selectReadingsIndexMap = (state, survey) => {
	const surveyId = getId(survey);
	const readingsStore = selectReadingsIndexMapStore(state) || {};
	return readingsStore[surveyId];
};

export const selectReadingsForAnalytics = createSelector(
	selectSurveysForAnalytics,
	selectReadingsStore,
	(surveys, readingsStore) => {
		if (!surveys || surveys.length === 0) {
			return {};
		}

		const surveyIds = surveys.map(s => s.id);
		const readingsKeys = Object.keys(readingsStore);
		const filteredReadingsStore = readingsKeys.reduce((acc, key) => {
			const hasMatch = surveyIds.indexOf(key) > -1;
			if (hasMatch) {
				return {
					...acc,
					[key]: readingsStore[key]
				};
			}

			return acc;
		}, {});

		return filteredReadingsStore;
	}
);

export const selectReadingsStationIdIntegers = (state, surveyId) => { 
  const slice = selectStateApp(state);
  const { readingsStationIdIntegers } = slice;
	return readingsStationIdIntegers[surveyId];
}

const calcDelta = (v1, v2) => {
	const max = Math.max(v1, v2);
	const min = Math.min(v1, v2);
	return Math.abs(max - min);
};

const closest = (target, v1, v2) => {
	const deltaV1 = calcDelta(target, v1);
	const deltaV2 = calcDelta(target, v2);
	return deltaV1 <= deltaV2 ? v1 : v2;
};

const evalClosestInt = (map, intId, closestInt, xFloor) => {
	if (isDefined(map[intId])) {
		if (!isDefined(closestInt)) {
			return intId;
		}
		if (closest(xFloor, intId, closestInt) === intId) {
			return intId;
		}
	}
	return closestInt;
};

const getClosestIntForward = (start, end, map, xFloor) => {
	let closestInt;
	for (let intId = start; intId <= end; intId += 1) {
		closestInt = evalClosestInt(map, intId, closestInt, xFloor);
	}

	return closestInt;
};

const getClosestIntBackwards = (start, end, map, xFloor) => {
	let closestInt;
	for (let intId = end; intId >= start; intId -= 1) {
		closestInt = evalClosestInt(map, intId, closestInt, xFloor);
	}

	return closestInt;
};

const getClosestId = (map, closestInt, x) => {
	let closestId;
	const ids = map[closestInt];
	ids.forEach(id => {
		if (!isDefined(closestId)) {
			closestId = id;
		} else if (closest(x, id, closestId) === id) {
			closestId = id;
		}
	});
	return closestId;
};

const selectMap = (state, surveyId) => {
	const survey = selectSurvey(state, surveyId);

	// let primarySurvey = survey;
	// if (survey.isDerived) {
	//	primarySurvey = selectPrimarySurveyFromDerived(stateApp, survey);
	// }

	return selectReadingsStationIdIntegers(state, survey.id);
};

export const selectClosestReadingBySurveyId = (
	state,
	surveyId,
	x,
	distance = 5
) => {
	if (!surveyId) {
		return undefined;
	}

	const survey = selectSurvey(state, surveyId);
	const readingDataStructures = selectMyReadingDataStructures(survey, state);

	const map = readingDataStructures.readingsStationIdIntegers;
	const xFloor = Math.floor(x);
	const start = xFloor - distance;
	const end = xFloor + distance;

	const closestInt = getClosestIntForward(start, end, map, xFloor);

	if (!isDefined(closestInt)) {
		return undefined;
	}

	const closestId = getClosestId(map, closestInt, x);

	if (!isDefined(closestId)) {
		return undefined;
	}

	const readingsMap = readingDataStructures.readingsMap || {};
	const reading = readingsMap[closestId];

	return reading;
};

export const selectClosestReading = (state, chartId, x, distance) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	return selectClosestReadingBySurveyId(state, surveyId, x, distance);
};

export const selectClosestReadings = (
	state,
	chartId,
	xMin,
	xMax,
	distance = 5
) => {
	const surveyId = getSurveyIdFromChartId(chartId);
	if (!surveyId) {
		return undefined;
	}
	const map = selectMap(state, surveyId);
	const xMinFloor = Math.floor(xMin);
	const xMaxFloor = Math.floor(xMax);
	const start = xMinFloor - distance;
	const end = xMaxFloor + distance;

	const closestMinInt = getClosestIntForward(start, end, map, xMinFloor);
	const closestMaxInt = getClosestIntBackwards(start, end, map, xMaxFloor);

	if (!isDefined(closestMinInt) || !isDefined(closestMaxInt)) {
		return undefined;
	}

	const closestMinId = getClosestId(map, closestMinInt, xMin);
	const closestMaxId = getClosestId(map, closestMaxInt, xMax);

	if (!isDefined(closestMinId) || !isDefined(closestMaxId)) {
		return undefined;
	}

	const readingsMap = selectReadingsMap(state, {
		id: surveyId
	});
	const minReading = readingsMap[closestMinId];
	const maxReading = readingsMap[closestMaxId];

	return { minReading, maxReading };
};
