"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transLabel = exports["default"] = exports.TESTS = void 0;

var _buildMappings = _interopRequireDefault(require("./mappings/buildMappings"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _get = function _get(obj, key) {
  return (obj || {})[key];
};

var _transLabel = function _transLabel(label, MAPPINGS) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$customer = _ref.customer,
      customer = _ref$customer === void 0 ? 'NONE' : _ref$customer,
      _ref$abbrv = _ref.abbrv,
      abbrv = _ref$abbrv === void 0 ? false : _ref$abbrv,
      view = _ref.view;

  var BASEMAPPINGS = MAPPINGS[customer] || MAPPINGS.GENERAL;
  var BASEMAPPINGSVIEW = view && BASEMAPPINGS !== null && BASEMAPPINGS !== void 0 && BASEMAPPINGS.VIEWS[view] ? BASEMAPPINGS === null || BASEMAPPINGS === void 0 ? void 0 : BASEMAPPINGS.VIEWS[view] : BASEMAPPINGS;

  if (abbrv) {
    return _get(BASEMAPPINGSVIEW === null || BASEMAPPINGSVIEW === void 0 ? void 0 : BASEMAPPINGSVIEW.ABBRV, label) || _get(BASEMAPPINGS === null || BASEMAPPINGS === void 0 ? void 0 : BASEMAPPINGS.ABBRV, label) || _get(BASEMAPPINGSVIEW === null || BASEMAPPINGSVIEW === void 0 ? void 0 : BASEMAPPINGSVIEW.LABELS, label) || _get(BASEMAPPINGS === null || BASEMAPPINGS === void 0 ? void 0 : BASEMAPPINGS.LABELS, label) || label;
  }

  return _get(BASEMAPPINGSVIEW === null || BASEMAPPINGSVIEW === void 0 ? void 0 : BASEMAPPINGSVIEW.LABELS, label) || _get(BASEMAPPINGS === null || BASEMAPPINGS === void 0 ? void 0 : BASEMAPPINGS.LABELS, label) || label;
};

var createTransLabel = function createTransLabel() {
  var MAPPINGS = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _buildMappings["default"];
  return function (label, config) {
    return _transLabel(label, MAPPINGS, config);
  };
};

var transLabel = createTransLabel();
exports.transLabel = transLabel;
var TESTS = {
  createTransLabel: createTransLabel
};
exports.TESTS = TESTS;
var _default = {
  transLabel: transLabel,
  TESTS: TESTS
};
exports["default"] = _default;