/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setJobsModalVisibility as acSetJobsModalVisibility } from '../../../../actions/mapComponent';

import { AimLink } from '../../../../../commons/components/AimButtons';

import { generateAnalysisPageUrl } from '../../../JobsList/components/ReportsList/List/util';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const LAMBDA = () => {};
const DEFAULT_HEADER = 'Line';
const PROP_KEY = 'parentName';

const createAnalysisPagePayload = reportAgg => {
	return {
		parent_type: (reportAgg.parentTypeKey || '')
			.toUpperCase()
			.replaceAll('_', ' '),
		parent_name: reportAgg.parentName,
		reportType: reportAgg.reportTypeName,
		isProject: reportAgg.parentTypeKey !== 'LINE',
		line: {
			id: reportAgg.lineGuid,
			name: reportAgg.lineName
		},
		project: {
			id: reportAgg.projectId,
			name: reportAgg.projectName
		},
		primarySurvey: {
			jobId: reportAgg.jobId
		},
		technician: {
			userid: reportAgg.ownerUsername
		}
	};
};

const generateDetailsLink = (reportagg = {}) => {
	return generateAnalysisPageUrl(createAnalysisPagePayload(reportagg));
};

/** ****************************
 * REDUX
 */

const mapStateToProps = () => ({});

const actionCreators = { setJobsModalVisibility: acSetJobsModalVisibility };
const mapDispatchToProps = dispatch =>
	bindActionCreators(actionCreators, dispatch);

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({
	item = EMPTY_OBJECT,
	onClick = LAMBDA,
	setJobsModalVisibility
} = {}) => {
	const { value, hrefLineDetails } = useMemo(
		() => ({
			value: (item || {})[PROP_KEY] || '',
			hrefLineDetails: generateDetailsLink(item)
		}),
		[item]
	);

	const goToLineDetails = useCallback(() => {
		if (hrefLineDetails) {
			// debugger;
			const path = hrefLineDetails;
			// @todo - stabilize state changes so we can use react router to switch lines
			// history.push(path);

			// currently we have many intermittent issues when switching from one line to another without reloading page
			// temporarily force page refresh until we can resolve.
			window.location = path;
		}
	}, [hrefLineDetails]);

	const onGoToAnalysis = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			if (setJobsModalVisibility) {
				setJobsModalVisibility(false);
			}
			goToLineDetails();
		},
		[goToLineDetails, setJobsModalVisibility]
	);

	return (
		<td className='w-column-linename' onClick={onClick}>
			<AimLink
				blType="LINK"
				bsStyle="default"
				href={hrefLineDetails}
				onClick={onGoToAnalysis}
			>
				{value}
			</AimLink>
		</td>
	);
};

const ColLine = { TH, TD, CSV_PROP: PROP_KEY, DEFAULT_HEADER };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ColLine));
