/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { createChangeNotesState } from './util';

const fallback = (obj1, obj2) => {
  if (!obj1 || !Object.keys(obj1).length) {
    return obj2;
  }
  return obj1;
}

export const _createReducerParts = ({ sliceConfig, ACTIONS = {}, SELECTORS = {} }) => {
  const { prop: sliceProp } = sliceConfig;
  
  // ************
  // PARTS
  // ************
  return {
    [ACTIONS.setNotes]: (state, { payload: { chartId, notes } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const obj = fallback(SELECTORS.selectChartActionPlanChangeNotes(state, { chartId }), createChangeNotesState());
  
      return {
        ...state,
        [sliceProp]: {
          ...slice,
          [chartId]: {
            ...obj,
            notes
          }
        }
      };
    },
    
    [ACTIONS.setShowModal]: (state, { payload: { chartId, show } }) => {
      const slice = SELECTORS.selectThisSlice(state);
      const obj = fallback(SELECTORS.selectChartActionPlanChangeNotes(state, { chartId }), createChangeNotesState());

      return {
        ...state,
        [sliceProp]: {
          ...slice,
          [chartId]: {
            ...obj,
            showModal: show
          }
        }
      };
    },
    [ACTIONS.setSideEffects]: (
      state,
      { payload: { chartId, onCancelChangeNotes, onSaveChangeNotes } }
    ) => {
      const slice = SELECTORS.selectThisSlice(state);
      const obj = fallback(SELECTORS.selectChartActionPlanChangeNotes(state, { chartId }), createChangeNotesState());

      return {
        ...state,
        [sliceProp]: {
          ...slice,
          [chartId]: {
            ...obj,
            sideEffects: {
              onCancelChangeNotes,
              onSaveChangeNotes
            }
          }
        }
      };
    }
  };
}


export default _createReducerParts;
