/* eslint-disable import/no-cycle */
import { EMPTY_OBJECT } from '../slices/Charts/Main/constants';
import { selectStateApp } from './main';
import { selectDerivedSurvey } from './surveys';


// PRIMARY

export const selectSeriesStore = state => {
  const slice = selectStateApp(state);
  if (slice?.series) { 
    return slice.series;
  }

  // else assume state is already at slice "series".
  return slice || EMPTY_OBJECT;
};

export const selectSeriesIndexMapStore = state => {
  const slice = selectStateApp(state);
  if (slice?.seriesIndexMap) { 
    return slice.seriesIndexMap;
  }

  // else assume state is already at slice "seriesIndexMap".
  return slice || EMPTY_OBJECT;
};


export const selectSeries = (state, survey) => {
	const series = selectSeriesStore(state);
	return survey ? series[survey.id] : undefined;
};

export const selectSeriesIndexMap = (state, survey) => {
  const seriesIndexMap = selectSeriesIndexMapStore(state);
	return survey ? seriesIndexMap[survey.id] : undefined;
};

// COMPUTED
export const selectComputedSeries = (
	state,
	survey,
	useInterpolated,
	useNormalized
) => {
  const stateApp = selectStateApp(state);
	if (survey) {
		let prop = 'series';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalized';
		} else if (useInterpolated) {
			prop = 'interpSeries';
		} else if (useNormalized) {
			prop = 'seriesNormalized';
		}
		return stateApp[prop][survey.id];
	}
	return undefined;
};

export const selectComputedSeriesIndexMap = (
	state,
	survey,
	useInterpolated,
	useNormalized
) => {
  const stateApp = selectStateApp(state);
	if (survey) {
		let prop = 'seriesIndexMap';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalizedIndexMap';
		} else if (useInterpolated) {
			prop = 'interpSeriesIndexMap';
		} else if (useNormalized) {
			prop = 'seriesNormalizedIndexMap';
		}
		return stateApp[prop][survey.id];
	}
	return undefined;
};

// SPATIAL
export const selectSpatialSeries = (state, survey) => {
	const spatialSurvey = selectDerivedSurvey(state, survey, 'SPATIAL');
	return selectSeries(state, spatialSurvey);
};

export const selectSpatialSeriesIndexMap = (state, survey) => {
	const spatialSurvey = selectDerivedSurvey(state, survey, 'SPATIAL');
	return selectSeriesIndexMap(state, spatialSurvey);
};

// DEPOL
export const selectDepolSeriesFromCisSurvey = (
	state,
	cisSurvey,
	useInterpolated,
	useNormalized
) => {
  const stateApp = selectStateApp(state);
	const depolSurvey = selectDerivedSurvey(state, cisSurvey, 'DEPOL');

	if (depolSurvey) {
		let prop = 'series';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalized';
		} else if (useInterpolated) {
			prop = 'interpSeries';
		} else if (useNormalized) {
			prop = 'seriesNormalized';
		}
		return stateApp[prop][depolSurvey.id];
	}
	return undefined;
};

export const selectDepolSeriesIndexMapFromCisSurvey = (
	state,
	cisSurvey,
	useInterpolated,
	useNormalized
) => {
  const stateApp = selectStateApp(state);
	const depolSurvey = selectDerivedSurvey(state, cisSurvey, 'DEPOL');

	if (depolSurvey) {
		let prop = 'seriesIndexMap';
		if (useInterpolated && useNormalized) {
			prop = 'interpSeriesNormalizedIndexMap';
		} else if (useInterpolated) {
			prop = 'interpSeriesIndexMap';
		} else if (useNormalized) {
			prop = 'seriesNormalizedIndexMap';
		}
		return stateApp[prop][depolSurvey.id];
	}
	return undefined;
};
