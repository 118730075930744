/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import orderBy from 'lodash/orderBy';

import { set, setCustomer, setIsLoadingLoaded, clear } from './actions';
import { fetchReportsAggs } from '../../../api/reportsaggs';
import { selectCustomer, selectIsLoaded, selectIsLoading } from '../selectors';
import {
	selectSortColumn,
	selectSortDirection
} from '../../ReportsAggsSorting/selectors';
import { SORT_DIRECTION } from '../../ReportsAggsSorting/constants';
import { getColumnBase } from '../../../../../components/ReportsAggsTable/components/Columns/utils';

function getSortAttr(sortColumn) {
	const ColumnBase = getColumnBase(sortColumn);
	return ColumnBase.SortFunction || ColumnBase.CSV_PROP;
}

function doSortItems(items, sortColumn, sortDirection = SORT_DIRECTION.ASC) {
	if (sortColumn === undefined) {
		return items;
	}
	return orderBy(items, [getSortAttr(sortColumn)], [sortDirection]);
}

const thunkSet = items => (dispatch, getState) => {
	const sortColumn = selectSortColumn(getState());
	const sortDirection = selectSortDirection(getState());
	const sortedList = doSortItems(items, sortColumn, sortDirection);
	dispatch(set({ items: sortedList }));
};

const thunkLoad = () => (dispatch, getState) => {
	const customerName = selectCustomer(getState());

	dispatch(clear());
	dispatch(setCustomer(customerName));
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
	fetchReportsAggs(customerName).then(items => {
		dispatch(thunkSet(items));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
	const isLoaded = selectIsLoaded(getState());
	const isLoading = selectIsLoading(getState());

	if (!isLoaded && !isLoading) {
		dispatch(thunkLoad());
	}
};

const thunkSetCustomerName = customerName => dispatch => {
	dispatch(setCustomer(customerName));
	dispatch(thunkLoad());
};

export { thunkSet, thunkLoad, thunkLoadIfNotLoaded, thunkSetCustomerName };
