/* eslint-disable import/no-named-as-default-member */
/* eslint-disable prefer-destructuring */
import { createSelector } from 'reselect';
import CORE from './selectors.core';
import { hasSettingAutoRemoveIliData, hasSettingEnableActionPlan, hasSettingEnableClosureReport, hasSettingEnableMyProjects, hasSettingEnableSharedStateForAnalysisAndReports, hasSettingFeatureClosureCopyActionPlan } from '../../../../../../util/customerAppSettings/scanline';
import { getCustomer } from '../../../../../../util/user';

const EMPTY_OBJECT = {};

const getSourceSettingsForCustomer = (customerSettingsSource, analysisPageCustomer) => {
  return (customerSettingsSource || {})[analysisPageCustomer]?.SCANLINE || EMPTY_OBJECT;
}


/**
 * IN APP REPORTS
 */

export const selectHasActionPlansForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasActionPlans,
  (analysisPageCustomer, customerSettingsSource, defaultHasActionPlans) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingEnableActionPlan(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasActionPlans;
  }
);

export const selectHasClosureReportsForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasClosureReports,
  (analysisPageCustomer, customerSettingsSource, defaultHasClosureReports) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingEnableClosureReport(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasClosureReports;
  }
);


/**
 * MISCELLANEOUS SETTINGS
 */
export const selectHasSharedStateForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasSharedState,
  (analysisPageCustomer, customerSettingsSource, defaultHasSharedState) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingEnableSharedStateForAnalysisAndReports(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasSharedState;
  }
);

export const selectHasBehaviorAutoRemoveIliDataForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasBehaviorAutoRemoveIliData,
  (analysisPageCustomer, customerSettingsSource, defaultHasBehaviorAutoRemoveIliData) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingAutoRemoveIliData(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasBehaviorAutoRemoveIliData;
  }
);

export const selectHasFeatureClosureCopyActionPlanForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasFeatureClosureCopyActionPlan,
  (analysisPageCustomer, customerSettingsSource, defaultHasFeatureClosureCopyActionPlan) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingFeatureClosureCopyActionPlan(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasFeatureClosureCopyActionPlan;
  }
);

export const selectHasFeatureCreateAsProjectForAnalysisPage = createSelector(
  CORE.selectAnalysisPageCustomer,
  CORE.selectCustomerSettingsSource,
  CORE.selectHasTabMyProjects,
  (analysisPageCustomer, customerSettingsSource, defaultHasTabMyProjects) => {
    if (analysisPageCustomer && getCustomer() === 'Aegion') {
      return hasSettingEnableMyProjects(getSourceSettingsForCustomer(customerSettingsSource, analysisPageCustomer));
    }

    return defaultHasTabMyProjects;
  }
);

export default {
  selectHasActionPlansForAnalysisPage,
  selectHasClosureReportsForAnalysisPage,
  selectHasSharedStateForAnalysisPage,
  selectHasBehaviorAutoRemoveIliDataForAnalysisPage,
  selectHasFeatureClosureCopyActionPlanForAnalysisPage,
}
