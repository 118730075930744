/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// TECHNICIANS
import { ROOT_SLICE as ROOT_SLICE_TECHNICIANS } from './slices/Technicians/constants';
import { initialState as Technicians } from './slices/Technicians/initialState';
import TechniciansReducerParts from './slices/Technicians/reducerParts';

// PROJECT MANAGERS
import { ROOT_SLICE as ROOT_SLICE_PROJECT_MANAGERS } from './slices/ProjectManagers/constants';
import { initialState as ProjectManagers } from './slices/ProjectManagers/initialState';
import ProjectManagersReducerParts from './slices/ProjectManagers/reducerParts';

// TECHNICIANS
import { ROOT_SLICE as ROOT_SLICE_SUPERVISORS } from './slices/Supervisors/constants';
import { initialState as Supervisors } from './slices/Supervisors/initialState';
import SupervisorsReducerParts from './slices/Supervisors/reducerParts';

const initialState = {
	[ROOT_SLICE_TECHNICIANS]: {
		...Technicians
	},
	[ROOT_SLICE_PROJECT_MANAGERS]: {
		...ProjectManagers
	},
	[ROOT_SLICE_SUPERVISORS]: {
		...Supervisors
	},

};

const reducerParts = {
	...TechniciansReducerParts,
	...ProjectManagersReducerParts,
	...SupervisorsReducerParts,
};

export { ROOT_SLICE } from './constants';
export default handleActions(reducerParts, initialState);
