import { createSelector } from 'reselect';

import { ROOT_SLICE } from './constants';
import PRIVATE from './selectors.util';
import { selectRootSliceLPR } from '../../Main/selectors';
import { selectFilteredReportsAggs } from '../ReportsAggsFiltersUI/selectors';

const consoleLogDebugger = (txt, debug) => { 
  if (debug) {
    console.log(ROOT_SLICE);
    // debugger;
  }
}

export const selectThisSlice = (state, debug = false) => { 
  const slice = selectRootSliceLPR(state, debug);
  if (slice[ROOT_SLICE]) { 
    consoleLogDebugger('found', debug);
    return slice[ROOT_SLICE];
  }

  // else assume state is already LinkSurveysTableBehaviors.
  consoleLogDebugger('not found', debug);
  return state;
};

export const selectExpandedRowMapById = (state) => { 
  const slice = selectThisSlice(state);
  const { expandedRowMapById } = slice;
  return expandedRowMapById;
}

export const selectReportsAggsWExpanded = createSelector(
  selectFilteredReportsAggs,
  selectExpandedRowMapById,
  (
    filteredReportsAggs,
    expandedRowMapById
  ) => {
    const expandedRowMapByIdWithOffsets = PRIVATE.computeExpandedRowMapByIdWithOffsets(expandedRowMapById);
    const linesAggsWithExpandedRows = PRIVATE.injectExpandedRows(filteredReportsAggs, expandedRowMapByIdWithOffsets);
    return linesAggsWithExpandedRows;
  });

export default {
  selectExpandedRowMapById,
  selectReportsAggsWExpanded
};
