/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';
import { DEFAULT_THRESHOLDS, EMPTY_OBJECT } from './constants';
import { isDefined } from './util';
import { selectSurvey } from '../../../../selectors/surveys';
import { selectStateApp } from '../../../../selectors/main';

export const selectChartsSettings = (state) => {
  const slice = selectStateApp(state);
  if (slice?.chartsSettings) { 
    return slice.chartsSettings;
  }

  // else assume state is already at slice "chartsSettings".
  return slice;
};

export const selectChartSettings = (state, { chartId } = {}) => {
	const chartsSettings = selectChartsSettings(state);
	const chartSettings = chartsSettings[chartId] || EMPTY_OBJECT;

	return chartSettings;
};

export const selectThresholds = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { thresholds } = chartSettings;

	return isDefined(thresholds) ? thresholds : DEFAULT_THRESHOLDS;
};

/**
 * @deprecated
 * @todo We need to move away from single threshold per chart
 */
export const selectThreshold = (state, { chartId } = {}) => {
	const thresholds = selectThresholds(state, { chartId });
	return thresholds[0];
};

export const selectShowDecimatedData = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { showDecimatedData } = chartSettings;
	return showDecimatedData;
};

export const selectRequiresThresholdChangeConfirmation = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { thresholdsPendingChangeRequiresConfirmation } = chartSettings;

	return !!thresholdsPendingChangeRequiresConfirmation;
};

export const selectUseThreshold = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { useThreshold } = chartSettings;

	return !!useThreshold;
};

export const selectUseShadow = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { useShadow } = chartSettings;

	return !!useShadow;
};

export const selectUseSawtooth = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { useSawtooth } = chartSettings;

	return !!useSawtooth;
};

export const selectHasSawtoothData = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { hasSawtoothData } = chartSettings;

	return !!hasSawtoothData;
};

export const selectChartPrimarySurveyId = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { primarySurveyId } = chartSettings;

	if (!primarySurveyId) {
		// todo - need fallback functionality here for when chart settings is not set from backend - and primarySurveyId is not stored in settings.
		// debugger;
	}

	return primarySurveyId;
};

export const selectChartPrimarySurvey = (state, { chartId } = {}) => {
	const primarySurveyId = selectChartPrimarySurveyId(state, { chartId });
	return selectSurvey(state, primarySurveyId);
};

export const selectChartDepolSurveyId = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { depolSurveyId } = chartSettings;

	return depolSurveyId;
};

export const selectChartDepolSurvey = (state, { chartId } = {}) => {
	const depolSurveyId = selectChartDepolSurveyId(state, { chartId });
	return selectSurvey(state, depolSurveyId);
};

export const selectUseInterpolatedDepol = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { useInterpolatedDepol } = chartSettings;

	return useInterpolatedDepol;
};

export const selectUseNormalizedDepol = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { useNormalizedDepol } = chartSettings;

	return useNormalizedDepol;
};

// @deprecated - this is required due to previous arch combined with readings table modal implementation being inline in Map.jsx
export const selectUseNormalizedDepolMap = createSelector(
	selectChartsSettings,
	chartsSettings => {
		const chartIds = Object.keys(chartsSettings);
		const useNormalizedDepolMap = chartIds.reduce((acc, chartId) => {
			const chartSettings = chartsSettings[chartId];
			const { primarySurveyId, useNormalizedDepol } = chartSettings;
			acc[primarySurveyId] = useNormalizedDepol;
			return acc;
		}, {});
		return useNormalizedDepolMap;
	}
);

// @deprecated - this is required due to previous arch combined with readings table modal implementation being inline in Map.jsx
export const selectUseInterpolatedDepolMap = createSelector(
	selectChartsSettings,
	chartsSettings => {
		const chartIds = Object.keys(chartsSettings);
		const useInterpolatedDepolMap = chartIds.reduce((acc, chartId) => {
			const chartSettings = chartsSettings[chartId];
			const { primarySurveyId, useInterpolatedDepol } = chartSettings;
			acc[primarySurveyId] = useInterpolatedDepol;
			return acc;
		}, {});
		return useInterpolatedDepolMap;
	}
);

export const selectSelectedRemediationGroup = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { selectedRemediationGroup } = chartSettings;

	return selectedRemediationGroup;
};

export const selectSelectedRemediationGroupIndex = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { selectedRemediationGroupIndex } = chartSettings;

	return selectedRemediationGroupIndex;
};

export const selectInputThresholdResetToken = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { inputThresholdResetToken } = chartSettings;
	return inputThresholdResetToken;
};

export const selectSeriesVisibilityResetToken = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { seriesVisibilityResetToken } = chartSettings;
	return seriesVisibilityResetToken;
};

export const selectSeriesVisibilityDataStoreForChart = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { seriesVisibility } = chartSettings;
	return seriesVisibility;
};

export const selectSeriesVisibility = (
	state,
	{ chartId } = {},
	{
		dataSetKey, // could be a survey id - could be the name of a series
		propKey // name of property which is plotted on chart
	}
) => {
	const seriesVisibilityDataStore = selectSeriesVisibilityDataStoreForChart(
		state,
		{ chartId }
	);

	const isVisible = ((seriesVisibilityDataStore || {})[dataSetKey] || {})[
		propKey
	];

	return isDefined(isVisible) ? isVisible : true; // for now assume is visible
};

export const selectIsVisible = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { isVisible } = chartSettings;

	return isVisible;
};

export const selectShowSpatial = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { showSpatial } = chartSettings;

	return showSpatial;
};

export const selectBelowCriterionComparator = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { belowCriterionComparator } = chartSettings;
	return belowCriterionComparator;
};

export const selectShowConfirmation = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { showConfirmation } = chartSettings;

	return showConfirmation;
};

export const selectConfirmationMessageKey = (state, { chartId } = {}) => {
	const chartSettings = selectChartSettings(state, { chartId });
	const { confirmationMessageKey } = chartSettings;

	return confirmationMessageKey;
};

export default {
	selectChartSettings,
	selectUseThreshold,
	selectUseShadow,
	selectThresholds,
	selectUseSawtooth,
	selectChartPrimarySurveyId,
	selectChartPrimarySurvey,
	selectChartDepolSurveyId,
	selectChartDepolSurvey,
	selectUseNormalizedDepol,
	selectUseInterpolatedDepol,
	selectSeriesVisibilityDataStoreForChart,
	selectSeriesVisibility,
	selectIsVisible,
	selectUseNormalizedDepolMap,
	selectUseInterpolatedDepolMap,
	selectShowConfirmation
};
