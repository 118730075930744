import { selectStateApp } from '../../../AppAnalysisPage/selectors/main';
import { ROOT_SLICE } from './constants';

// @deprecated
export const selectStateSlice = selectStateApp;

export const selectActionPlanSyncSlice = state => {
  const slice = selectStateApp(state);
  if (slice && slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already at slice "actionPlanSync".
  return slice;
};

export const selectIsSyncEnabled = state => {
	const { isSyncEnabled } = selectActionPlanSyncSlice(state);
	return isSyncEnabled;
};
