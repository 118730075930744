/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import _ from 'lodash';

import { config } from 'aegion_common_utilities/lib/MapProcessingUtil';

import { createActions } from 'redux-actions';
import {
	_isValidPrimarySurvey,
	_isValidTargetSurvey
} from '../redux/AppAnalysisPage/util/alignedReadings.align';
import { normalizeIliData } from '../mapUtils.alignReadings.ili';
import { generateReadingDataStructures } from '../redux/AppAnalysisPage/util/readings.parse';
import {
	selectReadings,
	selectAlignedReadingsThreshold
} from '../redux/AppAnalysisPage/selectors/alignedReadings';
import {
	selectXYMinMaxPayloadsForAlignedReadings,
	selectXYMinMaxPayloadsForAlignedReadingsFiltered
} from '../redux/AppAnalysisPage/selectors/alignedReadings.selectXYMinMaxPayloads';
import { thunkBulkSetDataXYMinMax } from '../redux/AppAnalysisPage/slices/StoreXYMinMax/actions';
import { thunkDecimateAlignedILISurvey } from '../redux/AppAnalysisPage/slices/ReadingsStore/actions/thunks.cisDecimate';

const {
	deleteAlignedReadings,
	setAlignedReadings,
	setAlignedReadingsFiltered,
	setDisplayAlignedReadingsInRibbon,
	setRenderAlignedReadingsToSurvey,
	setRenderReadingsFilter,
	setAlignedRibbonVisibility,
	setAlignedReadingsThreshold,
	setAlignedReadingsStats
} = createActions({
	DELETE_ALIGNED_READINGS: targetSurvey => ({ targetSurvey }),
	SET_ALIGNED_READINGS: (
		primarySurvey,
		targetSurvey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	) => ({
		primarySurvey,
		targetSurvey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	}),
	SET_ALIGNED_READINGS_FILTERED: (
		primarySurvey,
		targetSurvey,
		filteredDatasetsByKey
	) => ({ primarySurvey, targetSurvey, filteredDatasetsByKey }),
	SET_DISPLAY_ALIGNED_READINGS_IN_RIBBON: (targetSurveyId, display) => ({
		targetSurveyId,
		display
	}),
	SET_RENDER_ALIGNED_READINGS_TO_SURVEY: (targetSurvey, primarySurvey) => ({
		targetSurvey,
		primarySurvey
	}),
	SET_RENDER_READINGS_FILTER: (survey, filterKey) => ({
		survey,
		filterKey
	}),
	SET_ALIGNED_RIBBON_VISIBILITY: (primarySurvey, visibility) => ({
		primarySurvey,
		visibility
	}),
	SET_ALIGNED_READINGS_THRESHOLD: (
		primarySurvey,
		threshold,
		tempThreshold
	) => ({
		primarySurvey,
		threshold,
		tempThreshold
	}),
	SET_ALIGNED_READINGS_STATS: (primarySurvey, targetSurvey, stats) => ({
		primarySurvey,
		targetSurvey,
		stats
	})
});

const thunkComputeXYMinMaxForAlignedReadings = survey => (
	dispatch,
	getState
) => {
	const { app: stateApp } = getState();
	const arrBoundaryPayloads = selectXYMinMaxPayloadsForAlignedReadings(
		survey,
		stateApp
	);
	const bulkChanges = arrBoundaryPayloads.reduce((acc, { key, boundaries }) => {
		acc[key] = boundaries;
		return acc;
	}, {});
	dispatch(thunkBulkSetDataXYMinMax(bulkChanges));
};

const thunkComputeXYMinMaxForAlignedReadingsFiltered = survey => (
	dispatch,
	getState
) => {
	const { app: stateApp } = getState();
	const arrBoundaryPayloads = selectXYMinMaxPayloadsForAlignedReadingsFiltered(
		survey,
		stateApp
	);
	const bulkChanges = arrBoundaryPayloads.reduce((acc, { key, boundaries }) => {
		acc[key] = boundaries;
		return acc;
	}, {});
	dispatch(thunkBulkSetDataXYMinMax(bulkChanges));
};

const alignData = (primarySurvey, targetSurvey, getState, threshold) => {
	if (
		!_isValidPrimarySurvey(primarySurvey) ||
		!_isValidTargetSurvey(targetSurvey)
	) {
		return undefined;
	}
	const { app } = getState();
	const primaryReadings = selectReadings(primarySurvey, app);
	const targetReadings = selectReadings(targetSurvey, app);

	const { alignedTargetReadings, stats } = normalizeIliData(
		primaryReadings,
		targetReadings,
		{
			threshold,
			ensureAscendingIndexes: true,
			algorithm: config.GEO_ALGORITHMS.TURF
		}
	);

	const [
		readings,
		readingsWithGeoGaps,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	] = generateReadingDataStructures(targetSurvey, alignedTargetReadings);

	return {
		dataSets: {
			readings,
			readingsWithGeoGaps,
			readingsWithChartGaps,
			readingsMap,
			readingsIndexMap,
			readingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap,
			series,
			seriesIndexMap,
			readingsStationIdIntegers
		},
		stats
	};
};

// Note: If the survey data, changes, we need to break this key, otherwise we will be displaying stale data
const _createAlignDataMemoizationKey = (
	primarySurvey,
	targetSurvey,
	getState,
	threshold
) => {
	return `${(primarySurvey || {}).id}-${(targetSurvey || {}).id}-${threshold}`;
};

const memAlignData = _.memoize(alignData, _createAlignDataMemoizationKey);

const doAlignReadings = (primarySurvey, targetSurvey) => (
	dispatch,
	getState
) => {
	const { app } = getState();

	const { threshold } = selectAlignedReadingsThreshold(primarySurvey, app);

	const response = memAlignData(
		primarySurvey,
		targetSurvey,
		getState,
		threshold
	);

	if (!response) {
		return;
	}

	const { stats, dataSets } = response;

	const {
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readingsWithGeoGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	} = dataSets;
	dispatch(
		setAlignedReadings(
			primarySurvey,
			targetSurvey,
			readings,
			readingsWithChartGaps,
			readingsMap,
			readingsIndexMap,
			readingsWithChartGapsIndexMap,
			readingsWithGeoGapsIndexMap,
			series,
			seriesIndexMap,
			readingsStationIdIntegers
		)
	);
	dispatch(setAlignedReadingsStats(primarySurvey, targetSurvey, stats));
	dispatch(thunkComputeXYMinMaxForAlignedReadings(targetSurvey));

	const { meta } = targetSurvey;
	const filteredDatasetsByKey = meta.computeFilteredDataSets(
		targetSurvey,
		readings
	);
	if (filteredDatasetsByKey) {
		dispatch(
			setAlignedReadingsFiltered(
				primarySurvey,
				targetSurvey,
				filteredDatasetsByKey
			)
		);
		dispatch(thunkComputeXYMinMaxForAlignedReadingsFiltered(targetSurvey));
	}

	dispatch(thunkDecimateAlignedILISurvey(targetSurvey, primarySurvey));
};

const updateAlignedReadingsThreshold = (
	primarySurvey,
	threshold
) => dispatch => {
	dispatch(setAlignedReadingsThreshold(primarySurvey, threshold, null));
};

const updateAlignedReadingsTempThreshold = (
	primarySurvey,
	threshold,
	tempThreshold
) => dispatch => {
	dispatch(
		setAlignedReadingsThreshold(primarySurvey, threshold, tempThreshold)
	);
};

export {
	setAlignedReadings,
	doAlignReadings,
	deleteAlignedReadings,
	setAlignedReadingsFiltered,
	setDisplayAlignedReadingsInRibbon,
	setRenderAlignedReadingsToSurvey,
	setRenderReadingsFilter,
	setAlignedRibbonVisibility,
	updateAlignedReadingsThreshold,
	updateAlignedReadingsTempThreshold,
	setAlignedReadingsThreshold,
	setAlignedReadingsStats
};
