import { getCustomer } from "../../../../../util/user";

const sessionCustomer = getCustomer();

/**
 * ROOT_SLICE
 */
const ROOT_SLICE = 'ReportsAggsColumnSettings';

/**
 * COLUMN_KEYS
 */

const _COLUMN_KEYS = {
  LINE: 'LINE',
  CUSTOMER: 'CUSTOMER',
  ENTITY: 'ENTITY',
  AREA: 'AREA',
  PIPE: 'PIPE',
  REGULATORY_STATUS: 'REGULATORY_STATUS',
  JOB_NUMBER: 'JOB_NUMBER',
  START_END: 'START_END',
  SURVEY_TYPES: 'SURVEY_TYPES',
  PMS: 'PMS',
  CTS: 'CTS',
  TAGS: 'TAGS',
  REPORT_TYPE: 'REPORT_TYPE',
  REPORT_STATUS: 'REPORT_STATUS',
  REPORT_DATE: 'REPORT_DATE',
  COMPLIANCE_DATE: 'COMPLIANCE_DATE',
  REMEDIATION_TYPES: 'REMEDIATION_TYPES',
  REMEDIATION_TYPE_COUNTS: 'REMEDIATION_TYPE_COUNTS',
  PROJECT_TYPE: 'PROJECT_TYPE',
  TARGA_GROUP_STATUSES: 'TARGA_GROUP_STATUSES'
};

const AEGION_ONLY_COLUMN_KEYS = {
  [_COLUMN_KEYS.CUSTOMER]: _COLUMN_KEYS.CUSTOMER
};

const REQUIRED_COLUMN_KEYS = {
  [_COLUMN_KEYS.LINE]: _COLUMN_KEYS.LINE
};

const DEFAULT_COLUMN_KEYS = {
  [_COLUMN_KEYS.LINE]: _COLUMN_KEYS.LINE,
  [_COLUMN_KEYS.CUSTOMER]: _COLUMN_KEYS.CUSTOMER,
  [_COLUMN_KEYS.JOB_NUMBER]: _COLUMN_KEYS.JOB_NUMBER,
  [_COLUMN_KEYS.START_END]: _COLUMN_KEYS.START_END,
  [_COLUMN_KEYS.SURVEY_TYPES]: _COLUMN_KEYS.SURVEY_TYPES,
  [_COLUMN_KEYS.PMS]: _COLUMN_KEYS.PMS,
  [_COLUMN_KEYS.CTS]: _COLUMN_KEYS.CTS,
  [_COLUMN_KEYS.REPORT_TYPE]: _COLUMN_KEYS.REPORT_TYPE,
  [_COLUMN_KEYS.REPORT_STATUS]: _COLUMN_KEYS.REPORT_STATUS,
  [_COLUMN_KEYS.COMPLIANCE_DATE]: _COLUMN_KEYS.COMPLIANCE_DATE,
  [_COLUMN_KEYS.REMEDIATION_TYPES]: _COLUMN_KEYS.REMEDIATION_TYPES,
  [_COLUMN_KEYS.PROJECT_TYPE]: _COLUMN_KEYS.PROJECT_TYPE,
}

const COLUMN_KEYS = { ..._COLUMN_KEYS };

if (sessionCustomer !== 'Aegion') { 
  // remove AEGION_ONLY_COLUMN_KEYS
  Object.keys(AEGION_ONLY_COLUMN_KEYS).forEach(key => {
    delete DEFAULT_COLUMN_KEYS[key];
    delete COLUMN_KEYS[key];
  });
}

// Add Missing REQUIRED_COLUMN_KEYS
Object.keys(REQUIRED_COLUMN_KEYS).forEach(key => {
  if (!DEFAULT_COLUMN_KEYS[key]) {
    DEFAULT_COLUMN_KEYS[key] = key;
  }
});

// Targa 
if (sessionCustomer !== 'Aegion' && sessionCustomer !== 'Targa') { 
  delete COLUMN_KEYS[_COLUMN_KEYS.TARGA_GROUP_STATUSES];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.TARGA_GROUP_STATUSES];
}
if (sessionCustomer === 'Targa') { 
  DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.ENTITY] = _COLUMN_KEYS.ENTITY;
  DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.AREA] = _COLUMN_KEYS.AREA;
  DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.PIPE] = _COLUMN_KEYS.PIPE;
  DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.REGULATORY_STATUS] = _COLUMN_KEYS.REGULATORY_STATUS;

  delete COLUMN_KEYS[_COLUMN_KEYS.PROJECT_TYPE];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.PROJECT_TYPE];
  delete COLUMN_KEYS[_COLUMN_KEYS.REPORT_TYPE];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.REPORT_TYPE];
  delete COLUMN_KEYS[_COLUMN_KEYS.REPORT_STATUS];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.REPORT_STATUS];
  delete COLUMN_KEYS[_COLUMN_KEYS.REMEDIATION_TYPES];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.REMEDIATION_TYPES];
  delete COLUMN_KEYS[_COLUMN_KEYS.REMEDIATION_TYPE_COUNTS];
  delete DEFAULT_COLUMN_KEYS[_COLUMN_KEYS.REMEDIATION_TYPE_COUNTS];
}

/**
 * COLUMN_KEYS_DEFAULT_SORT
 */

const DEFAULT_COLUMN_KEYS_SORT_LIST = [
  _COLUMN_KEYS.LINE,
  _COLUMN_KEYS.PROJECT_TYPE,
  _COLUMN_KEYS.REPORT_TYPE,
  _COLUMN_KEYS.REPORT_STATUS,
  _COLUMN_KEYS.REPORT_DATE,
  _COLUMN_KEYS.COMPLIANCE_DATE,
  _COLUMN_KEYS.REMEDIATION_TYPES,
  _COLUMN_KEYS.REMEDIATION_TYPE_COUNTS,
  _COLUMN_KEYS.TARGA_GROUP_STATUSES,
  _COLUMN_KEYS.CUSTOMER,
  _COLUMN_KEYS.ENTITY,
  _COLUMN_KEYS.AREA,
  _COLUMN_KEYS.PIPE,
  _COLUMN_KEYS.REGULATORY_STATUS,
  _COLUMN_KEYS.JOB_NUMBER,
  _COLUMN_KEYS.START_END,
  _COLUMN_KEYS.SURVEY_TYPES,
  _COLUMN_KEYS.PMS,
  _COLUMN_KEYS.CTS,
  _COLUMN_KEYS.TAGS
].filter(key => (sessionCustomer === 'Aegion' || !AEGION_ONLY_COLUMN_KEYS[key]));

const PRIVATE_ALL_POSSIBLE_COLUMN_KEYS = _COLUMN_KEYS;

export { 
  ROOT_SLICE,

  COLUMN_KEYS,
  AEGION_ONLY_COLUMN_KEYS,
  REQUIRED_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS,

  DEFAULT_COLUMN_KEYS_SORT_LIST,
  PRIVATE_ALL_POSSIBLE_COLUMN_KEYS,
}


export default { 
  ROOT_SLICE,

  COLUMN_KEYS,
  AEGION_ONLY_COLUMN_KEYS,
  REQUIRED_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS,

  DEFAULT_COLUMN_KEYS_SORT_LIST,
  PRIVATE_ALL_POSSIBLE_COLUMN_KEYS
}
