import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};

const getTags = (line) => { 
  return (line?.tags || []).map(t => t.tag_name).join('~');
}

/** ****************************
 * MAIN
 */

const TH = ({ header = "Tags" }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT }) => {
  const value = useMemo(() => getTags(item), [item]);

  return <td><HelperRenderStringList strList={value} direction='vertical' /></td>;
};

const ColTags = { TH, TD, CSV_PROP: null };

export default ColTags;
