/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
  setCustomer,
  setAnalysisPageCustomer,
  setIsLoadingLoaded,
  setCustomerSettingsSource,
  replaceSettingsForCustomer,
  clear,
} from './actions';
import { getCustomer } from '../../../../../../util/user';

import { fetchCustomerSettingsSource } from '../../../api/customersettings';

import { selectCustomer, selectIsLoaded, selectIsLoading } from '../selectors';
import { selectCustomerFromSurveys } from '../../../../AppAnalysisPage/selectors/surveys';

const _thunkLoad = () => (dispatch, getState) => {
  const customerName = selectCustomer(getState());

  dispatch(clear());
  dispatch(setCustomer(customerName));
  dispatch(
    setIsLoadingLoaded({
      isLoading: true,
      isLoaded: false
    })
  );

  fetchCustomerSettingsSource(customerName).then((data) => {
    dispatch(setCustomerSettingsSource(data));
    dispatch(replaceSettingsForCustomer());
    dispatch(
      setIsLoadingLoaded({
        isLoading: false,
        isLoaded: true
      })
    );
  });
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
  const sessionCustomer = getCustomer();
  const isLoaded = selectIsLoaded(getState());
  const isLoading = selectIsLoading(getState());

  if (sessionCustomer === 'Aegion' && !isLoaded && !isLoading) {
    dispatch(_thunkLoad());
  }
};

const thunkSetCustomerName = (customerName) => (dispatch, getState) => { 
  const customer = selectCustomer(getState());
  if (customer !== customerName) {
    dispatch(setCustomer(customerName));
    dispatch(replaceSettingsForCustomer());
  }
}

const thunkSetAnalysisPageCustomerFromSurveys = () => (dispatch, getState) => { 
  const customer = selectCustomerFromSurveys(getState()) || undefined;
  dispatch(setAnalysisPageCustomer(customer));
}


export { thunkLoadIfNotLoaded, thunkSetCustomerName, thunkSetAnalysisPageCustomerFromSurveys };
