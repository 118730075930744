import { createSelector } from 'reselect';
import { EMPTY_OBJECT } from './constants';
import { createCustomGroupsFromCustomGroupRanges } from './util';
import { selectReadings } from '../../../AppAnalysisPage/selectors/alignedReadings';
import { selectCustomerRemediationActionTypes } from '../../../AppAnalysisPage/selectors/customer';
import { getSeriesKey } from '../../../../utils/legacy.chart';
import { selectStateApp } from '../../../AppAnalysisPage/selectors/main';


const selectThisSlice = (state) => { 
  const slice = selectStateApp(state);
  if (slice && slice.chartsRemediations) { 
    return slice.chartsRemediations;
  }

  // else assume state is already at slice "chartsRemediations".
  return slice || EMPTY_OBJECT;
}

const selectChartRemediations = (state, chartId) => {
  const slice = selectThisSlice(state)
	const chartRemediations = slice[chartId] || EMPTY_OBJECT;
	return chartRemediations;
};

export const selectCustomGroupRanges = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { customGroupRanges } = chartRemediations;
	return customGroupRanges;
};

export const selectHasCustomRemediationGroups = (state, chartId) => {
	const customGroupRanges = selectCustomGroupRanges(state, chartId);
	return customGroupRanges && customGroupRanges.length > 0;
};

export const selectComputedBcMap = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { computedBcMap } = chartRemediations;
	return computedBcMap;
};

export const selectCustomExceptions = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { customExceptions } = chartRemediations;
	return customExceptions;
};

export const selectCustomExceptionsMap = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { customExceptionsMap } = chartRemediations;
	return customExceptionsMap;
};

export const selectCustomExceptionsWithGaps = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { customExceptionsWithGaps } = chartRemediations;
	return customExceptionsWithGaps;
};

export const selectRemediationGroupRanges = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { remediationGroupRanges } = chartRemediations;
	return remediationGroupRanges;
};

export const selectRemediationReadings = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId);
	const { remediationReadings } = chartRemediations;
	return remediationReadings;
};

const helpSelectReadings = (state, { survey }) => {
	return selectReadings(survey, state);
};

const helpSelectCustomRangeGroups = (state, { chartId }) => {
	return selectCustomGroupRanges(state, chartId);
};

export const createSelectCustomGroups = () => {
	return createSelector(
		[helpSelectCustomRangeGroups, helpSelectReadings],
		createCustomGroupsFromCustomGroupRanges
	);
};

export const selectRemediationActions = (state, chartId) => {
	const chartRemediations = selectChartRemediations(state, chartId) || {};
	const { remediationActions } = chartRemediations;

	return remediationActions || EMPTY_OBJECT;
};

export const selectRemediationActionsByGroup = (state, chartId) => {
	const remediationGroupRanges = selectRemediationGroupRanges(
		state,
		chartId
	);
	const remediationActions = selectRemediationActions(state, chartId);
	const customerRemediationActionTypes = selectCustomerRemediationActionTypes(
		state
	);

	// @todo @WBFIX ---- adding null check here --- needs to be reviewed for potential hidden bugs
	const result = (remediationGroupRanges || []).reduce((accRoot, group) => {
		const groupKey = getSeriesKey({ dataSource: group });
		const actionsForGroup = remediationActions[groupKey] || {};

		// Determine which action types for this group exist
		const actionTypeKeysForGroupMap = Object.keys(actionsForGroup).reduce(
			(acc, actionKey) => {
				const actionType = actionsForGroup[actionKey].type;
				acc[actionType] = true;
				return acc;
			},
			{}
		);

		// Build ordered list of actionTypes
		const actionTypeKeysForGroup = customerRemediationActionTypes.filter(
			type => !!actionTypeKeysForGroupMap[type]
		);

		// Group actions by action type
		const actionTypes = actionTypeKeysForGroup.map(actionTypeKey => {
			return {
				actionType: actionTypeKey,
				actions: Object.keys(actionsForGroup).reduce((acc, actionKey) => {
					const action = actionsForGroup[actionKey];
					if (action.type === actionTypeKey) {
						acc.push({
							...action,
							key: actionKey
						});
					}
					return acc;
				}, [])
			};
		});

		// eslint-disable-next-line no-param-reassign
		accRoot[groupKey] = {
			groupKey,
			actionTypes
		};

		return accRoot;
	}, {});

	return result;
};

const createRemediationGroupsByKey = (remediationGroupRanges = []) =>
	remediationGroupRanges.reduce((acc, group) => {
		const key = getSeriesKey({ dataSource: group });
		acc[key] = {
			key,
			group
		};

		return acc;
	}, {});

export const selectRemediationGroupsByActionType = (state, chartId) => {
	const remediationGroupRanges = selectCustomGroupRanges(state, chartId);
	const remediationGroupsByKey = createRemediationGroupsByKey(
		remediationGroupRanges
	);
	const remediationGroupKeys = Object.keys(remediationGroupsByKey);
	const remediationActions = selectRemediationActions(state, chartId);

	// @todo - this doesn't appear to be in use - re-evaluate and remove if not used
	// inject actions into group meta objects
	// format - { key, group, actions }
	remediationGroupKeys.forEach(key => {
		const meta = remediationGroupsByKey[key];
		meta.actions = remediationActions[key];
	});

	// initialize remediationGroupsByActionType
	const RemediationActionTypes = selectCustomerRemediationActionTypes(state);
	const remediationGroupsByActionType = RemediationActionTypes.reduce(
		(acc, actionType) => {
			acc[actionType] = [];
			return acc;
		},
		{}
	);

	// inject groupMeta w/Actions into correct ActionType mapping
	remediationGroupKeys.forEach(groupKey => {
		const groupMeta = remediationGroupsByKey[groupKey];
		const { actions = {} } = groupMeta;
		const actionKeys = Object.keys(actions);

		actionKeys.forEach(actionKey => {
			const action = actions[actionKey];
			const { type } = action;
			if (type) {
				const hasGroupMapped =
					remediationGroupsByActionType[type].indexOf(groupMeta) > -1;
				if (!hasGroupMapped) {
					remediationGroupsByActionType[type].push(groupMeta);
				}
			}
		});
	});

	return remediationGroupsByActionType;
};

export const selectRemediationAction = (
	state,
	chartId,
	groupKey,
	actionKey
) => {
	const remediationActions = selectRemediationActions(state, chartId) || {};
	return (remediationActions[groupKey] || {})[actionKey];
};

export default {
	selectCustomGroupRanges,
	selectHasCustomRemediationGroups,
	selectCustomExceptions,
	selectCustomExceptionsWithGaps,
	selectRemediationGroupRanges,
	selectRemediationReadings,
	createSelectCustomGroups,
	selectRemediationActions,
	selectRemediationActionsByGroup,
	selectRemediationGroupsByActionType,
	selectRemediationAction
};
