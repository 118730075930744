import { getUserName } from '../../../../util/user';
import { selectStateApp } from './main';

const EMPTY_ARRAY = [];

export const selectViewAs = state => {
  const slice = selectStateApp(state);
	const { viewAs } = slice;

	if (viewAs && viewAs.userId !== getUserName()) {
		return viewAs;
	}

	return undefined;
};

export const selectViewAsList = state => {
  const slice = selectStateApp(state);
	const { viewAsList } = slice;
	return viewAsList || EMPTY_ARRAY;
};

export const selectIsViewAsListLoading = state => {
  const slice = selectStateApp(state);
	const { isViewAsListLoading } = slice;
	return !!isViewAsListLoading;
};

export const isViewAsMe = state => {
	const viewAs = selectViewAs(state);
	return !viewAs || viewAs.userId === getUserName();
};

export const selectViewAsUserId = state => {
	const viewAs = selectViewAs(state) || {};
	return viewAs.userId;
};

export const selectViewAsErrorMessage = state => {
  const slice = selectStateApp(state);
	const { viewAsErrorMessage } = slice;
	return viewAsErrorMessage;
};

export const selectHasViewAsErrorMessage = state => {
	const viewAsErrorMessage = selectViewAsErrorMessage(state);
	return viewAsErrorMessage && viewAsErrorMessage.trim().length > 0;
};

export const selectIsPm = (state = {}) => {
  const slice = selectStateApp(state);
  const { isPm } = slice;
	return !!isPm;
};

export const selectIsRequestingIsPm = (state) => {
  const slice = selectStateApp(state);
  const { isRequestingIsPm } = slice;
	return !!isRequestingIsPm;
};
