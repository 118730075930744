import { getChartId } from '../../../../../utils/chart';
import { selectStateApp } from '../../../selectors/main';
import {
	selectPrimarySurveyFromDerived,
	selectSurveys
} from '../../../selectors/surveys';
import { selectChartIds } from '../../Charts/Main/selectors';
import { selectStoreXYMinMaxSlice } from '../selectors';
import { computePossibleLinkedSurveyIds } from '../util/cis.linked.surveys';
import { setDataXYMinMax, bulkSetDataXYMinMax } from './actions';

const isDefined = v => v !== null && v !== undefined;

const thunkSetDataXYMinMax = (key, { xMin, xMax, yMin, yMax }) => dispatch => {
	dispatch(setDataXYMinMax(key, { xMin, xMax, yMin, yMax }));
};

const thunkBulkSetDataXYMinMax = bulkChanges => dispatch => {
	dispatch(bulkSetDataXYMinMax(bulkChanges));
};

const _selectXYMinMaxChanges = (_current, _new) => {
	let hasChartChanges = false;
	const bulkChange = {};

	const { xMin: currXMin, xMax: currXMax, yMin: currYMin, yMax: currYMax } =
		_current || {};

	const { xMin: newXMin, xMax: newXMax, yMin: newYMin, yMax: newYMax } =
		_new || {};

	// XMIN
	const ignoreXMin = !isDefined(newXMin);
	const didXMinDefinedChange = !isDefined(currXMin) && isDefined(newXMin);
	const didXMinChange =
		!didXMinDefinedChange && !ignoreXMin && newXMin < currXMin;
	if (didXMinDefinedChange || didXMinChange) {
		bulkChange.xMin = newXMin;
		hasChartChanges = true;
	}

	// XMAX
	const ignoreXMax = !isDefined(newXMax);
	const didXMaxDefinedChange = !isDefined(currXMax) && isDefined(newXMax);
	const didXMaxChange =
		!didXMaxDefinedChange && !ignoreXMax && newXMax > currXMax;
	if (didXMaxDefinedChange || didXMaxChange) {
		bulkChange.xMax = newXMax;
		hasChartChanges = true;
	}

	// YMIN
	const ignoreYMin = !isDefined(newYMin);
	const didYMinDefinedChange = !isDefined(currYMin) && isDefined(newYMin);
	const didYMinChange =
		!didYMinDefinedChange && !ignoreYMin && newYMin < currYMin;
	if (didYMinDefinedChange || didYMinChange) {
		bulkChange.yMin = newYMin;
		hasChartChanges = true;
	}

	// YMAX
	const ignoreYMax = !isDefined(newYMax);
	const didYMaxDefinedChange = !isDefined(currYMax) && isDefined(newYMax);
	const didYMaxChange =
		!didYMaxDefinedChange && !ignoreYMax && newYMax > currYMax;
	if (didYMaxDefinedChange || didYMaxChange) {
		bulkChange.yMax = newYMax;
		hasChartChanges = true;
	}

	if (hasChartChanges) {
		return bulkChange;
	}

	return undefined;
};

const thunkSetDataXYMinMaxFromSurvey = survey => (dispatch, getState) => {
	const bulkChanges = {};
	const { isDerived } = survey;
	const parentSurvey = isDerived
		? selectPrimarySurveyFromDerived(getState().app, survey)
		: undefined;

	// identify chart ids which might need to be updated
	const possibleLinkedSurveyIds = computePossibleLinkedSurveyIds(survey.id);
	const chartIds = selectChartIds(selectStateApp(getState()));
	const chartIdsMap = chartIds.reduce((acc, cid) => {
		acc[cid] = true;
		return acc;
	}, {});
	const thisChartId = [survey.id, ...possibleLinkedSurveyIds]
		.map(sId => getChartId({ id: sId }))
		.filter(cId => chartIdsMap[cId])[0];

	// get Y MIN MAX STORE
	const storeXYMinMax =
		selectStoreXYMinMaxSlice(selectStateApp(getState())) || {};
	const storeXYMinMaxData = (storeXYMinMax || {}).data || {};
	// create bulk change for survey min/max
	const surveyChanges = _selectXYMinMaxChanges(
		storeXYMinMaxData[survey.id],
		survey
	);
	if (surveyChanges) {
		bulkChanges[survey.id] = {
			...(storeXYMinMaxData[survey.id] || {}),
			...surveyChanges
		};
	}
	if (thisChartId) {
		const chartChanges = _selectXYMinMaxChanges(
			storeXYMinMaxData[thisChartId],
			{
				xMin: (parentSurvey || survey).xMin,
				xMax: (parentSurvey || survey).xMax,
				yMin: survey.yMin,
				yMax: survey.yMax
			}
		);
		if (chartChanges) {
			bulkChanges[thisChartId] = {
				...(storeXYMinMaxData[thisChartId] || {}),
				...chartChanges
			};
		}
	}

	// remove undefined keys
	Object.keys(bulkChanges).forEach(k => {
		if (!bulkChanges[k]) {
			delete bulkChanges[k];
		}
	});

	if (Object.keys(bulkChanges).length) {
		dispatch(thunkBulkSetDataXYMinMax(bulkChanges));
	}
};

const thunkRecomputeAllDataXYMinsMaxes = () => (dispatch, getState) => {
	const surveys = selectSurveys(selectStateApp(getState())).filter(
		s =>
			isDefined(s.yMin) ||
			isDefined(s.yMax) ||
			isDefined(s.xMin) ||
			isDefined(s.xMax)
	);
	surveys.forEach(s => {
		dispatch(thunkSetDataXYMinMaxFromSurvey(s));
	});
};

export {
	thunkSetDataXYMinMax,
	thunkBulkSetDataXYMinMax,
	thunkSetDataXYMinMaxFromSurvey,
	thunkRecomputeAllDataXYMinsMaxes
};

export default {
	thunkSetDataXYMinMax,
	thunkBulkSetDataXYMinMax,
	thunkSetDataXYMinMaxFromSurvey,
	thunkRecomputeAllDataXYMinsMaxes
};
