/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */

import { ACTION_PLAN_STATE_SLICE_KEY } from './constants';

import {
	addGroupToRangeGroups,
	removeGroupFromRangeGroups
} from '../../../../utils/readingRangeGroups';
import {
	selectActionPlanStateCustomGroups,
	selectActionPlanStateRemediationActions,
	selectActionPlanStateThreshold,
	selectActionPlanStateUseShadow,
	selectActionPlanStateVersion,
	selectActionPlanStateComparator
} from './selectors';
import {
	receiveActionPlanState,
	setActionReportVersion,
	setActionReportComparator,
	setActionReportThreshold,
	setActionReportUseShadow,
	addActionReportCustomGroup,
	removeActionReportCustomGroup,
	clearActionReportCustomGroup
} from './actions';

// ************
// PARTS
// ************
const reducerParts = {
	[receiveActionPlanState]: (state, { payload: { actionPlanState } }) => {
		const result = {
			...state,
			isFetchingActionPlanState: false,
			isActionPlanStateLoaded: true,
			[ACTION_PLAN_STATE_SLICE_KEY]: actionPlanState
		};

		if (actionPlanState && Object.keys(actionPlanState).length > 0) {
			const version = selectActionPlanStateVersion({ actionPlanState });
			const comparator = selectActionPlanStateComparator({
				actionPlanState
			});
			const threshold = selectActionPlanStateThreshold({ actionPlanState });
			const useShadow = selectActionPlanStateUseShadow({ actionPlanState });
			const remediationActions = selectActionPlanStateRemediationActions({
				actionPlanState
			});
			const customGroups = selectActionPlanStateCustomGroups({
				actionPlanState
			});
			result.actionReportVersion = version;
			result.actionReportComparator = comparator;
			result.actionReportThreshold = threshold;
			result.actionReportUseShadow = useShadow;
			if (remediationActions) {
				result.actionReportRemediationActions = remediationActions;
			}
			if (customGroups) {
				result.actionReportCustomGroups = customGroups;
			}
		}

		return result;
	},

	// @todo - this should be managed inside the action plan state object
	[setActionReportVersion]: (
		state,
		{ payload: { version: actionReportVersion } }
	) => {
		return { ...state, actionReportVersion };
	},

	// @todo - this should be managed inside the action plan state object
	[setActionReportComparator]: (
		state,
		{ payload: { comparator: actionReportComparator } }
	) => {
		return { ...state, actionReportComparator };
	},

	// @todo - this should be managed inside the action plan state object
	[setActionReportThreshold]: (
		state,
		{ payload: { threshold: actionReportThreshold } }
	) => {
		return { ...state, actionReportThreshold: +actionReportThreshold };
	},

	// @todo - this should be managed inside the action plan state object
	[setActionReportUseShadow]: (
		state,
		{ payload: { useShadow: actionReportUseShadow } }
	) => {
		return { ...state, actionReportUseShadow };
	},

	// @todo - this should be managed inside the action plan state object
	[addActionReportCustomGroup]: (state, { payload: { group } }) => {
		return {
			...state,
			actionReportCustomGroups: addGroupToRangeGroups(
				group,
				state.actionReportCustomGroups
			)
		};
	},

	// @todo - this should be managed inside the action plan state object
	[removeActionReportCustomGroup]: (state, { payload: { group } }) => {
		return {
			...state,
			actionReportCustomGroups: removeGroupFromRangeGroups(
				group,
				state.actionReportCustomGroups
			)
		};
	},

	// @todo - this should be managed inside the action plan state object
	[clearActionReportCustomGroup]: state => {
		return {
			...state,
			actionReportCustomGroups: []
		};
	}
};

export default reducerParts;
