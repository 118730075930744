/* eslint-disable no-confusing-arrow */

export const isDefined = v => v !== null && v !== undefined;

export const isEmptyString = v =>
	typeof v === 'string' && v.trim().length === 0;

export const isEmptyObject = o =>
	typeof o === 'object' && Object.keys(o).length === 0;

export const createChangeNotesState = () => {
	return {
		notes: undefined,
		showModal: undefined,
		sideEffects: {
			onSaveChangeNotes: undefined,
			onCancelChangeNotes: undefined
		}
	};
};

export default {
	isDefined,
	isEmptyString,
	isEmptyObject,
	createChangeNotesState
};
