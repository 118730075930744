/* eslint-disable import/no-cycle */
import { getLineId } from '../../../../../utils/location.scanline';
import { postLinkSurveys } from '../../../api/linksurveys';
import { selectPrimarySurveys } from '../../../../AppAnalysisPage/selectors/alignedReadings';
import { selectSelectedSurveysArrMapById} from '../selectors';
import { setIsLinking, setLinkErrorMessage } from './actions';


const LAMBDA = () => { };

const thunkSubmitLinkSurveys = ({ onSuccess = LAMBDA, onError = LAMBDA } = {}) => (dispatch, getState) => {
  const state = getState();
  const stateApp = state.app || state;
  const primarySurveys = selectPrimarySurveys(stateApp);
  const primarySurvey = (primarySurveys || [])[0];
  const { customer } = (primarySurvey || {});
  const map = selectSelectedSurveysArrMapById(state);
  
  const surveyIds = Object.keys(map).reduce((acc, key) => {
    return [...acc, ...(map[key] || [])];
  }, []);

  if (surveyIds.length) {
    dispatch(setIsLinking(true));
    postLinkSurveys(customer, getLineId(), surveyIds).then(() => { 
      dispatch(setIsLinking(false));
      if (onSuccess) { 
        onSuccess();
      }
    }).catch(err => { 
      dispatch(setIsLinking(false));
      if (err && err.message && typeof err.message === 'string') {
        dispatch(setLinkErrorMessage(err.message));
      } else {
        dispatch(setLinkErrorMessage('An unknown error occured.  Please email support at aip@corrpro.com'));
      }
      if (onError) { 
        onError();
      }
    });
  }
}

export default thunkSubmitLinkSurveys;
