import { thunkSet as thunkSetLinesAggs } from '../../LinesAggs/actions';
import { selectItems } from '../../LinesAggs/selectors';
import { SORT_DIRECTION } from '../constants';
import { selectSortColumn, selectSortDirection } from '../selectors';
import { setSorting } from './actions';
import { getColumnBase } from '../../../../../components/LinesTable/components/Columns/utils';
import { updateSorting as updatReportsSorting } from '../../ReportsAggsSorting/actions';

function getSortDirection(currentSortDirection, currentSortColumn, sortColumn) {
	if (currentSortColumn !== sortColumn) {
		return SORT_DIRECTION.ASC;
	}
	if (currentSortDirection === SORT_DIRECTION.NULL) {
		return SORT_DIRECTION.ASC;
	}
	if (currentSortDirection === SORT_DIRECTION.ASC) {
		return SORT_DIRECTION.DESC;
	}
	return SORT_DIRECTION.NULL;
}

export const updateSorting = (sortColumn, direction, updateReports = true) => (
	dispatch,
	getState
) => {
	try {
		getColumnBase(sortColumn);
	} catch {
		return;
	}
	const currentSortColumn = selectSortColumn(getState());
	const currentSortDirection = selectSortDirection(getState());
	const sortDirection = getSortDirection(
		currentSortDirection,
		currentSortColumn,
		sortColumn
	);
	dispatch(
		setSorting({ sortColumn, sortDirection: direction || sortDirection })
	);
	const items = selectItems(getState());
	dispatch(thunkSetLinesAggs(items));

	if (updateReports) {
		dispatch(updatReportsSorting(sortColumn, sortDirection, false));
	}
};
