import { createSelector } from "reselect";
import { selectCustomerFromSurveys } from "../AppAnalysisPage/selectors/surveys";

/* eslint-disable import/prefer-default-export */
const { getCustomer } = require("../../../util/user")

const _selectPickerCustomer = (state) => { 
  return state?.jobs?.currentCustomer || state?.currentCustomer;
}

export const legacySelectCustomerFromPicker = createSelector(_selectPickerCustomer, selectCustomerFromSurveys, (pickerCustomer, surveyCustomer) => { 
  return surveyCustomer || pickerCustomer ||  getCustomer();
})
