/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { assumeCognitoUser } from '../../../cognito';
import getAipUrls from '../../../../config/getAipUrls';
import scanlineUtils from '../../../../scanline/scanlineUtils';
import { renderAsText, asyncTimeout } from './helpers';

const aipUrls = getAipUrls();

const S3_ACTIONS_DESC = {
	upload: 'upload -> s3.upload',
	listObjectVersions: 'upload -> s3.listObjectVersions'
};

const emailTechnicalSupport = ({ action, payload, error, attemptCount }) => {
	const title = `AIP - request "${action}" failed (attempt ${attemptCount})`;

	scanlineUtils.emailTechnicalSupport(
		{
			appendToSubject: title,
			content: `
        <div>
          <h2>${title}</h2>
          <div>Error: ${renderAsText(error)}</div>
          <div>Payload: ${renderAsText(payload)}</div>
        <div>
      `,
			docType: 'HTML'
		},
		{ ignoreError: true }
	);
};

const _upload = async (s3, params, options, attemptCount = 1) => {
	const retryOnFail = attemptCount === 1;
	return s3
		.upload(params, options)
		.promise()
		.catch(async err => {
			emailTechnicalSupport({
				action: S3_ACTIONS_DESC.upload,
				payload: params,
				error: err,
				attemptCount
			});
			if (retryOnFail) {
				await asyncTimeout(300);
				return _upload(s3, params, options, attemptCount + 1);
			}
			throw err;
		});
};

const _listObjectVersions = async (
	s3,
	{ Bucket: bucket, Prefix: newKey, MaxKeys },
	attemptCount = 1
) => {
	const retryOnFail = attemptCount === 1;
	return s3
		.listObjectVersions({ Bucket: bucket, Prefix: newKey, MaxKeys })
		.promise()
		.catch(async err => {
			emailTechnicalSupport({
				action: S3_ACTIONS_DESC.listObjectVersions,
				payload: { Bucket: bucket, Prefix: newKey, MaxKeys },
				error: err,
				attemptCount
			});
			if (retryOnFail) {
				await asyncTimeout(300);
				return _listObjectVersions(
					s3,
					{ Bucket: bucket, Prefix: newKey, MaxKeys },
					attemptCount + 1
				);
			}
			throw err;
		});
};

export const upload = async (
	key,
	data,
	versioned = false,
	enableFallback = false
) => {
	const AWS = await assumeCognitoUser();
	const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
	const bucket = versioned ? aipUrls.versionedBucket : aipUrls.bucket;
	const newKey = key.replace('{user}', AWS.config.credentials.identityId);
	const params = {
		Body: data,
		Bucket: bucket,
		Key: newKey
	};

	const options = {
		partSize: 5 * 1024 * 1024,
		queueSize: 5
	};

	await _upload(s3, params, options, enableFallback ? 1 : 0);

	if (!versioned) {
		return { bucket, key: newKey };
	}
	const {
		Versions: [{ VersionId: versionId }]
	} = await _listObjectVersions(
		s3,
		{
			Bucket: bucket,
			Prefix: newKey,
			MaxKeys: '1'
		},
		enableFallback ? 1 : 0
	);

	return { versionId, bucket, key: newKey };
};
