import { getChartId } from '../../../../../../utils/chart';
import { selectDefaultSurvey } from '../../../../../AppAnalysisPage/selectors/surveys';
import { cleanContent } from './helpers';

// PRIVATE
const computeAssumedSurveyId = (pageLoadState, appState) => {
	const defaultSurvey = selectDefaultSurvey(appState) || {};
	return defaultSurvey.id;
};

export const transformPageLoadStateFromServer = (
	serverPageLoadState,
	getState
) => {
	const state = getState();
	const { app } = state;
	const { content = {} } = serverPageLoadState;
	const assumedSurveyId = computeAssumedSurveyId(content, app);

	const surveyId = content.surveyId || assumedSurveyId;
	const chartId = content.chartId || getChartId({ id: surveyId });

	return {
		...cleanContent(content),
		surveyId,
		chartId
	};
};

export default {
	transformPageLoadStateFromServer
};
