/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { 
  clear
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},
};

export default reducerParts;
