/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { getObject } from '../../../../../util/persist/utils/s3.api';
import {
	selectStateSlice,
	selectS3DataRequestInfo
} from '../ActionPlanStateV2/selectors';
import scanlineUtils from '../../../../scanlineUtils';
import { isProd } from '../../../../utils/location';

const injectMaps = data => {
	if (Array.isArray(data) || typeof data !== 'object') {
		return data;
	}

	const keys = Object.keys(data);
	return keys.reduce((acc, k) => {
		const d = data[k];
		acc[k] = d;
		if (
			Array.isArray(d) &&
			k.toLowerCase().indexOf('readings') > -1 &&
			!data[`${k}Map`]
		) {
			const map = d.reduce((acc2, r) => {
				if (!acc2[r.id]) {
					acc2[r.id] = r;
				}
				return acc2;
			}, {});
			acc[`${k}Map`] = map;
		}
		return acc;
	}, {});
};

const {
	clearReadings,
	requestReadings,
	setIsLoaded,
	receiveReadings
} = createActions(
	{
		CLEAR_READINGS: () => ({}),
		REQUEST_READINGS: () => ({}),
		SET_IS_LOADED: () => ({}),
		RECEIVE_READINGS: payload => payload
	},
	{ prefix: 'ActionPlanReadings' }
);

const octetStreamToJson = stream => JSON.parse(stream.toString('utf-8'));
const parseS3GetResponse = res => octetStreamToJson(res.Body);

const thunkLoadActionPlanReadings = () => {
	return (dispatch, getState) => {
		dispatch(requestReadings());
		const slice = selectStateSlice(getState());
		const requestsPayloads = selectS3DataRequestInfo(slice);
		let countResponse = 0;

		requestsPayloads.forEach(payload => {
			const {
				s3Bucket: bucket,
				s3Key: key,
				s3KeyVersion: versionId
			} = payload.s3Keys;
			const enableFallback = true;
			getObject(
				key,
				{ bucket, versionId, ignoreError: !isProd() },
				enableFallback
			)
				.then(res => {
					const { targetKey, parentKey, modifierKey } = payload;
					const { data } = parseS3GetResponse(res);
					dispatch(
						receiveReadings({
							targetKey,
							parentKey,
							modifierKey,
							data: injectMaps(data)
						})
					);
					countResponse += 1;
					if (countResponse >= requestsPayloads.length) {
						dispatch(setIsLoaded(true));
					}
				})
				.catch(() => {
					if (isProd()) {
						scanlineUtils.emailTechnicalSupport(
							{
								appendToSubject:
									'ScanLine - Downloading archived survey data failed.',
								content: `
                  <div>
                    <h2>ScanLine - Downloading archived survey data failed</h2>
                    <div><b>Key:</b> ${key}</div>
                    <div><b>VersionId:</b> ${versionId}</div>
                  <div>
                `,
								docType: 'HTML'
							},
							{ ignoreError: true }
						);
					}
				});
		});
	};
};

export {
	clearReadings,
	receiveReadings,
	setIsLoaded,
	requestReadings,
	thunkLoadActionPlanReadings
};
