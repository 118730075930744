const { getAppSettings } = require("../user");


/**
 * IN APP REPORTS
 */
export const hasSettingEnableActionPlan = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableActionPlan;
};

export const hasSettingEnableClosureReport = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableClosureReport;
};


/**
 * TABS & LISTS PAGE - LINE LISTS
 */

export const hasSettingEnableLinesListCardsView = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return (
    _appSettings.enableLinesListCardsView ||
    (!_appSettings.enableLinesListCardsView && !_appSettings.enableLinesListTableView)
  );
};

export const hasSettingEnableLinesListTableView = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableLinesListTableView;
};

export const hasSettingEnableMyProjects = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableMyProjects;
};



/**
 * TABS & LISTS PAGE - REPORTS LIST
 */

export const hasSettingEnableReportPercentPassing = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableReportPercentPassing;
};

export const hasSettingEnableReportTotalMiles = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableReportTotalMiles;
};

export const hasSettingEnableReportListActionPlanAndClosureV1 = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return (
    (_appSettings.enableReportListActionPlanAndClosureV1 ||
      (
        !_appSettings.enableReportListActionPlanAndClosureV1 &&
        !_appSettings.enableReportListActionPlanAndClosureV2
      )
    ) && (
      (
        _appSettings.enableActionPlan ||
        _appSettings.enableClosureReport
      )
    )
  );
};

export const hasSettingEnableReportListActionPlanAndClosureV2 = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableReportListActionPlanAndClosureV2 && (
    _appSettings.enableActionPlan ||
    _appSettings.enableClosureReport
  );
};

export const hasSettingEnableReports = (appSettings) => { 
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return (
    _appSettings.enableReportPercentPassing ||
    _appSettings.enableReportTotalMiles ||
    hasSettingEnableReportListActionPlanAndClosureV1(_appSettings) ||
    hasSettingEnableReportListActionPlanAndClosureV2(_appSettings)
  )  
}


/**
 * MISCELLANEOUS SETTINGS
 */

export const hasSettingEnableSharedStateForAnalysisAndReports = (appSettings) => { 
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.enableSharedStateForAnalysisAndReports;
}

export const hasSettingAutoRemoveIliData = (appSettings) => {
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.autoRemoveIliData;
};

export const hasSettingFeatureClosureCopyActionPlan =  (appSettings) => { 
  const _appSettings = appSettings || getAppSettings('SCANLINE');
  return _appSettings.featureClosureCopyActionPlan;  
}

/**
 * COMPUTE USER OR COMPANY KEY - used in S3 paths as a differentiator for the path - if shared state is enabled, returns company name else userid
 */

export const settingsPickCorrectKeyForS3 = (appSettings, { userid, customerName }) => {
  const customerHasSharedStateEnabled = hasSettingEnableSharedStateForAnalysisAndReports(appSettings);
  if (customerName && customerHasSharedStateEnabled) {
    return customerName;
  }
  return userid;
}

