"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  LABELS: {
    // FILTERS & TABLE COL HEADERS
    "line": "Project Description",
    "Line": "Project Description",
    "LINE": "Project Description",
    "lines": "Project Description",
    "Lines": "Project Description",
    "LINEs": "Project Description",
    "Entity": "Business Unit",
    "entity": "Business Unit",
    "ENTITY": "Business Unit",
    "Entities": "Business Unit",
    "entities": "Business Unit",
    "ENTITIES": "Business Unit",
    "area": "Area",
    "AREA": "Area",
    "areas": "Area",
    "Areas": "Area",
    "AREAS": "Area",
    "pipe": "PipeID",
    "Pipe": "PipeID",
    "PIPE": "PipeID",
    "pipes": "PipeID",
    "Pipes": "PipeID",
    "PIPES": "PipeID",
    "pipeids": "PipeID",
    "pipeIDs": "PipeID",
    "PipeIDs": "PipeID",
    "pipe ids": "PipeID",
    "pipe IDs": "PipeID",
    "Pipe IDs": "PipeID",
    "pipeid": "PipeID",
    "pipeID": "PipeID",
    "PipeID": "PipeID",
    "pipe id": "PipeID",
    "pipe ID": "PipeID",
    "Pipe ID": "PipeID",
    "Regulatorystatus": "Regulatory Status",
    "regulatorystatus": "Regulatory Status",
    "Regulatorystatuses": "Regulatory Status",
    "RegulatoryStatuses": "Regulatory Status",
    "Regulatory status": "Regulatory Status",
    "regulatory status": "Regulatory Status",
    "Regulatory statuses": "Regulatory Status",
    "Regulatory Statuses": "Regulatory Status",
    "Reg Status": "Regulatory Status",
    "Job": "Corrpro Job #",
    "Job#": "Corrpro Job #",
    "Job #": "Corrpro Job #",
    "Jobs": "Corrpro Job #",
    "survey type": "Survey Type",
    "survey types": "Survey Type",
    "surveytypes": "Survey Type",
    "Survey Types": "Survey Type",
    "SurveyTypes": "Survey Type",
    "Surveytypes": "Survey Type",
    "tech": "Technician",
    "techs": "Technician",
    "cts": "Technician",
    "ct": "Technician",
    "corrosion technician": "Technician",
    "corrosion technicians": "Technician",
    "Tech": "Technician",
    "Techs": "Technician",
    "Cts": "Technician",
    "CTs": "Technician",
    "CT": "Technician",
    "Technicians": "Technician",
    "Corrosion Technician": "Technician",
    "Corrosion Technicians": "Technician",
    "pm": "Project Manager",
    "pms": "Project Manager",
    "manager": "Project Manager",
    "managers": "Project Manager",
    "projectmanagers": "Project Manager",
    "PM": "Project Manager",
    "PMs": "Project Manager",
    "Pms": "Project Manager",
    "Manager": "Project Manager",
    "Managers": "Project Manager",
    "Projectmanagers": "Project Manager",
    "Project Manager": "Project Manager",
    "Project Managers": "Project Manager",
    "Start / End": "Survey Start & End Dates",
    "Report Date": "Created Date",
    "Compliance Date": "Compliance Due Date",
    // MISC
    "actionplan": "actiontracker",
    "Table Lines": "Projects",
    "Targagroupstatuses": "Group Status",
    "Targa Status": "Group Status",
    "Targa Group Status": "Group Status",
    "Action Plan Assignment": "Action Tracker Items"
  },
  ABBRV: {
    // FILTERS & TABLE COL HEADERS
    "line": "Project Desc",
    "Line": "Project Desc",
    "LINE": "Project Desc",
    "lines": "Project Desc",
    "Lines": "Project Desc",
    "LINEs": "Project Desc",
    "Entity": "BU",
    "entity": "BU",
    "ENTITY": "BU",
    "Entities": "BU",
    "entities": "BU",
    "ENTITIES": "BU",
    "area": "Area",
    "AREA": "Area",
    "areas": "Area",
    "Areas": "Area",
    "AREAS": "Area",
    "pipe": "PipeID",
    "Pipe": "PipeID",
    "PIPE": "PipeID",
    "pipes": "PipeID",
    "Pipes": "PipeID",
    "PIPES": "PipeID",
    "Regulatorystatus": "Reg Status",
    "regulatorystatus": "Reg Status",
    "Regulatorystatuses": "Reg Status",
    "RegulatoryStatuses": "Reg Status",
    "Regulatory status": "Reg Status",
    "regulatory status": "Reg Status",
    "Regulatory statuses": "Reg Status",
    "Regulatory Statuses": "Reg Status",
    "Reg Status": "Reg Status",
    "Job": "Job #",
    "Job#": "Job #",
    "Job #": "Job #",
    "Jobs": "Job #",
    "survey type": "Survey Type",
    "survey types": "Survey Type",
    "surveytypes": "Survey Type",
    "Survey Types": "Survey Type",
    "SurveyTypes": "Survey Type",
    "Surveytypes": "Survey Type",
    "tech": "Technician",
    "techs": "Technician",
    "cts": "Technician",
    "ct": "Technician",
    "corrosion technician": "Technician",
    "corrosion technicians": "Technician",
    "Tech": "Technician",
    "Techs": "Technician",
    "Cts": "Technician",
    "CTs": "Technician",
    "CT": "Technician",
    "Technicians": "Technician",
    "Corrosion Technician": "Technician",
    "Corrosion Technicians": "Technician",
    "pm": "PM",
    "pms": "PM",
    "manager": "PM",
    "managers": "PM",
    "projectmanagers": "PM",
    "PM": "PM",
    "PMs": "PM",
    "Pms": "PM",
    "Manager": "PM",
    "Managers": "PM",
    "Projectmanagers": "PM",
    "Project Manager": "PM",
    "Project Managers": "PM",
    "Start / End": "Start / End",
    "Report Date": "Created Date",
    "Compliance Date": "Compliance Date",
    "Targagroupstatuses": "Group Status",
    "Targa Status": "Group Status",
    "Targa Group Status": "Group Status"
  },
  VIEWS: {
    TABLE_HEADER: {
      LABELS: {
        "Lines": "Projects"
      }
    },
    SCAN_ANALYSIS_PAGE: {
      LABELS: {
        "MANAGE ACTION PLAN": "ACTION TRACKER"
      }
    },
    ACTION_PLAN: {
      LABELS: {
        'Integrity Action Plan&trade;': 'Action Tracker',
        'Integrity Action Plan&trade; - Segment': 'Action Tracker - Remediation Group',
        'Action Plan Report': 'Action Tracker'
      }
    }
  }
};
exports["default"] = _default;