"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _util = require("../util");

var _general = _interopRequireDefault(require("./general.mappings"));

var _targa = _interopRequireDefault(require("./targa.mappings"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = {
  GENERAL: _general["default"],
  Targa: (0, _util.combine)(_general["default"], _targa["default"])
};
exports["default"] = _default;