/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  clear,

  collapseAllRows,
  expandAllRows,
  expandRow,
  collapseRow,
  toggleRow  
} = createActions(
	{
    CLEAR: () => ({}),

    EXPAND_ALL_ROWS: () => ({}),
    COLLAPSE_ALL_ROWS: () => ({}),
    EXPAND_ROW: (item) => ({ item }), 
    COLLAPSE_ROW: (item) => ({ item }), 
    TOGGLE_ROW: (item) => ({ item }), 
	},
	{ prefix: ROOT_SLICE }
);

export {
  clear,
  expandAllRows,
  collapseAllRows,
  expandRow,
  collapseRow,
  toggleRow,
};
