/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import scanlineUtils from '../../../../scanlineUtils';
import { transformPageLoadStateFromServer } from './util/transformPageLoadStateFromServer/main';

const { requestPageLoadState, receivePageLoadState } = createActions(
	{
		REQUEST_PAGE_LOAD_STATE: () => {
			return {};
		},
		RECEIVE_PAGE_LOAD_STATE: pageLoadState => {
			return { pageLoadState };
		}
	},
	{
		prefix: 'PageLoadState'
	}
);

const thunkLoadPageLoadState = (lineId, projectId, callback = () => {}) => {
	return (dispatch, getState) => {
		dispatch(requestPageLoadState());

		return scanlineUtils.getAppStateV2({ lineId, projectId }, (err, res) => {
			if (err) {
				callback(err);
			} else {
				dispatch(
					receivePageLoadState(transformPageLoadStateFromServer(res, getState))
				);
				callback(null, res);
			}
		});
	};
};

// eslint-disable-next-line import/prefer-default-export
export {
	thunkLoadPageLoadState as loadPageLoadState,
	requestPageLoadState,
	receivePageLoadState
};
