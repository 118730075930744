/* eslint-disable no-param-reassign */
// DO NOT USE outside selectors.js

const isDefined = v => v !== undefined && v !== null;

const computeSortedKeys = (expandedRowMapById = {}) => { 
  const map = expandedRowMapById;
  const keys = Object.keys(map).filter(k => isDefined(map[k]) && map[k] > -1);
  keys.sort((k1, k2) => {
    if (map[k1] > map[k2]) {
        return 1;
    }
    return -1;
  });
  return keys;  
}

// we need offsets to account for faux items in the reports agg array which are used to represent expanded rows
// example: {'a': 4, 'b': 1, 'c', 7} ==> {'a': 5, 'b': 1, 'c': 9}
const computeExpandedRowMapByIdWithOffsets = (expandedRowMapById = {}) => { 
  const map = expandedRowMapById;
  const keys = computeSortedKeys(map);
  return keys.reduce((acc, k, offset) => {
    acc[k] = map[k] + offset;
    return acc;
  }, {});
}

const injectExpandedRows = (reportsAggs, expandedRowMapByIdWithOffsets) => {
  const map = expandedRowMapByIdWithOffsets;
  const keys = computeSortedKeys(map);
  const withInjected = keys.reduce((acc, k) => { 
    const itemRowIdx = map[k] - 1;
    const item = acc[itemRowIdx];
    const expandedRow = {
      ...item,
      EXPANDED_ROW: true
    };
    acc.splice(itemRowIdx + 1, 0, expandedRow);
    return acc;
  }, [...reportsAggs]);

  let trackOdd = 0;
  const newReportsAggs = withInjected.map(la => {
    const clone = { ...la };
    if (!la.EXPANDED_ROW) {
      trackOdd = trackOdd ? 0 : 1;
    }
    clone.EVEN_ODD = trackOdd ? 'ODD' : 'EVEN';
    return clone;
  });
  return newReportsAggs;
}

const createSelectedSurveysMapMapById = (selectedSurveysArrMapById) => {
  const map = selectedSurveysArrMapById;
  const reportAggIds = Object.keys(map);
  return reportAggIds.reduce((acc, reportAggId) => {
    const arr = map[reportAggId];
    if (arr instanceof Array && arr.length) {
      acc[reportAggId] = arr.reduce((acc2, surveyId) => {
        acc2[surveyId] = true;
        return acc2;
      }, {});
    }
    return acc;
  }, {});
}

const PRIVATE = {
  computeSortedKeys,
  computeExpandedRowMapByIdWithOffsets,
  injectExpandedRows,
  createSelectedSurveysMapMapById
}

export default PRIVATE;