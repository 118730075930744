"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  LABELS: {
    Cts: "Techs",
    Pms: "Managers",
    Regulatorystatuses: "Regulatory Statuses",
    Surveytypes: "Survey Types",
    "Targagroupstatuses": "Targa Group Status",
    "Projecttypes": "Project Type",
    "Reporttypes": "Report Type",
    "Reportstatuses": "Report Status",
    "Remediationstatuses": "Remediation Status"
  },
  ABBRV: {
    "Targagroupstatuses": "Group Status",
    "Targa Group Status": "Targa Status"
  },
  VIEWS: {
    CUSTOMER_ADMIN_SETTINGS: {
      LABELS: {
        "Enable Lines List Cards View": "Enable Cards View",
        "Enable Lines List Table View": "Enable Table View",
        "Enable Report List Action Plan And Closure V1": "Enable V1:Custom Reports <br/><small>action plan / closure</small>",
        "Enable Report List Action Plan And Closure V2": "Enable V2:Custom Reports <br/><small>action plan / closure</small>",
        "Enable Report Percent Passing": "Enable Percent Passing",
        "Enable Report Total Miles": "Enable Total Miles"
      }
    }
  }
};
exports["default"] = _default;