import React from 'react';
import { connect } from 'react-redux';
import { transLabel } from '../../../utils/transLabel';
import { legacySelectCustomerFromPicker } from '../../../redux/legacy/selectors';

const mapStateToProps = (state) => {
  return {
    labelCustomer: legacySelectCustomerFromPicker(state)
  };
}

const TransLabel = ({ text, labelCustomer, abbrv = false, view }) => { 
  return <>{transLabel(text, { customer: labelCustomer, abbrv, view })}</>
}

export default connect(mapStateToProps)(TransLabel);