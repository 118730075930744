/* eslint-disable import/no-cycle */
import { handleActions, combineActions } from 'redux-actions';

import {
	selectCustomer,
	invalidateCustomer,
	receiveCustomers
} from '../actions/customers';
import { getCustomer } from '../../../../util/user';

const jobsInitialState = {
	availableCustomers: [],
	currentCustomer: getCustomer(),
	isLoadingCustomers: false
};

const jobsReducer = handleActions(
	{
		[combineActions(selectCustomer, invalidateCustomer)](
			state,
			{
				payload: { customer }
			}
		) {
			return {
				...state,
				currentCustomer: customer
			};
		},

    [receiveCustomers]: (state, { payload: { customers } }) => {
			return {
				...state,
				availableCustomers: customers
			};
		}
	},
	jobsInitialState
);

export default jobsReducer;
