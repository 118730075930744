import { getColumnBase } from '../../../../../components/ReportsAggsTable/components/Columns/utils';
import { thunkSet as thunkSetReportsAggs } from '../../ReportsAggs/actions';
import { selectItems } from '../../ReportsAggs/selectors';
import { SORT_DIRECTION } from '../constants';
import { selectSortColumn, selectSortDirection } from '../selectors';
import { setSorting } from './actions';
import { updateSorting as updateLinesSorting } from '../../LinesAggsSorting/actions';

function getSortDirection(currentSortDirection, currentSortColumn, sortColumn) {
	if (currentSortColumn !== sortColumn) {
		return SORT_DIRECTION.ASC;
	}
	if (currentSortDirection === SORT_DIRECTION.NULL) {
		return SORT_DIRECTION.ASC;
	}
	if (currentSortDirection === SORT_DIRECTION.ASC) {
		return SORT_DIRECTION.DESC;
	}
	return SORT_DIRECTION.NULL;
}

export const updateSorting = (sortColumn, direction, updateLines = true) => (
	dispatch,
	getState
) => {
	try {
		getColumnBase(sortColumn);
	} catch {
		return;
	}
	const currentSortColumn = selectSortColumn(getState());
	const currentSortDirection = selectSortDirection(getState());
	const sortDirection = getSortDirection(
		currentSortDirection,
		currentSortColumn,
		sortColumn
	);
	dispatch(
		setSorting({ sortColumn, sortDirection: direction || sortDirection })
	);
	const items = selectItems(getState());
	dispatch(thunkSetReportsAggs(items));

	if (updateLines) {
		dispatch(updateLinesSorting(sortColumn, sortDirection, false));
	}
};
