/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import scanlineMapUtils from '../../../mapUtils';
import { arrayToMap } from '../../../utils/array';
import { selectPrimarySurveys } from './alignedReadings';
import { universalSortSurveys } from '../../../utils/surveys.sort';
import { selectStateApp } from './main';

// ********************
// SURVEYS
// ********************
export const selectSurveys = state => {
  const slice = selectStateApp(state);
	const { surveys } = slice;
	return surveys;
};

export const selectMapSurveys = createSelector(selectSurveys, surveys =>
	arrayToMap(surveys)
);

export const selectSortedSurveys = createSelector(selectSurveys, surveys =>
	universalSortSurveys(surveys)
);

// ********************
// META
// ********************

export const selectCustomerFromSurveys = createSelector(selectSurveys, surveys => { 
  return (surveys || []).filter(v => v?.customer)[0]?.customer || undefined;
})

// *******************
// Surveys By ChartId
// *******************
export const selectSurveysByChartId = (state, chartId) => {
	const surveys = selectSurveys(state);
	return surveys.filter(s => s.CHART_ID === chartId);
};

// ********************
// CIS SURVEYS
// ********************

export const selectCisSurveys = createSelector(selectSurveys, surveys => {
	surveys.filter(survey => scanlineMapUtils.isCis(survey.id));
});

export const selectMapCisSurveys = createSelector(selectCisSurveys, surveys =>
	arrayToMap(surveys)
);

// ********************
// SELECTED SURVEYS
// ********************

export const selectSelectedSurveys = createSelector(selectSurveys, surveys => {
	return surveys.filter(survey => !!survey.checked);
});

export const selectMapSelectedSurveys = createSelector(
	selectSelectedSurveys,
	surveys => arrayToMap(surveys)
);

// ********************
// SELECTED CIS SURVEYS
// ********************

export const selectSelectedCisSurveys = createSelector(
	selectCisSurveys,
	surveys => {
		surveys.filter(survey => !!survey.checked);
	}
);

export const selectMapSelectedCisSurveys = createSelector(
	selectSelectedCisSurveys,
	surveys => arrayToMap(surveys)
);

// ********************
// MISC
// ********************

export const selectSurveysForAnalytics = createSelector(
	selectSurveys,
	surveys => {
		return (surveys || []).filter(s => !s.isDerived);
	}
);

export const selectSurvey = (state, surveyId) => {
  const surveys = selectSurveys(state);
	return surveys.find(s => s.id === surveyId);
};

export const selectDerivedSurvey = (state, survey, derivedSubtype) => {
	const derivedSurveyId = scanlineMapUtils.deriveSurveyId(
		survey,
		derivedSubtype
	);
	return selectSurvey(state, derivedSurveyId);
};

export const selectPrimarySurveyFromDerived = (state, derivedSurvey) => {
	if (!derivedSurvey.isDerived) {
		return derivedSurvey;
	}

	const primarySurveys = [...selectPrimarySurveys(state)].sort((a, b) => {
		const ORDER_REF = ['ON_OFF', 'ON-OFF', 'ON', 'DEPOL', 'NATIVE'];

		const { survey_subtype: a_survey_subtype } = a;
		const { survey_subtype: b_survey_subtype } = b;

		const a_order_index = ORDER_REF.indexOf(a_survey_subtype);
		const b_order_index = ORDER_REF.indexOf(b_survey_subtype);

		const a_order = a_order_index !== -1 ? a_order_index : 9999;
		const b_order = b_order_index !== -1 ? b_order_index : 9999;

		switch (true) {
			case a_order > b_order:
				return 1;
			case a_order < b_order:
				return -1;
			default:
				return 0;
		}
	});

	const primarySurvey =
		primarySurveys.find(
			s => !!derivedSurvey.jobId && s.jobId === derivedSurvey.jobId
		) || undefined;

	return primarySurvey;
};

export const selectUnsupportedSurveysToLoad = (state = {}) => {
  const slice = selectStateApp(state);
	const { unsupportedSurveysToLoad } = slice;
	return unsupportedSurveysToLoad;
};

export const selectDefaultSurvey = (state = {}) => {
  const slice = selectStateApp(state);
	const { defaultSurvey } = slice;
	return defaultSurvey;
};

export const selectDefaultSurveyJobId = (state = {}) => {
  const slice = selectStateApp(state);
	const { defaultSurveyJobId } = slice;
	return defaultSurveyJobId;
};

export const selectSurveyFirstSurveyByExternalId = (state, externalId) => {
  const surveys = selectSurveys(state);
	return surveys.find(s => s.survey_guid === externalId);
};

export const selectKnownSurveysByExternalId = (state, externalId) => {
  const surveys = selectSurveys(state);
	return surveys.filter(
		s => s.survey_guid === externalId && s.subtype && s.subtype !== 'UNKNOWN'
	);
};
