/* eslint-disable import/no-cycle */
import { fetch } from 'whatwg-fetch';
import { hasAccessToken, getAccessToken } from './token';

import getAipUrls, { getURL } from '../../config/getAipUrls';
import AccountUtil from '../../account/components/utils/AccountUtil';
import { isTokenExpired } from '../../util/user';
import { store } from '../store';
import { selectViewAsUserId } from '../redux/AppAnalysisPage/selectors/viewAs';
import { parseQueryString, createNewQueryString } from './location';

const APIS_REQUIRING_VIEW_AS = [
	'scanline',
	'scanlineReport',
	'parseFile'
	// 'scanlineWorkflow', // does not appear to be needed
	// 'scanlineReportV2', // does not appear to be needed
	// 'scanlineReportV2WS', // does not appear to be needed
	// 'scanlineTags', // does not appear to be needed
];

const shouldInjectViewAs = api => APIS_REQUIRING_VIEW_AS.indexOf(api) > -1;

const selectGuestToken = () => {
	if (window && window.location && window.location.search) {
		const { token } = parseQueryString(window.location.search);
		return token;
	}

	return undefined;
};

const shouldInjectGuestToken = () => !!selectGuestToken();

const injectGuestTokenIfPresent = (url = '') => {
	const { token: currUrlToken } = parseQueryString(url.split('?')[1] || '');
	if (!currUrlToken && shouldInjectGuestToken()) {
		const [currPath, currSearch] = url.split('?');
		return `${currPath}${createNewQueryString(
			['token', selectGuestToken()],
			currSearch
		)}`;
	}

	return url;
};

const AIP_URLS = getAipUrls();

export const getViewAsUserId = () => {
	const { app } = store.getState();
	return selectViewAsUserId(app);
};

const refreshToken = options => {
	return AccountUtil.refresh().then(res => {
		const { AuthenticationResult: { AccessToken = '' } = {} } = res;
		return {
			...options,
			headers: { ...options.headers, Authorization: AccessToken }
		};
	});
};

const _fetch = (options, isSecondAttempt = false) => {
	const { aip: aipApp = '', url: optUrl = '' } = options;
	const aipUrl = AIP_URLS[aipApp] || getURL(aipApp);
	const url = `${aipUrl}${optUrl}`;
	return Promise.all([fetch(url, options), options, isSecondAttempt]);
};

const processor = ([res, options, isSecondAttempt]) => {
	const { ok, status } = res;
	if (ok && status === 204) {
		return null;
	}
	if (ok) {
		return res.json();
	}
	return res.json().then(data => {
		const { message } = data;
		if (status === 401 && message === 'Unauthorized' && !isSecondAttempt) {
			return refreshToken(options)
				.then(opt => _fetch(opt, true))
				.then(res2 => processor(res2));
		}
		return Promise.reject(new Error(message));
	});
};

const _ajax = options => {
	return Promise.resolve(isTokenExpired()) // Checks if Token is Expired
		.then(expired => {
			const { headers: { Authorization } = {} } = options;
			if (expired && Authorization !== undefined) {
				// refresh Token if Expired
				return refreshToken(options);
			}
			return options;
		})
		.then(optsWithData => {
			// Replace body with data if data exists
			const { data } = optsWithData;
			if (data !== undefined) {
				const opts = { ...optsWithData, body: data };
				delete opts.data;

				return opts;
			}
			return { ...optsWithData };
		})
		.then(optsWithBody => _fetch(optsWithBody))
		.then(res => processor(res));
};

/* 
const _ajax1 = options => {
	const promise = new Promise((resolve, reject) => {
		if (options.data) {
			options.body = options.data;
			delete options.data;
		}
		const aipurls = getAipUrls();
		fetch(
			(aipurls[options.aip] ? aipurls[options.aip] : '') + options.url,
			options
		)
			.then(res => {
				if (res.ok) {
					if (res.status === 204) {
						resolve(null);
					} else {
						res
							.json()
							.then(data => {
								resolve(data);
							})
							.catch(err => {
								reject(err);
							});
					}
				} else {
					res
						.json()
						.then(data => {
							res.responseJSON = data;
							if (res.status === 401 && data.message === 'Unauthorized') {
								AccountUtil.refresh((err, r2) => {
									if (err) {
										reject(r2);
									} else {
										options.headers.Authorization =
											res.AuthenticationResult.AccessToken;
										_ajax(options)
											.then(r3 => {
												resolve(r3);
											})
											.catch(() => {
												reject(r2);
											});
									}
									// console.log(res);
								});
								// console.log('getnew token')
							} else {
								reject(res);
							}
						})
						.catch(err => {
							// eslint-disable-next-line prefer-promise-reject-errors
							reject({ ...err, status: res.status });
						});
				}
			})
			.catch(err => {
				reject(err);
			});
	});

	return promise;
};
 */

const ajaxJSONParam = ({
	url,
	data,
	method,
	callback,
	isAnonymous,
	aip = 'scanline',
	token = getAccessToken()
}) => {
	if (hasAccessToken() || isAnonymous) {
		const headers = {
			'Content-Type': 'application/json'
		};
		if (shouldInjectViewAs(aip)) {
			headers['X-View-As-User-Id'] =
				encodeURIComponent(getViewAsUserId()) || '';
		}
		if (!isAnonymous) {
			headers.Authorization = token;
		}

		return _ajax({
			aip,
			data,
			url: injectGuestTokenIfPresent(url),
			headers,
			method
		})
			.then(res => {
				if (callback) {
					callback(null, res);
				} else {
					return res;
				}
				return undefined;
			})
			.catch(err => {
				if (callback) {
					callback(err);
				} else {
					throw err;
				}
			});
	}

	if (callback) {
		callback(new Error('Not Logged In'));
	} else {
		throw new Error('Not Logged In');
	}

	return undefined;
};

export default (url, data, method, callback, isAnonymous, aip = 'scanline') => {
	return ajaxJSONParam({ url, data, method, callback, isAnonymous, aip });
};

// HELPER
// original code was implemented with error param as first param, response as second param
// eslint-disable-next-line no-unused-vars
const handleError = err => {
	// console.log(err);
};
export const createLegacyCallbackWrapper = callback => (err, res) => {
	if (err) {
		handleError(err);
	} else {
		callback(res);
	}
};

export const createHandleResolveReject = (resolve, reject) => (err, res) => {
	return err ? reject(err) : resolve(res);
};

// DEFAULT

export { ajaxJSONParam as ajaxNew };
