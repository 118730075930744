/** 
 * HELPERS
 */

const selectId = (val) => {
  if (typeof val === 'object') {
    // assume val === object
    return val?.id;
  }

  // assume val === id value
  return val;
}

const _findIndex = (items, item) => {
  const id = selectId(item);
  return items.findIndex((i) => i.id === id);
}



/** 
 * MAIN - EXPANDED ROWS
 */
const expandAllRows = (items = []) => {
  return items.reduce((acc, item, idx) => { 
    const id = selectId(item);
    acc[id] = idx + 1;
    return acc;
  }, {})
}

const expandRow = (items, expandedRowMapById, item) => { 
  const id = selectId(item);
  const idx = _findIndex(items, item);
  const clone = { ...expandedRowMapById };
  clone[id] = idx > -1 ? idx + 1 : idx; // track position (idx + 1)
  return clone;
}

const collapseRow = (expandedRowMapById, item) => {
  const id = selectId(item);
  const clone = { ...expandedRowMapById };
  delete clone[id];
  return clone;
}

const toggleRow = (items, expandedRowMapById, item) => { 
  const id = selectId(item);
  if (expandedRowMapById[id]) { 
    return collapseRow(expandedRowMapById, item);
  }
  return expandRow(items, expandedRowMapById, item);
}


export { 
  expandAllRows,
  expandRow,
  collapseRow,
  toggleRow,
}

export default {
  selectId,

  expandAllRows,
  expandRow,
  collapseRow,
  toggleRow,
}