"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combine = void 0;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/* eslint-disable import/prefer-default-export */

/* eslint-disable no-param-reassign */


var combineLabels = function combineLabels(mappingBase, mappingPrimary, output) {
  if (mappingBase !== null && mappingBase !== void 0 && mappingBase.LABELS || mappingPrimary !== null && mappingPrimary !== void 0 && mappingPrimary.LABELS) {
    output.LABELS = _objectSpread(_objectSpread({}, (mappingBase === null || mappingBase === void 0 ? void 0 : mappingBase.LABELS) || {}), (mappingPrimary === null || mappingPrimary === void 0 ? void 0 : mappingPrimary.LABELS) || {});
  }
};

var combineAbbreviations = function combineAbbreviations(mappingBase, mappingPrimary, output) {
  if (mappingBase !== null && mappingBase !== void 0 && mappingBase.ABBRV || mappingPrimary !== null && mappingPrimary !== void 0 && mappingPrimary.ABBRV) {
    output.ABBRV = _objectSpread(_objectSpread({}, (mappingBase === null || mappingBase === void 0 ? void 0 : mappingBase.ABBRV) || {}), (mappingPrimary === null || mappingPrimary === void 0 ? void 0 : mappingPrimary.ABBRV) || {});
  }
};

var combineViews = function combineViews(mappingBase, mappingPrimary, output) {
  if (mappingBase !== null && mappingBase !== void 0 && mappingBase.VIEWS || mappingPrimary !== null && mappingPrimary !== void 0 && mappingPrimary.VIEWS) {
    output.VIEWS = {};

    var keys = _toConsumableArray(new Set([].concat(_toConsumableArray(Object.keys((mappingBase === null || mappingBase === void 0 ? void 0 : mappingBase.VIEWS) || {})), _toConsumableArray(Object.keys((mappingPrimary === null || mappingPrimary === void 0 ? void 0 : mappingPrimary.VIEWS) || {})))));

    keys.forEach(function (key) {
      output.VIEWS[key] = {};
      combineLabels(((mappingBase === null || mappingBase === void 0 ? void 0 : mappingBase.VIEWS) || {})[key], ((mappingPrimary === null || mappingPrimary === void 0 ? void 0 : mappingPrimary.VIEWS) || {})[key], output.VIEWS[key]);
      combineAbbreviations(((mappingBase === null || mappingBase === void 0 ? void 0 : mappingBase.VIEWS) || {})[key], ((mappingPrimary === null || mappingPrimary === void 0 ? void 0 : mappingPrimary.VIEWS) || {})[key], output.VIEWS[key]);
    });
  }
};

var combine = function combine(mappingBase, mappingPrimary) {
  var result = {};
  combineLabels(mappingBase, mappingPrimary, result);
  combineAbbreviations(mappingBase, mappingPrimary, result);
  combineViews(mappingBase, mappingPrimary, result);
  return result;
};

exports.combine = combine;