/* eslint-disable eqeqeq */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import createSortCompare from '../../../../../../commons/module/CustomReactVirtualized/util/createSortCompare';
import { getUserName } from '../../../../../../util/user';
import { ROOT_URL } from '../../../../../constants';
import {
	clearFromQueryString,
	createNewQueryString
} from '../../../../../utils/location';

const clean = urlQueryString => {
	let results = urlQueryString;
	results = clearFromQueryString('p_id', results);
	results = clearFromQueryString('project', results);
	results = clearFromQueryString('status', results);
	results = clearFromQueryString('line', results);
	results = clearFromQueryString('linename', results);
	return results;
};

const generateLineUrlQueryParams = (urlQueryString, lineId, lineName) => {
	let results = clean(urlQueryString);
	results = createNewQueryString(['line', lineId], results);
	results = createNewQueryString(['linename', lineName], results);
	return results;
};

const generateProjectUrlQueryParams = (
	urlQueryString,
	lineId,
	lineName,
	jobId,
	projectId,
	projectName
) => {
	let results = clean(urlQueryString);
	results = createNewQueryString(['p_id', projectId], results);
	results = createNewQueryString(['project', projectName], results);
	return results;
};


const generateLineBasedProjectUrlQueryParams = (
	urlQueryString,
	lineId,
	lineName,
	jobId,
	projectId
) => {
	let results = urlQueryString;
	results = createNewQueryString(['p_id', projectId], results);
	results = createNewQueryString(['line', lineId], results);
  results = createNewQueryString(['linename', lineName], results);
  
	return results;
};

const PARENT_TYPES = {
	LINE: 'LINE',
	PROJECT: 'PROJECT',
	LINE_BASED_PROJECT: 'LINE BASED PROJECT'
};

const selectGenerateUrlQueryParams = parentType => {
	switch (parentType) {
		case PARENT_TYPES.LINE:
			return generateLineUrlQueryParams;
		case PARENT_TYPES.PROJECT:
			return generateProjectUrlQueryParams;
		case PARENT_TYPES.LINE_BASED_PROJECT:
			return generateLineBasedProjectUrlQueryParams;
		default:
			return () => '#';
	}
};

export const generateAnalysisPageUrl = reportMeta => {
	const {
		parent_type: parentType,
		reportType,
		isProject,
		line = {},
		project = {},
		primarySurvey = {},
		technician = {}
	} = reportMeta;
	const { jobId } = primarySurvey;
	const { userid: techUserId } = technician;

	const LINE_BASE_PATH = 'map';
	const PROJECT_BASE_PATH = 'my-projects';
	const REPORT_TYPE = (reportType || '').toUpperCase();
	const OWNER_USER_ID =
		techUserId &&
		techUserId.toLowerCase() !== (getUserName() || '').toLowerCase()
			? techUserId
			: undefined;
	const location = window && window.location;

	if (location) {
		let urlQueryString = location.search;

		urlQueryString = clearFromQueryString('autoOpenReport', urlQueryString);
		urlQueryString = clearFromQueryString('viewAs', urlQueryString);
		urlQueryString = clearFromQueryString('mode', urlQueryString);

		if (REPORT_TYPE) {
			urlQueryString = createNewQueryString(
				['autoOpenReport', REPORT_TYPE],
				urlQueryString
			);
		}

		if (OWNER_USER_ID) {
			urlQueryString = createNewQueryString(
				['viewAs', OWNER_USER_ID],
				urlQueryString
			);
			urlQueryString = createNewQueryString(
				['mode', 'oversight'],
				urlQueryString
			);
		}

		const generateUrlQueryParams = selectGenerateUrlQueryParams(parentType);
		const BASE_PATH = isProject ? PROJECT_BASE_PATH : LINE_BASE_PATH;

		return `${ROOT_URL}${BASE_PATH}${generateUrlQueryParams(
			urlQueryString,
			line.id,
			line.name,
			jobId,
			project.id,
			project.name
		)}`;
	}

	return undefined;
};

export const selectStatusCssSelector = (data = [], index) => {
	const obj = data[index] || {};
	const { status = '' } = obj;

	return `status-${status
		.toLowerCase()
		.split(' ')
		.join('-')}`;
};

const validSortKeys = [
	'pipelineName',
	'report_status_name',
	'reportType',
	'technician'
];
const selectPipelineName = report =>
	(report.line.name || report.project.name || '').trim() || undefined;
const selectTechnicianString = report =>
	(report.technician.name || report.technician.userid || '').trim();

export const sortReports = (reports, key, dir) => {
	const compare = createSortCompare(dir, { invertNotDefined: true });

	if (!dir || !key) {
		return reports;
	}

	if (key === 'pipelineName') {
		return [...reports].sort((a, b) => {
			const aVal = selectPipelineName(a);
			const bVal = selectPipelineName(b);
			return compare(aVal, bVal);
		});
	}

	if (key === 'technician') {
		return [...reports].sort((a, b) => {
			const aVal = selectTechnicianString(a);
			const bVal = selectTechnicianString(b);
			return compare(aVal, bVal);
		});
	}
	if (validSortKeys.indexOf(key) > -1) {
		return [...reports].sort((a, b) => {
			const aVal = _.get(a, key);
			const bVal = _.get(b, key);
			return compare(aVal, bVal);
		});
	}

	return reports;
};
