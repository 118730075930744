/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';
import { formatUser, sortUsers } from './util/user';

export const fetchProjectManagers = async (customerName) => {
	const url = '/pms';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'POST',
      data: {
        customer: customerName
      }      
		});

    return fetchData().then((users = []) => { 
      const formattedUsers = users.map(formatUser);
      formattedUsers.sort(sortUsers);
      return formattedUsers;
    });
	}
	return Promise.reject(new Error('Missing Token'));
};

