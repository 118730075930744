import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Remediation Types';
const PROP_KEY = 'reportRemediationTypes';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT }) => {
	const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

	return (
		<td>
			<HelperRenderStringList strList={value} direction="vertical" />
		</td>
	);
};

const ColRemediationTypes = { TH, TD, CSV_PROP: PROP_KEY, DEFAULT_HEADER };

export default ColRemediationTypes;
