/* eslint-disable import/prefer-default-export */
const { forceSetProps } = require("../../../../../actions/app");
const {
  didCheckedSurveysChange,
  didMapPopoutStateChange,
  injectCheckedSurveyChange,
  injectSelectedReadingChange,
  injectFocusedChartChange,
  injectHoveredChartChange,
  injectBoundaryXMinXMaxChange,
  didFocusedChartChange,
  didHoveredChartChange
} = require("./util");


export const thunkSetSelectedSurveys = (selectedSurveys) => (
	dispatch,
	getState
) => {
  const state = getState();
  const hasChanges = didCheckedSurveysChange(state, selectedSurveys);

  if (hasChanges) {
    const newProps = injectCheckedSurveyChange(state, {}, selectedSurveys);
    dispatch(forceSetProps(newProps));
  }	
};


export const thunkSetFocusedChart = (focusedChart) => (
	dispatch,
	getState
) => {
  const state = getState();
  const hasChanges = didFocusedChartChange(state, focusedChart);

  if (hasChanges) {
    const newProps = injectFocusedChartChange(state, {}, focusedChart);
    dispatch(forceSetProps(newProps));
  }	
};


export const thunkSetHoveredChart = (hoveredChart) => (
	dispatch,
	getState
) => {
  const state = getState();
  const hasChanges = didHoveredChartChange(state, hoveredChart);

  if (hasChanges) {
    const newProps = injectHoveredChartChange(state, {}, hoveredChart);
    dispatch(forceSetProps(newProps));
  }	
};

export const thunkSetMapPopoutState = (mapPopoutState) => (
	dispatch,
	getState
) => {
  const state = getState();
  const hasChanges = didMapPopoutStateChange(state, mapPopoutState);

  if (hasChanges) {
    let newProps = {};
    const {
      selectedSurveys,
      selectedReadingSurvey,
      selectedReading,
      focusedChart,
      hoveredChart,
      boundaryXMinXMax
    } = mapPopoutState;

    newProps = injectCheckedSurveyChange(state, newProps, selectedSurveys);
    newProps = injectSelectedReadingChange(state, newProps, selectedReadingSurvey, selectedReading);
    newProps = injectFocusedChartChange(state, newProps, focusedChart);
    newProps = injectHoveredChartChange(state, newProps, hoveredChart);
    newProps = injectBoundaryXMinXMaxChange(state, newProps, boundaryXMinXMax);
    dispatch(forceSetProps(newProps));
  }	
};
