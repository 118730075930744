/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import ajax from './ajax';

const encodeData = data => {
	if (!data) {
		return data;
	}

	if (data instanceof Object) {
		return JSON.stringify(data);
	}

	return data;
};

export const createPromiseRequest = ({
	url,
	data: paramData,
	method = 'GET',
	aip = 'platform',
	isAnonymous
}) => () => {
	const data = encodeData(paramData);	
	return new Promise((resolve, reject) => {
		ajax(
			url,
			data,
			method,
			(err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			},
			isAnonymous,
			aip
		);
	});
};
