/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import fieldlineUtils from '../scanlineUtils';
import { addChartIsLoading, removeChartIsLoading } from './charts';
import {
	createInterpolatedDataSources,
	createNormalizedInterpolatedDataSources,
	isInterpolateReferenceSurvey,
	canSurveyBeInterpolated,
	selectInterpolateTargetSurveys,
	addInterpolatedSourceValuesToReferenceDataset,
	addNormalizedInterpolatedSourceValuesToReferenceDataset,
	selectInterpolateReferenceSurvey
} from '../redux/AppAnalysisPage/util/readings.interpolate';
import { setReadingsDownloadMessage } from './dataDownloadMessages';
import { getChartId } from '../utils/chart';
import { thunkRecomputeAllDataXYMinsMaxes } from '../redux/AppAnalysisPage/slices/StoreXYMinMax/actions';
import { thunkDecimateCisSurvey } from '../redux/AppAnalysisPage/slices/ReadingsStore/actions';
import { thunkDecimateDerivedSurveys } from '../redux/AppAnalysisPage/slices/ReadingsStore/actions/thunks.cisDecimate';

const READINGS_BATCH_SIZE = 10000;

const {
	clearReadings,
	selectReading,
	normalizeReadings,
	readingsLoaded,
	receiveReadings,
	requestReadings,
	setReadingDownloadsComplete,
	setReadingsInitialized,
	setUseInterpolatedDepol,
	setUseNormalizedDepol,
	receiveInterpolatedReadings,
	receiveNormalizedInterpolatedReadings
} = createActions({
	CLEAR_READINGS: () => ({}),
	SELECT_READING: (survey, reading) => ({ survey, reading }),
	FETCH_READINGS: (jobId, type, subtype, index) =>
		doFetchReadings(jobId, type, subtype, index),
	NORMALIZE_READINGS: (survey, copyDepol) => ({ survey, copyDepol }),
	READINGS_LOADED: survey => ({ survey }),
	RECEIVE_READINGS: (json, survey, batchIdx, batchCount) => {
		return {
			data: json.data,
			meta: {
				filteredCount: json.filteredCount,
				originalCount: json.originalCount
			},
			survey,
			batchIdx,
			batchCount
		};
	},
	REQUEST_READINGS: survey => ({ survey }),
	SET_READING_DOWNLOADS_COMPLETE: () => ({}),
	SET_READINGS_INITIALIZED: () => ({}),
	SET_USE_INTERPOLATED_DEPOL: (survey, useInterpolatedDepol) => ({
		survey,
		useInterpolatedDepol
	}),
	SET_USE_NORMALIZED_DEPOL: (survey, useNormalizedDepol) => ({
		survey,
		useNormalizedDepol
	}),
	RECEIVE_INTERPOLATED_READINGS: (
		survey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	) => ({
		survey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	}),
	RECEIVE_NORMALIZED_INTERPOLATED_READINGS: (
		survey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	) => ({
		survey,
		readings,
		readingsWithChartGaps,
		readingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		series,
		seriesIndexMap,
		readingsStationIdIntegers
	})
});

const computeNewSurveysToLoad = (surveysToLoad, surveysLoading) => {
	return surveysToLoad.filter(
		s1 => surveysLoading.findIndex(s2 => s1.internalId === s2.internalId) < 0
	);
};

const doFetchMissingVisibleReadings = (
	linename,
	callback = (err, res) => {}
) => (dispatch, getState) => {
	// console.log(linename);
	// debugger;

	const { app } = getState();
	const { surveysToLoad = [], surveysLoading = [] } = app;

	const newSurveysToLoad = computeNewSurveysToLoad(
		surveysToLoad,
		surveysLoading
	).filter((s, idx, src) =>
		src.find(s2 => s2.job_number === s.job_number && s2.checked)
	);

	const readingsCount = newSurveysToLoad.length;
	let readingsLoadedCount = 0;
	const onReadingsLoadComplete = err => {
		if (err) {
			callback(err);
		} else {
			readingsLoadedCount += 1;
			if (readingsLoadedCount === readingsCount) {
				newSurveysToLoad.forEach(s => {
					dispatch(setReadingsDownloadMessage(s.id, undefined));
				});
				callback(undefined, 'all readings loaded');
			}
		}
	};

	if (readingsCount) {
		newSurveysToLoad.forEach(s => {
			dispatch(doFetchReadings(linename, s, onReadingsLoadComplete));
			dispatch(
				setReadingsDownloadMessage(
					s.id,
					`downloading readings for survey - ${fieldlineUtils.getSurveyDisplayName(
						s
					)}`
				)
			);
		});
	} else {
		dispatch(setReadingDownloadsComplete());
		dispatch(setReadingsInitialized());
		callback(undefined, 'no readings to load');
	}
};

const doFetchMissingReadings = (linename, callback = (err, res) => {}) => (
	dispatch,
	getState
) => {
	// console.log(linename);
	// debugger;

	const { app } = getState();
	const { surveysToLoad = [], surveysLoading = [] } = app;

	const newSurveysToLoad = computeNewSurveysToLoad(
		surveysToLoad,
		surveysLoading
	);

	const readingsCount = newSurveysToLoad.length;
	let readingsLoadedCount = 0;
	const onReadingsLoadComplete = err => {
		if (err) {
			callback(err);
		} else {
			readingsLoadedCount += 1;
			if (readingsLoadedCount === readingsCount) {
				newSurveysToLoad.forEach(s => {
					dispatch(setReadingsDownloadMessage(s.id, undefined));
				});
				callback(undefined, 'all readings loaded');
			}
		}
	};

	if (readingsCount) {
		newSurveysToLoad.forEach(s => {
			dispatch(doFetchReadings(linename, s, onReadingsLoadComplete));
			dispatch(
				setReadingsDownloadMessage(
					s.id,
					`downloading readings for survey - ${fieldlineUtils.getSurveyDisplayName(
						s
					)}`
				)
			);
		});
	} else {
		dispatch(setReadingDownloadsComplete());
		dispatch(setReadingsInitialized());
		callback(undefined, 'no readings to load');
	}
};

// eslint-disable-next-line no-unused-vars
const doFetchReadings = (
	linename,
	survey,
	callback = (err, res) => {}
) => dispatch => {
	// console.log(linename);
	// debugger;

	const batchCount = getBatchCount(survey.count);
	let batchCompleteCount = 0;
	const onBatchesComplete = err => {
		if (err) {
			callback(err);
		} else {
			batchCompleteCount += 1;
			if (batchCompleteCount === batchCount) {
				callback(undefined, 'all reading batches loaded');
			}
		}
	};
	if (batchCount) {
		dispatch(requestReadings(survey));
		for (let idx = 0; idx < batchCount; idx += 1) {
			const requestIndex = idx * READINGS_BATCH_SIZE;
			dispatch(addChartIsLoading(getChartId(survey)));
			dispatch(
				fetchReadingsBatch(linename, survey, requestIndex, onBatchesComplete)
			);
		}
	} else {
		callback(undefined, 'no reading batches to load');
	}
};

const fetchReadingsBatch = (
	linename,
	survey,
	requestIndex,
	callback = (err, res) => {}
) => (dispatch, getState) => {
	// console.log(linename);
	// debugger;

	const {
		survey_type: type,
		survey_subtype: subtype,
		survey_guid: surveyGuid,
		isNative
	} = survey;
	return fieldlineUtils.getReadings(
		surveyGuid,
		type,
		isNative ? 'NATIVE' : subtype,
		requestIndex,
		linename,
		(err, res) => {
			if (err) {
				// console.error(err);
			} else {
				dispatch(
					receiveReadings(
						res,
						survey,
						getBatchIndex(res.index),
						getBatchCount(survey.count)
					)
				);
				if (!isDownloadingThisSurveyReadings(getState().app, survey)) {
					dispatch(readingsLoaded(survey));

					// whitelist surveys that need to be normalized
					const subtypes = ['ON_OFF', 'ON-OFF', 'DEPOL', 'DCVG', 'ACCA'];
					if (type === 'CIS' && subtypes.indexOf(subtype) !== -1) {
						dispatch(normalizeReadings(survey, true));
					}

					if (!isDownloadingReadings(getState().app)) {
						dispatch(setReadingDownloadsComplete());
						dispatch(setReadingsInitialized());
					}
					dispatch(createInterpolatedReadings(survey));
					dispatch(createNormalizedInterpolatedReadings(survey));
					dispatch(thunkRecomputeAllDataXYMinsMaxes());
					dispatch(thunkDecimateCisSurvey(survey));
					dispatch(thunkDecimateDerivedSurveys());
					setTimeout(() => {
						dispatch(removeChartIsLoading(getChartId(survey)));
					}, 8);
				}
				callback(undefined, 'single batch complete');
			}
		}
	);
};

const isDownloadingThisSurveyReadings = (state, survey) => {
	const { loadingReadings } = state;
	const { count: readingsCount, id } = survey;
	return (
		loadingReadings[id] &&
		loadingReadings[id].length === getBatchCount(readingsCount) &&
		loadingReadings[id].some(batch => !batch)
	);
};

const isDownloadingReadings = state => {
	const { loadingReadings } = state;
	return (
		Object.keys(loadingReadings).filter(prop => !!loadingReadings[prop])
			.length > 0
	);
};

const getBatchCount = readingsCount =>
	Math.ceil(+readingsCount / READINGS_BATCH_SIZE);

const getBatchIndex = surveyIndex =>
	Math.floor(surveyIndex / READINGS_BATCH_SIZE) - 1;

const createInterpolatedReadings = survey => (dispatch, getState) => {
	if (isInterpolateReferenceSurvey(survey)) {
		const refSurvey = survey;
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			refSurvey,
			getState().app
		);
		surveysToInterpolate.forEach(srcSurvey => {
			const [
				newReadings,
				newReadingsWithChartGaps,
				newReadingsMap,
				newReadingsIndexMap,
				newReadingsWithChartGapsIndexMap,
				newSeries,
				newSeriesIndexMap,
				newReadingsStationIdIntegers
			] = createInterpolatedDataSources(srcSurvey, getState().app);

			const refReadingsMap = getState().app.readingsMap[refSurvey.id];
			addInterpolatedSourceValuesToReferenceDataset(
				newReadings,
				refReadingsMap
			);
			dispatch(
				receiveInterpolatedReadings(
					srcSurvey,
					newReadings,
					newReadingsWithChartGaps,
					newReadingsMap,
					newReadingsIndexMap,
					newReadingsWithChartGapsIndexMap,
					newSeries,
					newSeriesIndexMap,
					newReadingsStationIdIntegers
				)
			);
		});
	} else if (canSurveyBeInterpolated(survey)) {
		const srcSurvey = survey;
		const refSurvey = selectInterpolateReferenceSurvey(
			srcSurvey,
			getState().app
		);
		if (refSurvey) {
			const refReadingsMap = getState().app.readingsMap[refSurvey.id];
			if (refReadingsMap) {
				const [
					newReadings,
					newReadingsWithChartGaps,
					newReadingsMap,
					newReadingsIndexMap,
					newReadingsWithChartGapsIndexMap,
					newSeries,
					newSeriesIndexMap,
					newReadingsStationIdIntegers
				] = createInterpolatedDataSources(srcSurvey, getState().app);

				addInterpolatedSourceValuesToReferenceDataset(
					newReadings,
					refReadingsMap
				);
				dispatch(
					receiveInterpolatedReadings(
						srcSurvey,
						newReadings,
						newReadingsWithChartGaps,
						newReadingsMap,
						newReadingsIndexMap,
						newReadingsWithChartGapsIndexMap,
						newSeries,
						newSeriesIndexMap,
						newReadingsStationIdIntegers
					)
				);
			}
		}
	}
};

const createNormalizedInterpolatedReadings = survey => (dispatch, getState) => {
	if (isInterpolateReferenceSurvey(survey)) {
		const refSurvey = survey;
		const surveysToInterpolate = selectInterpolateTargetSurveys(
			refSurvey,
			getState().app
		);
		surveysToInterpolate.forEach(srcSurvey => {
			const [
				newReadings,
				newReadingsWithChartGaps,
				newReadingsMap,
				newReadingsIndexMap,
				newReadingsWithChartGapsIndexMap,
				newSeries,
				newSeriesIndexMap,
				newReadingsStationIdIntegers
			] = createNormalizedInterpolatedDataSources(srcSurvey, getState().app);

			const refReadingsMap = getState().app.readingsMap[refSurvey.id];
			addNormalizedInterpolatedSourceValuesToReferenceDataset(
				newReadings,
				refReadingsMap
			);
			dispatch(
				receiveNormalizedInterpolatedReadings(
					srcSurvey,
					newReadings,
					newReadingsWithChartGaps,
					newReadingsMap,
					newReadingsIndexMap,
					newReadingsWithChartGapsIndexMap,
					newSeries,
					newSeriesIndexMap,
					newReadingsStationIdIntegers
				)
			);
		});
	} else if (canSurveyBeInterpolated(survey)) {
		const srcSurvey = survey;
		const refSurvey = selectInterpolateReferenceSurvey(
			srcSurvey,
			getState().app
		);
		if (refSurvey) {
			const refReadingsMap = getState().app.readingsMap[refSurvey.id];
			if (refReadingsMap) {
				const [
					newReadings,
					newReadingsWithChartGaps,
					newReadingsMap,
					newReadingsIndexMap,
					newReadingsWithChartGapsIndexMap,
					newSeries,
					newSeriesIndexMap,
					newReadingsStationIdIntegers
				] = createNormalizedInterpolatedDataSources(srcSurvey, getState().app);

				addNormalizedInterpolatedSourceValuesToReferenceDataset(
					newReadings,
					refReadingsMap
				);
				dispatch(
					receiveNormalizedInterpolatedReadings(
						srcSurvey,
						newReadings,
						newReadingsWithChartGaps,
						newReadingsMap,
						newReadingsIndexMap,
						newReadingsWithChartGapsIndexMap,
						newSeries,
						newSeriesIndexMap,
						newReadingsStationIdIntegers
					)
				);
			}
		}
	}
};

export {
	clearReadings,
	selectReading, // @todo - a better name for this would be setSelectedReading as selectReading sounds like a selector as apposed to an action creator
	normalizeReadings,
	readingsLoaded,
	receiveReadings,
	requestReadings,
	doFetchReadings,
	doFetchMissingVisibleReadings,
	doFetchMissingReadings,
	setReadingDownloadsComplete,
	setReadingsInitialized,
	READINGS_BATCH_SIZE,
	setUseInterpolatedDepol,
	setUseNormalizedDepol,
	receiveInterpolatedReadings,
	receiveNormalizedInterpolatedReadings
};
