/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  clear,

  collapseAllRows,
  expandRow,
  collapseRow,
  toggleRow,

  expandAllRows,
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
  selectThisSlice,
  selectExpandedRowMapById
} from './selectors';
import { selectFilteredReportsAggs } from "../ReportsAggsFiltersUI/selectors";
import UTIL from './actions/util';

// const isDefined = v => v !== undefined && v !== null;

const EMPTY_OBJECT = {};

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
  },

  //
  // EXPANDED ROWS
  //

  [collapseAllRows]: (state) => { 
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        expandedRowMapById: EMPTY_OBJECT
			}
		};
  },

  [expandAllRows]: (state) => { 
    const rootSlice = selectThisSlice(state);
    const items = selectFilteredReportsAggs(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        expandedRowMapById: UTIL.expandAllRows(items)
			}
		};
  },

	[expandRow]: (state, { payload: { item } }) => {
    const rootSlice = selectThisSlice(state);
    const items = selectFilteredReportsAggs(state);
    const expandedRowMapById = selectExpandedRowMapById(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        expandedRowMapById: UTIL.expandRow(items, expandedRowMapById, item)
			}
		};
  },

	[collapseRow]: (state, { payload: { item } }) => {
    const rootSlice = selectThisSlice(state);
    const expandedRowMapById = selectExpandedRowMapById(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        expandedRowMapById: UTIL.collapseRow(expandedRowMapById, item)
			}
		};
  },

	[toggleRow]: (state, { payload: { item } }) => {
    const rootSlice = selectThisSlice(state);
    const items = selectFilteredReportsAggs(state);
    const expandedRowMapById = selectExpandedRowMapById(state);
		const newState = {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        expandedRowMapById: UTIL.toggleRow(items, expandedRowMapById, item)
			}
    };
    return newState;
  },

};

export default reducerParts;
