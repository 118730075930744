/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect';
import { ACTION_PLAN_STATE_SLICE_KEY as ROOT_SLICE } from './constants';
import { selectStateApp } from '../../../AppAnalysisPage/selectors/main';


export const APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE = 1.1;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const helperSelectActionPlanComparator = (key, actionPlanVersion) => {
	const validComparators = {
		GREATER_THAN: 'GREATER_THAN',
		GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO'
	};

	const result = validComparators[key];

	return (
		result ||
		(actionPlanVersion < APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE
			? 'GREATER_THAN_EQUAL_TO'
      : 'GREATER_THAN')
    
	);
};

// @deprecated
export const selectStateSlice = selectStateApp;

const selectThisSlice = (state) => {
  const slice = selectStateApp(state);
  if (slice && slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already at slice "actionPlanState".
  return slice || EMPTY_OBJECT;
}

// @deprecated
export const selectActionPlanStateSlice = selectThisSlice;

// META
export const selectMeta = (state) => {
	const slice = selectThisSlice(state);
	const { meta = EMPTY_OBJECT } = slice;

	return meta;
};

// @deprecated
export const selectActionPlanStateMeta = selectMeta;

// ACTION PLAN VERSION
export const selectActionPlanStateVersion = (state) => {
	const meta = selectMeta(state);
	const { actionPlanVersion = 0 } = meta;

	return actionPlanVersion;
};

// COMPARATOR
export const selectActionPlanStateComparator = (state) => {
	const meta = selectMeta(state);
	const { actionPlanComparator = '', actionPlanVersion = 0 } = meta;

	return helperSelectActionPlanComparator(
		actionPlanComparator,
		actionPlanVersion
	);
};

// THRESHOLD
export const selectActionPlanStateThreshold = (state) => {
	const meta = selectMeta(state);
	const { threshold = -850 } = meta;

	return threshold;
};

// USE SHADOW
export const selectActionPlanStateUseShadow = (state) => {
	const meta = selectMeta(state);
	const { useShadow = true } = meta;

	return useShadow;
};

// CHART ID
export const selectActionPlanStateChartId = (state) => {
	const meta = selectMeta(state);
	const { chartId } = meta;

	return chartId;
};

// PRIMARY SURVEY ID
export const selectActionPlanStatePrimarySurveyId = (state) => {
	const meta = selectMeta(state);
	const { primarySurveyId } = meta;

	return primarySurveyId;
};

// PRIMARY SURVEY GUID
export const selectActionPlanStatePrimarySurveyGuid = (state) => {
	const meta = selectMeta(state);
	const { primarySurveyGuid } = meta;

	return primarySurveyGuid;
};

// DEPOL SURVEY ID
export const selectActionPlanStateDepolSurveyId = (state) => {
	const meta = selectMeta(state);
	const { depolSurveyId } = meta;

	return depolSurveyId;
};



// REMEDIATION ACTIONS
export const selectActionPlanStateRemediationActions = (state) => {
	const meta = selectMeta(state);
	const { remediationActions = EMPTY_OBJECT } = meta;

	return remediationActions && Object.keys(remediationActions).length > 0
		? remediationActions
		: undefined;
};

// REMEDIATION ACTION TYPES
export const selectActionPlanStateRemediationActionTypesByGroup = (
	state
) => {
	const meta = selectMeta(state);
	const { remediationActionTypesByGroup = EMPTY_OBJECT } = meta;

	return remediationActionTypesByGroup &&
		Object.keys(remediationActionTypesByGroup).length > 0
		? remediationActionTypesByGroup
		: undefined;
};

// CUSTOM GROUPS
export const selectActionPlanStateCustomGroups = (state) => {
	const meta = selectMeta(state);
	const { customGroups = EMPTY_ARRAY } = meta;

	return customGroups && customGroups.length > 0 ? customGroups : undefined;
};

// REMDIATION GROUPS
export const selectActionPlanStateRemediationGroups = (state) => {
	const meta = selectMeta(state);
	const { remediationGroups = EMPTY_ARRAY } = meta;

	return remediationGroups && remediationGroups.length > 0
		? remediationGroups
		: undefined;
};

// USE INTERPOLATED DEPOL
export const selectActionPlanStateUseInterpolatedDepol = (state) => {
	const meta = selectMeta(state);
	const { useInterpolatedDepol = false } = meta;

	return useInterpolatedDepol;
};

// USE NORMALIZED DEPOL
export const selectActionPlanStateUseNormalizedDepol = (state) => {
	const meta = selectMeta(state);
	const { useNormalizedDepol = true } = meta;

	return useNormalizedDepol;
};

// REPORT DATA
export const selectActionPlanStateReportData = (state) => {
	const actionPlanState = selectThisSlice(state);
	const { meta, ...remainingProps } = actionPlanState;

	return remainingProps || EMPTY_OBJECT;
};

// IS ACTION PLAN STATE LOADED
export const selectIsActionPlanStateLoaded = (state = {}) => {
	const { isActionPlanStateLoaded } = state;

	return isActionPlanStateLoaded;
};

// META EXCEPTIONS
export const selectActionPlanStateMetaExceptions = (stateApp = {}) => {
	const meta = selectMeta(stateApp);
	const { exceptions = EMPTY_OBJECT } = meta;

	return exceptions;
};

// FORMDATA EXCEPTIONS
export const selectActionPlanStateFormDataExceptionProps = createSelector(
	selectThisSlice,
	actionPlanState => {
		const {
			'exceptions.belowThreshold': belowThreshold,
			'exceptions.criterion': criterion,
			'exceptions.isBelowCriterion': isBelowCriterion,
			'exceptions.list': list,
			'exceptions.series': series,
			'exceptions.suggestedBelowThreshold': suggestedBelowThreshold,
			'exceptions.suggestedIsBelowCriterion': suggestedIsBelowCriterion,
			'exceptions.totalDistance': totalDistance
		} = actionPlanState;

		return {
			'exceptions.belowThreshold': belowThreshold,
			'exceptions.criterion': criterion,
			'exceptions.isBelowCriterion': isBelowCriterion,
			'exceptions.list': list,
			'exceptions.series': series,
			'exceptions.suggestedBelowThreshold': suggestedBelowThreshold,
			'exceptions.suggestedIsBelowCriterion': suggestedIsBelowCriterion,
			'exceptions.totalDistance': totalDistance
		};
	}
);

// ACTION PLAN STATE ACTION PLAN VERSION
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportVersion = (state = {}) => {
	const { actionReportVersion = 0 } = state;

	return actionReportVersion;
};

// ACTION PLAN STATE ACTION PLAN VERSION
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportComparator = (state = {}) => {
	const { actionReportComparator = '', actionReportVersion = 0 } = state;

	return helperSelectActionPlanComparator(
		actionReportComparator,
		actionReportVersion
	);
};

// ACTION REPORT THRESHOLD
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportThreshold = (state = {}) => {
	const { actionReportThreshold = -850 } = state;

	return actionReportThreshold;
};

// ACTION REPORT USE SHADOW
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportUseShadow = (state = {}) => {
	const { actionReportUseShadow = true } = state;

	return actionReportUseShadow;
};

// ACTION REPORT CUSTOM GROUPS
// @todo - this should be tracked from within the actionPlanState object
export const selectActionReportCustomGroups = (state = {}) => {
	const { actionReportCustomGroups } = state;

	return actionReportCustomGroups;
};

export const selectS3Data = (state = {}) => {
	const meta = selectMeta(state);
	const { s3Data } = meta;
	return s3Data;
};

export const selectS3SurveyData = (
	state = {},
	{ targetKey, parentKey, modifierKey }
) => {
	const s3Data = selectS3Data(state) || {};
	return (
		((s3Data[targetKey] || {})[parentKey] || {})[modifierKey] || EMPTY_OBJECT
	);
};

export const selectIsSyncEnabled = (stateApp = {}) => {
	const { isSyncEnabled } = selectMeta(stateApp);
	return isSyncEnabled;
};

const isValidKey = k => !!k && k !== 'undefined';

const transformS3DataToPayloads = s3Data => {
	const targetKeys = Object.keys(s3Data || {}).filter(isValidKey);

	return targetKeys.reduce((acc, targetKey) => {
		const parentKeys = Object.keys(s3Data[targetKey]).filter(isValidKey);
		parentKeys.forEach(parentKey => {
			const modifierKeys = Object.keys(s3Data[targetKey][parentKey]).filter(
				isValidKey
			);
			modifierKeys.forEach(modifierKey => {
				const s3Keys = s3Data[targetKey][parentKey][modifierKey];
				acc.push({
					targetKey,
					parentKey,
					modifierKey,
					s3Keys
				});
			});
		});

		return acc;
	}, []);
};

export const selectS3DataRequestInfo = state => {
	const s3Data = selectS3Data(state);
	return transformS3DataToPayloads(s3Data);
};
