import { createSelector } from 'reselect';

import { ID_KEY_APPLICATION, ROOT_SLICE } from './constants';
import { selectStateApp } from '../../selectors/main'

/* eslint-disable no-use-before-define */
export const selectStateSlice = selectStateApp;

export const selectStoreXYMinMaxSlice = state => {
  const slice = selectStateApp(state);
  if (slice && slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already at slice "StoreXYMinMax".
  return slice;
};

const selectThisSlice = selectStoreXYMinMaxSlice;

// DATA

export const selectStoreDataXYMinMaxSlice = state => {
  const slice = selectThisSlice(state);
  const { data } = slice;
	return data;
};

export const selectDataXYMinMax = (state, key) => {
	const data = selectStoreDataXYMinMaxSlice(state);
	return data[key];
};

// ZOOM

export const selectStoreZoomXYMinMaxSlice = state => {
  const slice = selectThisSlice(state);
  const { zoom } = slice;
	return zoom;  
};

export const selectZoomXYMinMax = (state, key) => {
	const zoom = selectStoreZoomXYMinMaxSlice(state);
	return zoom[key];
};

export const selectAppZoomXMinMax = state => {
	const { xMin, xMax } = selectZoomXYMinMax(state, ID_KEY_APPLICATION) || {};
	return { xMin, xMax };
};

// INPUT

export const selectStoreInputXYMinMaxSlice = state => {
  const slice = selectThisSlice(state);
  const { input } = slice;
	return input;  
};

export const selectInputXYMinMax = (state, key) => {
	const input = selectStoreInputXYMinMaxSlice(state);
	return input[key];
};

export const selectCurrentXYMinMax = (state, key) => {
	const { yMin: ZoomYMin, yMax: ZoomYMax } =
		selectZoomXYMinMax(state, key) || {};
	const { yMin: DataYMin, yMax: DataYMax } =
		selectDataXYMinMax(state, key) || {};
	return {
		yMax: ZoomYMax === undefined ? DataYMax : ZoomYMax,
		yMin: ZoomYMin === undefined ? DataYMin : ZoomYMin
	};
};

const getXMin = (x, newX) => {
	if (x === undefined) {
		return newX;
	}
	return Math.min(x, newX);
};
const getXMax = (x, newX) => {
	if (x === undefined) {
		return newX;
	}
	return Math.max(x, newX);
};

export const selectHiddenControlSeries = createSelector(
	selectStoreDataXYMinMaxSlice,
	data => {
		const xBoundaries = Object.keys(data).reduce(
			(acc, key) => {
				const { xMin, xMax } = data[key];
				return { xMin: getXMin(acc.xMin, xMin), xMax: getXMax(acc.xMax, xMax) };
			},
			{ xMin: undefined, xMax: undefined }
		);
		const { xMin, xMax } = xBoundaries;
		const series = {
			data: [
				{ id: xMin, value: 0 },
				{ id: xMax, value: 0 }
			],
			dataSetId: 'hiddenControlData',
			parsing: { xAxisKey: 'id', yAxisKey: 'value' },
			backgroundColor: 'rgba(0, 0, 0, 0)',
			borderColor: 'rgba(0, 0, 0, 0)',
			type: 'line',
			hiddenControlData: true
		};
		return [{ ...series }, { ...series, type: 'scatter' }];
	}
);
