/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import _ from 'lodash';
import fieldlineUtils from '../scanlineUtils';

import { selectViewAsUserId } from '../redux/AppAnalysisPage/selectors/viewAs';

import {
	doLoadAllMissingData,
	incrementPendingParseFileRequests,
	decrementPendingParseFileRequests
} from './app';

const {
	uploadDatFile,
	receiveDatFileData,
	requestDatFileData,
	// moveTempS3File,
	receiveMoveTempS3File,
	requestMoveTempS3File
} = createActions({
	UPLOAD_DAT_FILE: file => doUploadDatFile(file),
	RECEIVE_DAT_FILE_DATA: (json, surveyId, meta, uniqueTypeID) => {
		return {
			header: json.header,
			map: json.map,
			data: json.data,
			surveyId,
			meta,
			job_id: uniqueTypeID
		};
	},
	REQUEST_DAT_FILE_DATA: () => ({}),
	// MOVE_TEMP_S3_FILE: (key) => doMoveTempS3File(key),
	RECEIVE_MOVE_TEMP_S3_FILE: newKey => ({ newKey }),
	REQUEST_MOVE_TEMP_S3_FILE: () => ({})
});

const doUploadDatFile = (
	key,
	dbId,
	projectId,
	surveyTypeKey,
	lineId,
	lineName,
	projectName
) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const viewAsUserId = selectViewAsUserId(getState().app)
		dispatch(requestDatFileData());
		dispatch(incrementPendingParseFileRequests());

		const queryNewSurveys = () => {
			setTimeout(() => {
				const state = getState();
				const { app } = state;
				const { countPendingParseFileRequests } = app;

				const isLastUploadRequest = countPendingParseFileRequests === 0;
				if (isLastUploadRequest) {
					dispatch(
						doLoadAllMissingData({
							lineId,
							lineName,
							projectId,
							projectName
						})
					);
					resolve({ uploadedKey: key, allFilesUploaded: true });
				} else {
					resolve({ uploadedKey: key, allFilesUploaded: false });
				}
			}, 1);
		};

		const callback = (err, res) => { 
			if (err) {
				console.error(err);
				reject(err);
			} else { 
				if (!res.success) {
					const errorText = `An error occurred when trying to upload the file: ${key}`;
					console.error(errorText);
					reject(errorText);
					return;
				}
				dispatch(decrementPendingParseFileRequests());
				queryNewSurveys();
				resolve('success');
			}
		}

		fieldlineUtils
			.universalDataUpload(key, projectId, lineId, surveyTypeKey, { viewAsUserId, callback });
	});
};

const doMoveTempS3File = key => dispatch =>
	new Promise((resolve, reject) => {
		dispatch(requestMoveTempS3File());

		fieldlineUtils.moveTempS3File(key, (err, res) => {
			if (err) {
				// console.error(err);
				reject(err);
			} else {
				dispatch(receiveMoveTempS3File(res.newKey));
				resolve(res.newKey);
			}
		});
	});

export {
	uploadDatFile,
	receiveDatFileData,
	requestDatFileData,
	doUploadDatFile,
	doMoveTempS3File,
	receiveMoveTempS3File,
	requestMoveTempS3File
};
