/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setIsLoadingLoaded, clear } from './actions';
import { listDdbCustomers } from './api';
import { selectIsLoaded, selectIsLoading } from '../selectors';
import { listPsqlCustomersV2 } from '../../../../../admin/api/customers';

const thunkLoad = () => dispatch => {
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
	listPsqlCustomersV2().then(data => {
		dispatch(set(data.map(({ name }) => name)));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
	const isLoaded = selectIsLoaded(getState());
	const isLoading = selectIsLoading(getState());
	if (!isLoaded && !isLoading) {
		dispatch(thunkLoad());
	}
};

export { thunkLoad, thunkLoadIfNotLoaded };
