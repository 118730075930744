import { EMPTY_OBJECT, LAMBDA } from './constants';
import { isDefined, isEmptyString } from './util';

export const _createSelectors = ({ selectThisSlice, selectParentSlice }) => { 


  const selectChartActionPlanChangeNotes = (state, { chartId } = {}) => {
    const slice = selectThisSlice(state);
    const actionPlanChangeNotes = slice[chartId] || EMPTY_OBJECT;
    return actionPlanChangeNotes;    
  };

  const selectNotes = (state, { chartId } = {}) => {
    const { notes } = selectChartActionPlanChangeNotes(state, { chartId });
    return notes;
  };

  const selectHasNotes = (state, { chartId } = {}) => {
    const notes = selectNotes(state, { chartId });
    return isDefined(notes) && !isEmptyString(notes);
  };
  
  const selectShowModal = (state, { chartId } = {}) => {
    const { showModal } = selectChartActionPlanChangeNotes(state, { chartId });
    return showModal;
  };

  const selectSideEffects = (state, { chartId } = {}) => {
    const { sideEffects = EMPTY_OBJECT } = selectChartActionPlanChangeNotes(state, { chartId });  
    return sideEffects;
  };

  const selectOnCancelChangeNotes = (state, { chartId } = {}) => {
    const { onCancelChangeNotes = LAMBDA } = selectSideEffects(state, { chartId });
    return onCancelChangeNotes;
  };

  const selectOnSaveChangeNotes = (state, { chartId } = {}) => {
    const { onSaveChangeNotes = LAMBDA } = selectSideEffects(state, { chartId });
    return onSaveChangeNotes;
  };      

  return {
    selectThisSlice,
    selectParentSlice,

    selectChartActionPlanChangeNotes,
    selectNotes,
    selectHasNotes,
    selectShowModal,
    selectOnCancelChangeNotes,
    selectOnSaveChangeNotes
  }
}

export default _createSelectors;
