import { createSelector } from 'reselect';
import { selectStateApp } from '../../../AppAnalysisPage/selectors/main';
import { selectRootMainMapPopoutState } from '../../Main/selectors';
import { selectSelectedSurveys } from '../../../AppAnalysisPage/selectors/surveys';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootMainMapPopoutState(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already Core.
  return state;
};

const EMPTY_ARRAY = [];

const _selectSelectedSurveys = (state) => {
  return selectSelectedSurveys(state) || EMPTY_ARRAY;
}

const _selectSelectedReadingSurvey = (state) => {
  const app = selectStateApp(state);
  return app?.selectedReadingSurvey;
}

const _selectSelectedReading = (state) => {
  const app = selectStateApp(state);
  return app?.selectedReading;
}

const _selectFocusedChart = (state) => {
  const app = selectStateApp(state);
  return app?.focusedChart;
}

const _selectHoveredChart = (state) => {
  const app = selectStateApp(state);
  return app?.hoveredChart;
}

const _selectBoundaryXMinMax = (state) => {
  const app = selectStateApp(state);
  return app?.boundaryXMinXMax;
}

export const selectMapPopoutState = createSelector(
  _selectSelectedSurveys,
  _selectSelectedReadingSurvey,
  _selectSelectedReading,
  _selectFocusedChart,
  _selectHoveredChart,
  _selectBoundaryXMinMax,
  (
    selectedSurveys,
    selectedReadingSurvey,
    selectedReading,
    focusedChart,
    hoveredChart,
    boundaryXMinXMax,
  ) => ({
    selectedSurveys,
    selectedReadingSurvey,
    selectedReading,
    focusedChart,
    hoveredChart,
    boundaryXMinXMax,
  })
);

export const selectMapPopoutStateForTabSync = createSelector(
  selectMapPopoutState,
  (state) => { 
    return {
      ...state,
      selectedSurveys: (state.selectedSurveys || []).map(s => ({ id: s.id })),
      selectedReadingSurvey: state?.selectedReadingSurvey ?
        ({ id: state.selectedReadingSurvey?.id }) :
        undefined,
    };
  }
)

export default {
  selectThisSlice,
  selectMapPopoutState,
  selectMapPopoutStateForTabSync
};
