import React, { useMemo } from 'react';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Below -850mv';
const PROP_KEY = 'bcPercentage';

const isDefined = v => v !== undefined && v !== null;

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT }) => {
	const { value, style } = useMemo(() => {
		const bcPercentage = (item || {})[PROP_KEY];
		if (isDefined(bcPercentage)) {
			return {
				value: `${Number.parseFloat(bcPercentage).toString()}%`,
				style: {
					color: Number.parseFloat(bcPercentage) > 0 ? 'red' : 'inherit'
				}
			};
		}

		return {
			value: '',
			style: {}
		};
	}, [item]);

	return <td style={style}>{value}</td>;
};

const ColNeg850mv = { TH, TD, CSV_PROP: PROP_KEY, DEFAULT_HEADER };

export default ColNeg850mv;
