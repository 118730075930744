/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
	_isValidTargetSurvey,
	_isValidPrimarySurvey
} from '../util/alignedReadings.align';
import { selectSeries, selectSeriesIndexMap } from './series';
import { getChartId } from '../../../utils/chart';
import { selectDataXYMinMax } from '../slices/StoreXYMinMax/selectors';
import { selectStateApp } from './main';


const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const DEFAULT_ALIGNMENT_THRESHOLD_IN_FEET = 35;

const isDefined = v => v !== undefined && v !== null;

const memSurveysRenderToSurvey = _.memoize(
	(controls, listSurveyIds = '', primarySurveyId) => {
		const surveyIds = listSurveyIds.split(',');
		return surveyIds.filter(
			id => controls[id] && controls[id].renderToSurvey === primarySurveyId
		);
	}
);

const _selectSurveyIdsRenderingToPrimary = (primarySurvey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsControl: controls } = stateApp;
	const listSurveyIds = (Object.keys(controls || {}) || []).join(',');
	// TODO: Eventually filter by primaryId too
	return memSurveysRenderToSurvey(controls, listSurveyIds, primarySurvey.id);
};

/**
 * ALIGNED READINGS CONTROL
 */
export const selectAlignedReadingsControl = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsControl = {} } = stateApp;
	return alignedReadingsControl[survey.id];
};

/**
 * SURVEY IDS ALIGNED TO PRIMARY
 */
const memSurveyIdsDisplayInRibbon = _.memoize((controls, allSurveyIds = []) => {
	return allSurveyIds.filter(id => controls[id].displayInRibbon);
});

export const selectSurveyIdsAlignedToSelf = (
	primarySurvey = {},
	state = {},
	options = {}
) => {
  const stateApp = selectStateApp(state);
	const { requireDisplayInRibbon = false } = options;
	const { alignedReadingsControl: controls } = stateApp;

	// @note - _selectSurveyIdsRenderingToPrimary is memoized
	const allSurveyIds = _selectSurveyIdsRenderingToPrimary(
		primarySurvey,
		stateApp
	);

	if (!requireDisplayInRibbon) {
		return allSurveyIds;
	}

	return memSurveyIdsDisplayInRibbon(controls, allSurveyIds);
};

export const selectHasSurveysAlignedToSelf = (
	primarySurvey = {},
	state = {},
	options = {}
) => {
  const stateApp = selectStateApp(state);
	const surveysAttached = selectSurveyIdsAlignedToSelf(
		primarySurvey,
		stateApp,
		options
	);
	return surveysAttached.length > 0;
};

/**
 * SURVEYS
 */
const memFilterAlignableSurveys = _.memoize((surveys = []) =>
	surveys.filter(_isValidTargetSurvey)
);

export const selectAlignableSurveys = state => {
  const stateApp = selectStateApp(state);
  const { surveys } = stateApp;
	return memFilterAlignableSurveys(surveys);
};

const memIsValidTargetSurvey = _.memoize((survey = {}) =>
	_isValidTargetSurvey(survey)
);

export const selectIsValidTargetSurvey = survey =>
	memIsValidTargetSurvey(survey);

const memFilterAlignedSurveys = _.memoize(
	(_uniqueKeys, surveys = [], targetSurveyIds) =>
		surveys.filter(s => targetSurveyIds.indexOf(s.id) > -1)
);

export const selectAlignedSurveys = (
	primarySurvey = {},
	state = {},
	options = {}
) => {
  const stateApp = selectStateApp(state);
	const { requireDisplayInRibbon = false } = options;
	const { surveys } = stateApp;

	// @note - selectSurveyIdsAlignedToSelf is memoized
	const targetSurveyIds = selectSurveyIdsAlignedToSelf(
		primarySurvey,
		stateApp,
		{ requireDisplayInRibbon }
	);
	const uniqueKeys = [
		requireDisplayInRibbon,
		primarySurvey.id,
		...targetSurveyIds
	]
		.sort()
		.join(',');
	return memFilterAlignedSurveys(uniqueKeys, surveys, targetSurveyIds);
};

export const selectAlignedSurveysForRibbon = (
  primarySurvey = {},
  state = {}
) => { 
  const stateApp = selectStateApp(state);
	// @note - selectAlignedSurveys is memoized
	return selectAlignedSurveys(primarySurvey, stateApp, {
		requireDisplayInRibbon: true
	});
}

/**
 * ALIGNED DATA STRUCTURES
 */

export const selectAllAlignedDataStructures = (
	primarySurvey = {},
	state = {},
	options = {}
) => {
  const stateApp = selectStateApp(state);
	const { requireDisplayInRibbon = false } = options;
	const { alignedReadings } = stateApp;

	const surveyIds = selectSurveyIdsAlignedToSelf(primarySurvey, stateApp, {
		requireDisplayInRibbon
	});
	const dataStructureStore = alignedReadings[primarySurvey.id] || {};

	return surveyIds.reduce(
		(acc, id) => ({
			...acc,
			[id]: dataStructureStore[id]
		}),
		EMPTY_OBJECT
	);
};

export const selectStatsForAlignment = (
	primarySurvey,
	targetSurvey,
	state
) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsStats } = stateApp;

	const primarySurveyLeaf = alignedReadingsStats[primarySurvey.id];

	if (!primarySurveyLeaf) {
		return null;
	}

	const targetSurveyLeaf = primarySurveyLeaf[targetSurvey.id];

	if (!targetSurveyLeaf) {
		return null;
	}

	return targetSurveyLeaf.stats;
};

export const selectAlignedDataStructures = (
	primarySurvey = {},
	targetSurvey = {},
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const allAlignedDataStructures = selectAllAlignedDataStructures(
		primarySurvey,
		stateApp
	);
	return allAlignedDataStructures[targetSurvey.id] || EMPTY_OBJECT;
};

/**
 * HELPERS: Aligned Readings
 */
export const selectAlignedReadings = (
	primarySurvey,
	targetSurvey,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = selectAlignedDataStructures(primarySurvey, targetSurvey, stateApp);
	return ds.readings || EMPTY_ARRAY;
};

export const selectAlignedReadingsMap = (
	primarySurvey,
	targetSurvey,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = selectAlignedDataStructures(primarySurvey, targetSurvey, stateApp);
	return ds.readingsMap || EMPTY_OBJECT;
};

export const selectIsAlignedRibbonVisible = (primarySurvey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsRibbonsVisible = {} } = stateApp;

	return alignedReadingsRibbonsVisible[primarySurvey.id];
};

export const selectAlignedSeries = (
	primarySurvey,
	targetSurvey,
	readingProp,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = selectAlignedDataStructures(primarySurvey, targetSurvey, stateApp);
	return (ds.series || EMPTY_OBJECT)[readingProp] || EMPTY_ARRAY;
};

/**
 * DATA SET FILTERS
 */
export const _selectAllOriginalFilteredDataSets = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { readingsFiltered } = stateApp;
	return readingsFiltered[survey.id];
};

const selectAllFilteredDataSets = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsFiltered } = stateApp;
	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { renderToSurvey } = control;

	if (renderToSurvey) {
		return alignedReadingsFiltered[renderToSurvey][survey.id];
	}

	return _selectAllOriginalFilteredDataSets(survey, stateApp);
};

export const selectAllReadingFilterKeys = (survey, state) => {
  const stateApp = selectStateApp(state);
	const filteredDataSets = selectAllFilteredDataSets(survey, stateApp);
	return Object.keys(filteredDataSets || {});
};

export const selectHasReadingFilterKeys = (survey, state) => {
  const stateApp = selectStateApp(state);
	return (selectAllReadingFilterKeys(survey, stateApp) || []).length > 0;
};

export const selectReadingFilterKey = (survey, state) => {
  const stateApp = selectStateApp(state);
	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { filterKey } = control;

	return filterKey;
};

export const selectHasReadingFilter = (survey, state) => {
  const stateApp = selectStateApp(state);
	return !!selectReadingFilterKey(survey, stateApp);
};

/**
 * HELPERS: Aligned Readings - EXPLICIT
 */

export const explicitSelectAllAlignedDataStructures = (
	primarySurvey = {},
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const { alignedReadings } = stateApp;

	return alignedReadings[primarySurvey.id] || EMPTY_OBJECT;
};

export const explicitSelectAlignedDataStructures = (
	primarySurvey = {},
	targetSurvey = {},
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const allAlignedDataStructures = explicitSelectAllAlignedDataStructures(
		primarySurvey,
		stateApp
	);
	return allAlignedDataStructures[targetSurvey.id] || EMPTY_OBJECT;
};

export const explicitSelectAlignedReadings = (
	primarySurvey,
	targetSurvey,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = explicitSelectAlignedDataStructures(
		primarySurvey,
		targetSurvey,
		stateApp
	);
	return ds.readings || EMPTY_ARRAY;
};

export const explicitSelectAlignedReadingsMap = (
	primarySurvey,
	targetSurvey,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = explicitSelectAlignedDataStructures(
		primarySurvey,
		targetSurvey,
		stateApp
	);
	return ds.readingsMap || EMPTY_OBJECT;
};

export const explicitSelectIsAlignedRibbonVisible = (
	primarySurvey,
	state
) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsRibbonsVisible = {} } = stateApp;

	return alignedReadingsRibbonsVisible[primarySurvey.id];
};

export const explicitSelectAlignedSeries = (
	primarySurvey,
	targetSurvey,
	readingProp,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds = explicitSelectAlignedDataStructures(
		primarySurvey,
		targetSurvey,
		stateApp
	);
	return (ds.series || EMPTY_OBJECT)[readingProp] || EMPTY_ARRAY;
};
/**
 * DATA SET FILTERS - EXPLICIT
 */

export const explicitSelectAllFilteredDataSets = (
	survey,
	refSurvey,
	state
) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsFiltered } = stateApp;
	return (alignedReadingsFiltered[refSurvey.id] || {})[survey.id];
};

export const explicitSelectAllReadingFilterKeys = (
	survey,
	refSurvey,
	state
) => {
  const stateApp = selectStateApp(state);
	const filteredDataSets = explicitSelectAllFilteredDataSets(
		survey,
		refSurvey,
		stateApp
	);
	return Object.keys(filteredDataSets || {});
};

export const explicitSelectAlignedFilteredSeries = (
	primarySurvey,
	targetSurvey,
	readingProp,
	filterKey,
	state = {}
) => {
  const stateApp = selectStateApp(state);
	const ds =
		explicitSelectAllFilteredDataSets(targetSurvey, primarySurvey, stateApp) ||
		EMPTY_OBJECT;
	return ((ds[filterKey] || EMPTY_OBJECT).series || EMPTY_OBJECT)[readingProp];
};

/**
 * SELECT MY READING DATA STRUCTURES
 */

const selectMyAlignedReadingDataStructures = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadings = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const primarySurveyId = control.renderToSurvey;

	return (alignedReadings[primarySurveyId] || {})[survey.id];
};

const selectMyAlignedReadingFilteredDataStructures = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsFiltered = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { renderToSurvey, filterKey } = control;

	return ((alignedReadingsFiltered[renderToSurvey] || {})[survey.id] || {})[
		filterKey
	];
};

export const selectMyReadingFilteredDataStructures = (survey, state) => {
  const stateApp = selectStateApp(state);
	const { readingsFiltered = {} } = stateApp;

	const control = selectAlignedReadingsControl(survey, stateApp) || {};
	const { filterKey } = control;

	return (readingsFiltered[survey.id] || {})[filterKey];
};

export const selectMyReadingDataStructures = (survey, state) => {
  const stateApp = selectStateApp(state);
	try {
		const control = selectAlignedReadingsControl(survey, stateApp) || {};
		const { renderToSurvey, filterKey } = control;

		if (renderToSurvey && filterKey) {
			return selectMyAlignedReadingFilteredDataStructures(survey, stateApp);
		}

		if (renderToSurvey) {
			return selectMyAlignedReadingDataStructures(survey, stateApp);
		}

		if (filterKey) {
			return selectMyReadingFilteredDataStructures(survey, stateApp);
		}

		const {
			readings,
			readingsMap,
			readingsIndexMap,
			readingsWithChartGaps,
			readingsWithChartGapsIndexMap,
			readingsStationIdIntegers
		} = stateApp;

		return {
			readings: readings[survey.id],
			readingsMap: readingsMap[survey.id],
			readingsIndexMap: readingsIndexMap[survey.id] || EMPTY_OBJECT,
			readingsWithChartGaps: readingsWithChartGaps[survey.id],
			readingsWithChartGapsIndexMap:
				readingsWithChartGapsIndexMap[survey.id] || EMPTY_OBJECT,
			series: selectSeries(stateApp, survey),
			seriesIndexMap: selectSeriesIndexMap(stateApp, survey),
			readingsStationIdIntegers: readingsStationIdIntegers[survey.id] || {}
		};
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		return {};
	}
};

// @todo - need to memoize selectAllComputedAlignedDataStructures
export const selectAllComputedAlignedDataStructures = (
	survey,
	state,
	options
) => {
  const stateApp = selectStateApp(state);
	// @note - selectSurveyIdsAlignedToSelf is memoized
	const targetSurveyIds = selectSurveyIdsAlignedToSelf(
		survey,
		stateApp,
		options
	);
	return targetSurveyIds.reduce(
		(acc, targetSurveyId) => ({
			...acc,
			[targetSurveyId]: selectMyReadingDataStructures(
				{ id: targetSurveyId },
				stateApp
			)
		}),
		{}
	);
};

/**
 * HELPERS: Standard Readings
 */
export const selectReadings = (survey, state = {}) => {
  const stateApp = selectStateApp(state);
	const { id } = survey;
	const { readings: appReadings = {} } = stateApp;

	return appReadings[id] || [];
};

export const selectReadingsMap = (survey, state = {}) => {
  const stateApp = selectStateApp(state);
	const { id } = survey;
	const { readingsMap: appReadingsMap = {} } = stateApp;

	return appReadingsMap[id] || {};
};

/**
 * HELPERS: Align to dropdown
 */

const memIsValidPrimarySurvey = _.memoize((survey = []) =>
	_isValidPrimarySurvey(survey)
);

export const selectIsValidPrimarySurvey = survey =>
	memIsValidPrimarySurvey(survey);

export const selectShouldShowAlignedRibbon = (survey, state) => {
  const stateApp = selectStateApp(state);
	const isValidPrimarySurvey = selectIsValidPrimarySurvey(survey);
	const hasVisibleAlignedSurveys = selectHasSurveysAlignedToSelf(survey, stateApp, {
		requireDisplayInRibbon: true
	});

	const isAlignedRibbonVisible = selectIsAlignedRibbonVisible(survey, stateApp);

	return (
		isAlignedRibbonVisible && hasVisibleAlignedSurveys && isValidPrimarySurvey
	);
};

export const selectAlignedReadingsThreshold = (primarySurvey, state) => {
  const stateApp = selectStateApp(state);
	const { alignedReadingsThreshold } = stateApp;

	const thresholdObject = alignedReadingsThreshold[primarySurvey.id] || {};

	return {
		threshold: thresholdObject.threshold || DEFAULT_ALIGNMENT_THRESHOLD_IN_FEET,
		tempThreshold: thresholdObject.tempThreshold
	};
};

const memFilterPossiblePrimarySurveys = _.memoize((surveys = []) =>
	surveys.filter(selectIsValidPrimarySurvey)
);

export const selectPrimarySurveys = (state = {}) => {
  const stateApp = selectStateApp(state);
	const { surveys = EMPTY_ARRAY } = stateApp;
	return memFilterPossiblePrimarySurveys(surveys);
};

const MIN = (a, b) => {
	if (!isDefined(b)) {
		return a;
	}

	if (!isDefined(a)) {
		return b;
	}

	return Math.min(a, b);
};

const MAX = (a, b) => {
	if (!isDefined(b)) {
		return a;
	}

	if (!isDefined(a)) {
		return b;
	}

	return Math.max(a, b);
};

export const selectAlignedBoundaryXYMinMax = (
	state = {},
	alignedToSurvey = {}
) => {
  const stateApp = selectStateApp(state);
	if (!alignedToSurvey.id) {
		return EMPTY_OBJECT;
	}

	const alignedSurveys = selectAlignedSurveysForRibbon(
		alignedToSurvey,
		stateApp
	);

	return alignedSurveys.reduce((acc, s) => {
		const chartId = getChartId(s);
		const { xMin, xMax, yMin, yMax } =
			selectDataXYMinMax(stateApp, chartId) || {};
		acc.xMin = MIN(acc.xMin, xMin);
		acc.xMax = MAX(acc.xMax, xMax);
		acc.yMin = MIN(acc.yMin, yMin);
		acc.yMax = MAX(acc.yMax, yMax);
		return acc;
	}, {});
};

const _exports = {
	selectSurveyIdsAlignedToSelf,
	selectHasSurveysAlignedToSelf,
	selectAlignedSurveys,
	selectAlignedSurveysForRibbon,
	selectAllAlignedDataStructures,
	selectAlignedDataStructures,
	selectAlignedReadings,
	selectAlignedReadingsMap,
	selectMyReadingDataStructures,
	selectReadings,
	selectReadingsMap,
	selectPrimarySurveys,
	selectAlignedBoundaryXYMinMax
};

export const TESTS = {
	..._exports,
	_selectSurveyIdsRenderingToPrimary
};

export default {
	..._exports
};
