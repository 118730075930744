/* eslint-disable no-param-reassign */
import { formatStationId } from './stationId';

// @keep
export const getSeriesKey = (series = {}) => {
	const { dataSource: readings = [] } = series;
	return readings.length
		? `${readings[0].id}-${readings[readings.length - 1].id}`
		: undefined;
};

// @keep
export const printGroupName = remediationGroup => {
	const [s, e] = remediationGroup;
	return `${formatStationId(s.id)} - ${formatStationId(e.id)}`;
};
