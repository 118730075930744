/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const LAMBDA = () => {};

const getTags = (reportAgg) => { 
  return (reportAgg?.tags || []).map(t => t.name).join('~');
}

/** ****************************
 * MAIN
 */

const TH = ({ header = "Tags" }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT, onClick = LAMBDA }) => {
  const value = useMemo(() => getTags(item), [item]);

  return <td onClick={onClick}><HelperRenderStringList strList={value} direction='vertical' /></td>;
};

const ColTags = { TH, TD, CSV_PROP: null };

export default ColTags;
