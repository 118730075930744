/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const LAMBDA = () => {};
const DEFAULT_HEADER = 'Technician';
const PROP_KEY = 'ownerName';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT, onClick = LAMBDA }) => {
	const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

	return <td onClick={onClick}>{value}</td>;
};

const ColCTs = { TH, TD, CSV_PROP: PROP_KEY, DEFAULT_HEADER };

export default ColCTs;
