/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect";

import { DateTimeless } from "aegion_common_utilities/lib/ReadingsUtil";
import { selectSelectedFiltersMap } from "../../ReportsAggsFilters/selectors";
import { selectItems as selectReportsAggs } from "../../ReportsAggs/selectors";

import { FILTER_KEYS } from "../../ReportsAggsFilterSettings/constants";
import { selectDateRangeFrom, selectDateRangeTo, selectDateRangeType, selectIsDateRangeEnabled } from "../../ReportsAggsFilterDateRange/selectors";

const { 
  CUSTOMERS,
  ENTITIES,
  AREAS,
  PIPES,
  REGULATORY_STATUSES,
  JOBS,
  SURVEY_TYPES,
  PMS,
  CTS,
  REPORT_TYPES,
  REPORT_STATUSES,
  PROJECT_TYPES,
  REMEDIATION_TYPES,
  TARGA_GROUP_STATUSES,
} = FILTER_KEYS

/**
 * HELPERS
 */

const createSelectedMapCounts = (selectedMap) => Object.keys(selectedMap).reduce((acc, key) => {
  acc[key] = Object.keys(selectedMap[key] || {}).length;
  return acc;
}, {});

const isDefined = v => v !== undefined && v !== null;

export const isInRange = (reportAgg, dateRangeProps) => { 
  const {
    enabled,
    from,
    to
  } = dateRangeProps;

  if (!enabled || (!from && !to)) { 
    return true;
  }

  if (!isDefined(reportAgg.sortEpoch)) { 
    return false;
  }

  const ts = new Date(new DateTimeless(reportAgg.sortEpoch)).getTime();
  const fromTS = isDefined(from) ? new Date(new DateTimeless(from)).getTime() : undefined;
  const toTS = isDefined(to) ? new Date(new DateTimeless(to)).getTime() : undefined;

  if (isDefined(fromTS) && isDefined(toTS)) {
    return fromTS <= ts && ts <= toTS;
  }

  if (isDefined(fromTS)) {
    return fromTS <= ts;
  }

  if (isDefined(toTS)) {
    return ts <= toTS;
  }

  return false;
}

/**
 * SURVEYS
 */

const hasSurveyType = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[SURVEY_TYPES] ?
    true : 
    !!(selectedMap[SURVEY_TYPES] || {})[reportAgg.surveyTypeId];
}

const hasReportType = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[REPORT_TYPES] ?
    true : 
    !!(selectedMap[REPORT_TYPES] || {})[reportAgg.reportTypeId];
}

const hasReportStatus = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[REPORT_STATUSES] ?
    true : 
    !!(selectedMap[REPORT_STATUSES] || {})[reportAgg.reportStatusId];
}

const hasProjectType = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[PROJECT_TYPES] ?
    true : 
    !!(selectedMap[PROJECT_TYPES] || {})[reportAgg.parentTypeKey];
}

const hasRemediationType = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[REMEDIATION_TYPES] ?
    true :
    (reportAgg.arrReportRemediationTypes || []).some(type => {
      return !!(selectedMap[REMEDIATION_TYPES] || {})[type]
    });
}

const hasTargaGroupStatus = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[TARGA_GROUP_STATUSES] ?
    true :
    (Object.keys(reportAgg.targaGroupStatusCounts || {})).some(status => {
      return !!(selectedMap[TARGA_GROUP_STATUSES] || {})[status]
    });
}

/**
 * JOB FILTERS
 */

const hasEntity = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[ENTITIES] ?
    true : 
    !!(selectedMap[ENTITIES] || {})[reportAgg.entityId];
}

const hasArea = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[AREAS] ?
    true : 
    !!(selectedMap[AREAS] || {})[reportAgg.areaId];
}

const hasPipe = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[PIPES] ?
    true : 
    !!(selectedMap[PIPES] || {})[reportAgg.pipeId];
}

const hasRegulatoryStatus = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[REGULATORY_STATUSES] ?
    true : 
    !!(selectedMap[REGULATORY_STATUSES] || {})[reportAgg.regulatoryStatusId];
}

const hasJob = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[JOBS] ?
    true : 
    !!(selectedMap[JOBS] || {})[reportAgg.jobId];
}

const hasPM = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[PMS] ?
    true : 
    !!(selectedMap[PMS] || {})[reportAgg.projectManagerUserId];
}

const hasCT = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[CTS] ?
    true : 
    !!(selectedMap[CTS] || {})[reportAgg.ownerUserId];
}

/**
 * LINE AGG FILTERS - TOP LEVEL
 */

const hasCustomer = (reportAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[CUSTOMERS] ?
    true : 
    !!(selectedMap[CUSTOMERS] || {})[reportAgg.customerId];
}

const reportAggHas = (reportAgg, selectedMap, selectedMapCounts, dateRangeProps) => { 
  return (
    hasCustomer(reportAgg, selectedMap, selectedMapCounts) &&
    hasEntity(reportAgg, selectedMap, selectedMapCounts) &&
    hasArea(reportAgg, selectedMap, selectedMapCounts) &&
    hasPipe(reportAgg, selectedMap, selectedMapCounts) &&
    hasRegulatoryStatus(reportAgg, selectedMap, selectedMapCounts) &&
    hasJob(reportAgg, selectedMap, selectedMapCounts) &&
    hasPM(reportAgg, selectedMap, selectedMapCounts) &&
    hasCT(reportAgg, selectedMap, selectedMapCounts) &&
    hasSurveyType(reportAgg, selectedMap, selectedMapCounts) &&    
    hasReportType(reportAgg, selectedMap, selectedMapCounts) &&
    hasReportStatus(reportAgg, selectedMap, selectedMapCounts) &&
    hasProjectType(reportAgg, selectedMap, selectedMapCounts) && 
    hasRemediationType(reportAgg, selectedMap, selectedMapCounts) &&
    hasTargaGroupStatus(reportAgg, selectedMap, selectedMapCounts) &&
    isInRange(reportAgg, dateRangeProps)
  );
};

// should only be used within /ReportsAggsFiltersUI/selectore/
const _INTERNAL_UTIL = {
  createSelectedMapCounts,
  reportAggHas,
  hasCustomer,
  hasEntity,
  hasArea,
  hasJob,
  hasPipe,
  hasRegulatoryStatus,
  hasPM,
  hasCT,
  hasSurveyType,
  hasReportType,
  hasReportStatus,
  hasProjectType,
  hasRemediationType,
  hasTargaGroupStatus
}

const selectFilteredReportsAggs = createSelector(
  selectReportsAggs,
  selectSelectedFiltersMap,
  selectIsDateRangeEnabled,
  selectDateRangeType,
  selectDateRangeFrom,
  selectDateRangeTo,  
  (
    reportsAggs,
    selectedMap,
    dateRangeEnabled,
    dateRangeType,
    dateRangeFrom,
    dateRangeTo
  ) => {
    const selectedMapCounts = createSelectedMapCounts(selectedMap);
    const dateRangeProps = {
      type: dateRangeType,
      enabled: dateRangeEnabled,
      from: dateRangeFrom,
      to: dateRangeTo
    }
    return reportsAggs.filter(ra => reportAggHas(ra, selectedMap, selectedMapCounts, dateRangeProps));
  });

export { 
  _INTERNAL_UTIL,
  selectFilteredReportsAggs
}

export default {
  _INTERNAL_UTIL,
  selectFilteredReportsAggs
}
