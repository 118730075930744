/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { createActions } from 'redux-actions';
import {
	selectChartBelowCriterionDataSets,
	selectLegacyCriterionInfo
} from '../../../AppAnalysisPage/slices/Charts/slices/ChartsBelowCriterions/selectors';
import {
	selectCustomGroupRanges,
	selectCustomExceptions,
  selectRemediationActions
	// selectRemediationGroupRanges
} from './selectors';
import {
	selectChartPrimarySurvey,
	selectUseInterpolatedDepol,
	selectUseNormalizedDepol
} from '../../../AppAnalysisPage/slices/Charts/slices/ChartsSettings/selectors';
import {
	selectSurveysByChartId,
	selectDerivedSurvey
} from '../../../AppAnalysisPage/selectors/surveys';
import { selectMyReadingDataStructures } from '../../../AppAnalysisPage/selectors/alignedReadings';

import { selectDepolReadings } from '../../../AppAnalysisPage/selectors/depolReadings';
import {
	addGroupToRangeGroups,
	removeGroupFromRangeGroups
} from '../../../../utils/readingRangeGroups';
import {
	deriveRecomputedDataSourcesFromCustomExceptionGroups,
	deriveNewDataSourcesFromNewExceptionsGroup,
	deriveNewDataSourcesFromDestroyedExceptionsGroup
} from '../../../../components/ChartGroupComponent/util/customGroupEditing/deriveCustomDataSources';

// @todo this belongs in Charts slices and needs to be simplified (remove criterion info as computed by chartgroupcomponent)
import { computeRemediationGroups } from '../../../../components/ChartGroupComponent/util/remediations';
import { selectReadings } from '../../../AppAnalysisPage/selectors/readings';
import { thunkSetSelectedRemediationGroupByReading } from '../../../AppAnalysisPage/slices/Charts/slices/ChartsSettings/actions';
import { filterReadingsWithinBounds } from '../../../../utils/reading';
import { getUserDisplayName } from '../../../../../util/user';
import { selectAutoSaveProjectEnabled } from '../../../CustomerAppSettings/slices/ScanlineSettings/selectors/selectors.core';
import { parseQueryString } from '../../../../utils/location';
import { thunkPromiseSaveAppSettings } from '../AppStateV2/actions';

const {
	setChartState,
	destroyChartState,
	setCustomGroupRanges,
	clearCustomGroupRanges,
	setRemediationAndCustomExceptionDataStructures,
	clearRemediationAndCustomExceptionDataStructures,
	setRemediationAction,
	setRemediationActions,
  setRemediationsActions,
  removeRemediationAction
} = createActions(
	{
		// BASE
		SET_CHART_STATE: (chartId, remediationState) => ({
			chartId,
			remediationState
		}),
		DESTROY_CHART_STATE: chartId => ({ chartId }),
		// CUSTOM GROUPS
		SET_CUSTOM_GROUP_RANGES: (chartId, customGroupRanges) => ({
			chartId,
			customGroupRanges
		}),
		CLEAR_CUSTOM_GROUP_RANGES: chartId => ({ chartId }),
		// REMEDIATION GROUPS
		SET_REMEDIATION_AND_CUSTOM_EXCEPTION_DATA_STRUCTURES: (
			chartId,
			{
				customExceptions,
				customExceptionsMap,
				customExceptionsWithGaps,
				remediationGroupRanges,
				remediationReadings
			}
		) => ({
			chartId,
			customExceptions,
			customExceptionsMap,
			customExceptionsWithGaps,
			remediationGroupRanges,
			remediationReadings
		}),
		CLEAR_REMEDIATION_AND_CUSTOM_EXCEPTION_DATA_STRUCTURES: chartId => ({
			chartId
		}),
		SET_REMEDIATION_ACTION: (
			chartId,
			{ groupKey, actionKey, remediationAction }
		) => ({ chartId, groupKey, actionKey, remediationAction }),
		SET_REMEDIATION_ACTIONS: (chartId, groupKey, remediationActions) => ({
			chartId,
			groupKey,
			remediationActions
		}),
		SET_REMEDIATIONS_ACTIONS: (chartId, remediationsActions) => ({
			chartId,
			remediationsActions
		}),
		REMOVE_REMEDIATION_ACTION: (
			chartId,
			{ groupKey, actionKey, remediationAction }
		) => ({ chartId, groupKey, actionKey, remediationAction }),
	},
	{
		prefix: 'ChartsRemediations'
	}
);

/** ******************************************
 * CUSTOM GROUP RANGES
 ******************************************* */

// SET - custom group ranges
const safeSetCustomGroupRanges = (chartId, newCustomGroupRanges) => (
	dispatch,
	getState
) => {
	const customGroupRanges = selectCustomGroupRanges(getState().app, chartId);
	if (!_.isEqual(customGroupRanges, newCustomGroupRanges)) {
		dispatch(setCustomGroupRanges(chartId, newCustomGroupRanges));
	}
};

const thunkSetCustomGroupRanges = (chartId, customGroupRanges) => (
	dispatch,
	getState
) => {
	const chartSurveys = selectSurveysByChartId(getState().app, chartId);
	const typesAllowingCustomGroups = ['ON', 'ON-OFF', 'ON_OFF'];
	const canSetCustomGroups = !!chartSurveys.filter(
		s => typesAllowingCustomGroups.indexOf(s.survey_subtype) > -1
	)[0];
	if (canSetCustomGroups) {
		dispatch(safeSetCustomGroupRanges(chartId, customGroupRanges));
		dispatch(
			thunkSetRemediationAndCustomExceptionDataStructures(
				chartId,
				customGroupRanges
			)
		);
	}
};

// selectAutoSaveProjectEnabled
const _thunkAutoSaveAppState = (chartId) => (dispatch, getState) => { 
  const shouldAutoSave = selectAutoSaveProjectEnabled(getState());
  if (shouldAutoSave) {
    try { 
      const {
        line: lineId,
        p_id: projectId,
      } = parseQueryString(window?.location?.search);
      if (lineId || projectId) {
        dispatch(thunkPromiseSaveAppSettings({ chartId, lineId, projectId, blnSendRequestReloadState: true }));
      }
    } catch (err) { 
      // ignore
    }
  }
}

// ADD - custom group ranges
const thunkAddCustomGroupRange = (chartId, customGroupRange, tryAutoSave = false) => (
	dispatch,
	getState
) => {
	const customGroupRanges = selectCustomGroupRanges(getState().app, chartId);
	const newCustomGroupRanges = addGroupToRangeGroups(
		customGroupRange,
		customGroupRanges
	);
	dispatch(safeSetCustomGroupRanges(chartId, newCustomGroupRanges));
	dispatch(
		thunkAddRemediationAndCustomExceptionDataStructures(
			chartId,
			customGroupRange
		)
  );
  if (tryAutoSave) { 
    dispatch(_thunkAutoSaveAppState(chartId));
  }
};

// REMOVE - custom group ranges
const thunkRemoveCustomGroupRange = (chartId, customGroupRange, tryAutoSave = false) => (
	dispatch,
	getState
) => {
	const customGroupRanges = selectCustomGroupRanges(getState().app, chartId);
	const newCustomGroupRanges = removeGroupFromRangeGroups(
		customGroupRange,
		customGroupRanges
	);
	dispatch(safeSetCustomGroupRanges(chartId, newCustomGroupRanges));
	dispatch(
		thunkRemoveRemediationAndCustomExceptionDataStructures(
			chartId,
			customGroupRange
		)
	);
  if (tryAutoSave) { 
    dispatch(_thunkAutoSaveAppState(chartId));
  }
};

const thunkChangeCustomGroupRange = (
	chartId,
	originalCustomGroupRange,
  newCustomGroupRange,
  tryAutoSave = false
) => (dispatch, getState) => {
	const stateApp = getState().app;
	const survey = selectChartPrimarySurvey(stateApp, { chartId });
	const readings = selectReadings(stateApp, survey);

	const originalDataValues = filterReadingsWithinBounds(
		readings,
		originalCustomGroupRange
	);
	const newDataValues = filterReadingsWithinBounds(
		readings,
		newCustomGroupRange,
		{ useFuzzy: true }
	);
	const firstNewReading = newDataValues[0];

	dispatch(thunkRemoveCustomGroupRange(chartId, originalDataValues));
	dispatch(thunkAddCustomGroupRange(chartId, newDataValues));
	dispatch(thunkSetSelectedRemediationGroupByReading(chartId, firstNewReading));
  if (tryAutoSave) { 
    dispatch(_thunkAutoSaveAppState(chartId));
  }
};

// CLEAR - custom group ranges
const thunkClearCustomGroupRanges = (chartId, tryAutoSave = false) => dispatch => {
	dispatch(clearCustomGroupRanges(chartId));
	dispatch(thunkClearRemediationAndCustomExceptionDataStructures(chartId));
  if (tryAutoSave) { 
    dispatch(_thunkAutoSaveAppState(chartId));
  }
};

/** ******************************************
 * REMEDIATIONS & CUSTOM EXCEPTIONS
 ******************************************* */

// SET - remediations & custom exceptions
const thunkSetRemediationAndCustomExceptionDataStructures = (
	chartId,
	customGroups
) => (dispatch, getState) => {
	const stateApp = getState().app;
	const identifiers = { chartId };
	const { bcReadingsMap } = selectChartBelowCriterionDataSets(
		stateApp,
		chartId
	);
	const survey = selectChartPrimarySurvey(stateApp, identifiers);
	const {
		readings,
		readingsIndexMap,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap
	} = selectMyReadingDataStructures(survey, stateApp);
	const criterionInfo = selectLegacyCriterionInfo(stateApp, chartId);
	const propKey = criterionInfo ? criterionInfo.key : ' ';
	const depolSurvey = selectDerivedSurvey(stateApp, survey, 'DEPOL');
	const useInterpolatedDepol = selectUseInterpolatedDepol(
		stateApp,
		identifiers
	);
	const useNormalizedDepol = selectUseNormalizedDepol(stateApp, identifiers);
	const depolReadings = selectDepolReadings(
		stateApp,
		(depolSurvey || {}).id,
		useInterpolatedDepol,
		useNormalizedDepol
	);
	const {
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	} = deriveRecomputedDataSourcesFromCustomExceptionGroups(
		bcReadingsMap,
		propKey,
		customGroups,
		propKey,
		bcReadingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readings,
		readingsWithChartGaps
	);

	const newRemediationGroupRanges = computeRemediationGroups(
		survey,
		readings,
		depolReadings,
		bcReadingsMap, // @todo - confirm this is correct, may require bc readings without gaps injected
		criterionInfo,
		bcReadingsMap,
		newComputedBcMap,
		newCustomExceptionsMap
	);

	dispatch(
		setRemediationAndCustomExceptionDataStructures(chartId, {
			computedBcMap: newComputedBcMap,
			customExceptions: newCustomExceptions,
			customExceptionsMap: newCustomExceptionsMap,
			customExceptionsWithGaps: newCustomExceptionsWithGaps,
			remediationGroupRanges: newRemediationGroupRanges,
			remediationReadings: newRemediationReadings
		})
	);
};

// ADD - remediations & custom exceptions
const thunkAddRemediationAndCustomExceptionDataStructures = (
	chartId,
	selectedDataValues
) => (dispatch, getState) => {
	const stateApp = getState().app;
	const identifiers = { chartId };
	const { bcReadings, bcReadingsMap } = selectChartBelowCriterionDataSets(
		stateApp,
		chartId
	);
	const customExceptions = selectCustomExceptions(stateApp, chartId);
	const survey = selectChartPrimarySurvey(stateApp, identifiers);
	const {
		readings,
		readingsIndexMap,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap
	} = selectMyReadingDataStructures(survey, stateApp);
	const criterionInfo = selectLegacyCriterionInfo(stateApp, chartId) || {};
	const propKey = criterionInfo ? criterionInfo.key : ' ';
	const depolSurvey = selectDerivedSurvey(stateApp, survey, 'DEPOL');
	const useInterpolatedDepol = selectUseInterpolatedDepol(
		stateApp,
		identifiers
	);
	const useNormalizedDepol = selectUseNormalizedDepol(stateApp, identifiers);
	const depolReadings = selectDepolReadings(
		stateApp,
		(depolSurvey || {}).id,
		useInterpolatedDepol,
		useNormalizedDepol
	);

	const {
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	} = deriveNewDataSourcesFromNewExceptionsGroup(
		selectedDataValues,
		bcReadings,
		propKey,
		customExceptions,
		propKey,
		bcReadingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readings,
		readingsWithChartGaps
	);

	const newRemediationGroupRanges = computeRemediationGroups(
		survey,
		readings,
		depolReadings,
		bcReadings, // @ToDo This variable isn't used in the child methods
		criterionInfo,
		bcReadingsMap,
		newComputedBcMap,
		newCustomExceptionsMap
	);

	dispatch(
		setRemediationAndCustomExceptionDataStructures(chartId, {
			computedBcMap: newComputedBcMap,
			customExceptions: newCustomExceptions,
			customExceptionsMap: newCustomExceptionsMap,
			customExceptionsWithGaps: newCustomExceptionsWithGaps,
			remediationGroupRanges: newRemediationGroupRanges,
			remediationReadings: newRemediationReadings
		})
	);
};

// REMOVE - remediations & custom exceptions
const thunkRemoveRemediationAndCustomExceptionDataStructures = (
	chartId,
	selectedDataValues
) => (dispatch, getState) => {
	const stateApp = getState().app;
	const identifiers = { chartId };
	const { bcReadingsMap, bcReadings } = selectChartBelowCriterionDataSets(
		stateApp,
		chartId
	);
	const customExceptions = selectCustomExceptions(stateApp, chartId);
	const survey = selectChartPrimarySurvey(stateApp, identifiers);
	const {
		readings,
		readingsIndexMap,
		readingsWithChartGaps,
		readingsWithChartGapsIndexMap
	} = selectMyReadingDataStructures(survey, stateApp);
	const criterionInfo = selectLegacyCriterionInfo(stateApp, chartId);
	const propKey = criterionInfo ? criterionInfo.key : ' ';
	const depolSurvey = selectDerivedSurvey(stateApp, survey, 'DEPOL');
	const useInterpolatedDepol = selectUseInterpolatedDepol(
		stateApp,
		identifiers
	);
	const useNormalizedDepol = selectUseNormalizedDepol(stateApp, identifiers);
	const depolReadings = selectDepolReadings(
		stateApp,
		(depolSurvey || {}).id,
		useInterpolatedDepol,
		useNormalizedDepol
	);

	const {
		newComputedBcMap,
		newCustomExceptions,
		newCustomExceptionsMap,
		newCustomExceptionsWithGaps,
		newRemediationReadings
	} = deriveNewDataSourcesFromDestroyedExceptionsGroup(
		selectedDataValues,
		bcReadings,
		propKey,
		customExceptions,
		propKey,
		bcReadingsMap,
		readingsIndexMap,
		readingsWithChartGapsIndexMap,
		readings,
		readingsWithChartGaps
	);

	const newRemediationGroupRanges = computeRemediationGroups(
		survey,
		readings,
		depolReadings,
		bcReadings, // @ToDo This variable isn't used in the child methods
		criterionInfo,
		bcReadingsMap,
		newComputedBcMap,
		newCustomExceptionsMap
	);

	dispatch(
		setRemediationAndCustomExceptionDataStructures(chartId, {
			computedBcMap: newComputedBcMap,
			customExceptions: newCustomExceptions,
			customExceptionsMap: newCustomExceptionsMap,
			customExceptionsWithGaps: newCustomExceptionsWithGaps,
			remediationGroupRanges: newRemediationGroupRanges,
			remediationReadings: newRemediationReadings
		})
	);
};


// ADD REM
const thunkAddRemediationAction = (chartId, groupKey, action) => (dispatch, getState) => {
  if (!action?.key) {
    throw new Error('Action is missing property key');
  }

  const currentMapForGroup = (selectRemediationActions(getState(), chartId) || {})[groupKey] || {};

  if (!currentMapForGroup[action.key]) { 
    const clone = { ...currentMapForGroup };
    clone[action.key] = {
      ...action,
      createdBy: getUserDisplayName({ composite: true }),
      dateCreated: new Date().toISOString()
    };
    dispatch(setRemediationActions(chartId, groupKey, clone))
  }
}

const thunkRemoveRemediationAction = (chartId, groupKey, action) => (dispatch, getState) => {
  if (!action?.key) {
    throw new Error('Action is missing property key');
  }

  const currentMapForGroup = (selectRemediationActions(getState(), chartId) || {})[groupKey];

  if (currentMapForGroup[action.key]) { 
    const clone = { ...currentMapForGroup };
    delete clone[action.key];
    dispatch(setRemediationActions(chartId, groupKey, clone))
  }
}

// REMOVE REM


// CLEAR - remediations & custom exceptions
const thunkClearRemediationAndCustomExceptionDataStructures = chartId => dispatch => {
	dispatch(clearRemediationAndCustomExceptionDataStructures(chartId));
	dispatch(thunkAddRemediationAndCustomExceptionDataStructures(chartId, []));
};

export {
	setChartState,
	destroyChartState,
	setCustomGroupRanges,
	clearCustomGroupRanges,
	setRemediationAndCustomExceptionDataStructures,
	clearRemediationAndCustomExceptionDataStructures,
	thunkSetCustomGroupRanges,
	thunkAddCustomGroupRange,
	thunkRemoveCustomGroupRange,
	thunkChangeCustomGroupRange,
	thunkClearCustomGroupRanges,
	safeSetCustomGroupRanges,
	thunkSetRemediationAndCustomExceptionDataStructures,
	thunkAddRemediationAndCustomExceptionDataStructures,
	thunkRemoveRemediationAndCustomExceptionDataStructures,
	thunkClearRemediationAndCustomExceptionDataStructures,
	setRemediationAction,
	setRemediationActions,
  setRemediationsActions,
  removeRemediationAction,
  thunkAddRemediationAction,
  thunkRemoveRemediationAction
};

export default {
	setChartState,
	destroyChartState,
	setCustomGroupRanges,
	clearCustomGroupRanges,
	setRemediationAndCustomExceptionDataStructures,
	clearRemediationAndCustomExceptionDataStructures,
	thunkSetCustomGroupRanges,
	thunkAddCustomGroupRange,
	thunkRemoveCustomGroupRange,
	thunkChangeCustomGroupRange,
	thunkClearCustomGroupRanges,
	safeSetCustomGroupRanges,
	thunkSetRemediationAndCustomExceptionDataStructures,
	thunkAddRemediationAndCustomExceptionDataStructures,
	thunkRemoveRemediationAndCustomExceptionDataStructures,
	thunkClearRemediationAndCustomExceptionDataStructures,
	setRemediationAction,
	setRemediationActions,
  setRemediationsActions,  
  thunkAddRemediationAction,
  thunkRemoveRemediationAction
};
