/* eslint-disable import/prefer-default-export */
import { _createActions } from "./create.actions";
import { _createSelectors } from "./create.selectors";
import { _createReducerParts } from "./create.reducer.parts";
import { createSliceSelectors, protectSliceConfig } from "../../util/slice";
import { ROOT_SLICE } from "./constants";
import { initialState } from "./initialState";


export const _createSlice = (sliceConfig = {}) => {
  // eslint-disable-next-line no-param-reassign
  sliceConfig = protectSliceConfig(sliceConfig, ROOT_SLICE);
  const { selectThisSlice, selectParentSlice } = createSliceSelectors(sliceConfig);

  const SELECTORS = _createSelectors({ sliceConfig, selectThisSlice, selectParentSlice });
  const ACTIONS = _createActions({ sliceConfig, SELECTORS });
  const REDUCERPARTS = _createReducerParts({ sliceConfig, ACTIONS, SELECTORS });

  return {
    ROOT_SLICE: sliceConfig.prop,
    SELECTORS,
    ACTIONS,
    REDUCERPARTS,
    initialState,
    prefix: sliceConfig.prefix,
  }
}

export default _createSlice;