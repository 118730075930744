import { getCustomer } from "../../../../../util/user";

const sessionCustomer = getCustomer();

/**
 * ROOT_SLICE
 */
const ROOT_SLICE = 'ReportsAggsFilterSettings';

/**
 * FILTER_KEYS
 */
const _FILTER_KEYS = {
  CUSTOMERS: 'customers',
  ENTITIES: 'entities',
  AREAS: 'areas',
  PIPES: 'pipes',
  REGULATORY_STATUSES: 'regulatoryStatuses',
  JOBS: 'jobs',
  SURVEY_TYPES: 'surveyTypes',
  PMS: 'pms',
  CTS: 'cts',
  REPORT_TYPES: 'reportTypes',
  REPORT_STATUSES: 'reportStatuses',
  PROJECT_TYPES: 'projectTypes',
  REMEDIATION_TYPES: 'remediationTypes',
  TARGA_GROUP_STATUSES: 'targaGroupStatuses'
};

const FILTER_ITEM_ID_KEY = {
  [_FILTER_KEYS.CUSTOMERS]: 'id',
  [_FILTER_KEYS.ENTITIES]: 'id',
  [_FILTER_KEYS.AREAS]: 'id',
  [_FILTER_KEYS.PIPES]: 'id',
  [_FILTER_KEYS.REGULATORY_STATUSES]: 'id',
  [_FILTER_KEYS.JOBS]: 'jobId',
  [_FILTER_KEYS.SURVEY_TYPES]: 'id',
  [_FILTER_KEYS.PMS]: 'pmId',
  [_FILTER_KEYS.CTS]: 'ctId',
  [_FILTER_KEYS.REPORT_TYPES]: 'id',
  [_FILTER_KEYS.REPORT_STATUSES]: 'id',
  [_FILTER_KEYS.PROJECT_TYPES]: 'id',
  [_FILTER_KEYS.REMEDIATION_TYPES]: 'id',
  [_FILTER_KEYS.TARGA_GROUP_STATUSES]: 'id',
}


const AEGION_ONLY_FILTER_KEYS = {
  [_FILTER_KEYS.CUSTOMERS]: _FILTER_KEYS.CUSTOMERS
};

const REQUIRED_FILTER_KEYS = {};

const DEFAULT_FILTER_KEYS = {
  [_FILTER_KEYS.CUSTOMERS]: _FILTER_KEYS.CUSTOMERS,
  [_FILTER_KEYS.ENTITIES]: _FILTER_KEYS.ENTITIES,
  [_FILTER_KEYS.JOBS]: _FILTER_KEYS.JOBS,
  [_FILTER_KEYS.SURVEY_TYPES]: _FILTER_KEYS.SURVEY_TYPES,
  [_FILTER_KEYS.PMS]: _FILTER_KEYS.PMS,
  [_FILTER_KEYS.CTS]: _FILTER_KEYS.CTS,
  [_FILTER_KEYS.PROJECT_TYPES]: _FILTER_KEYS.PROJECT_TYPES,
  [_FILTER_KEYS.REPORT_TYPES]: _FILTER_KEYS.REPORT_TYPES,
  [_FILTER_KEYS.REPORT_STATUSES]: _FILTER_KEYS.REPORT_STATUSES,
  [_FILTER_KEYS.REMEDIATION_TYPES]: _FILTER_KEYS.REMEDIATION_TYPES,
}

const FILTER_KEYS = { ..._FILTER_KEYS };

if (sessionCustomer !== 'Aegion') { 
  // remove AEGION_ONLY_FILTER_KEYS
  Object.keys(AEGION_ONLY_FILTER_KEYS).forEach(key => {
    delete DEFAULT_FILTER_KEYS[key];
  });
  delete FILTER_KEYS.CUSTOMERS; // hacky, need to prevent all of the complexity caused by having INVERTED_MAPS.... was originally done so snake case could be used to gen labels.
}

// Add Missing REQUIRED_FILTER_KEYS
Object.keys(REQUIRED_FILTER_KEYS).forEach(key => {
  if (!DEFAULT_FILTER_KEYS[key]) {
    DEFAULT_FILTER_KEYS[key] = key;
  }
});

// TARGA
if (sessionCustomer !== 'Targa' && sessionCustomer !== 'Aegion') { 
  delete DEFAULT_FILTER_KEYS[FILTER_KEYS.TARGA_GROUP_STATUSES];
  delete FILTER_KEYS.TARGA_GROUP_STATUSES; // hacky, need to prevent all of the complexity caused by having INVERTED_MAPS.... was originally done so snake case could be used to gen labels.
}

if (sessionCustomer === 'Targa') { 
  DEFAULT_FILTER_KEYS[_FILTER_KEYS.AREAS] = _FILTER_KEYS.AREAS;
  DEFAULT_FILTER_KEYS[_FILTER_KEYS.PIPES] = _FILTER_KEYS.PIPES;
  DEFAULT_FILTER_KEYS[_FILTER_KEYS.REGULATORY_STATUSES] = _FILTER_KEYS.REGULATORY_STATUSES;

  delete FILTER_KEYS.PROJECT_TYPES;
  delete DEFAULT_FILTER_KEYS[_FILTER_KEYS.PROJECT_TYPES];
  delete FILTER_KEYS.REPORT_TYPES;
  delete DEFAULT_FILTER_KEYS[_FILTER_KEYS.REPORT_TYPES];
  delete FILTER_KEYS.REPORT_STATUSES;
  delete DEFAULT_FILTER_KEYS[_FILTER_KEYS.REPORT_STATUSES];
  delete FILTER_KEYS.REMEDIATION_TYPES;
  delete DEFAULT_FILTER_KEYS[_FILTER_KEYS.REMEDIATION_TYPES];
}

const DIRTY_FILTER_KEYS = _FILTER_KEYS;

// this is needed because FILTER_KEYS.PROP will often not map to "PROP", but rather to "props" or something else which is not an exact match to "PROP".
const INVERTED_FILTER_KEYS_MAP = Object.keys(FILTER_KEYS).reduce((acc, key) => {
  acc[FILTER_KEYS[key]] = key;
  return acc;
}, {});
const INVERTED_DIRTY_FILTER_KEYS_MAP = Object.keys(FILTER_KEYS).reduce((acc, key) => {
  acc[DIRTY_FILTER_KEYS[key]] = key;
  return acc;
}, {});

/**
 * FILTER_KEYS_DEFAULT_SORT
 */

const DEFAULT_FILTER_KEYS_SORT_LIST = [
  _FILTER_KEYS.CUSTOMERS,
  _FILTER_KEYS.ENTITIES,
  _FILTER_KEYS.AREAS,
  _FILTER_KEYS.PIPES,
  _FILTER_KEYS.REGULATORY_STATUSES,
  _FILTER_KEYS.PROJECT_TYPES,
  _FILTER_KEYS.REPORT_TYPES,
  _FILTER_KEYS.TARGA_GROUP_STATUSES,
  _FILTER_KEYS.REPORT_STATUSES,
  _FILTER_KEYS.REMEDIATION_TYPES,
  _FILTER_KEYS.JOBS,
  _FILTER_KEYS.SURVEY_TYPES,
  _FILTER_KEYS.PMS,
  _FILTER_KEYS.CTS,
].filter(key => (!!INVERTED_FILTER_KEYS_MAP[key]));


export { 
  ROOT_SLICE,

  FILTER_KEYS,
  DIRTY_FILTER_KEYS,
  FILTER_ITEM_ID_KEY,
  INVERTED_FILTER_KEYS_MAP,
  INVERTED_DIRTY_FILTER_KEYS_MAP,
  AEGION_ONLY_FILTER_KEYS,
  REQUIRED_FILTER_KEYS,
  DEFAULT_FILTER_KEYS,

  DEFAULT_FILTER_KEYS_SORT_LIST
}


export default { 
  ROOT_SLICE,

  FILTER_KEYS,
  DIRTY_FILTER_KEYS,
  INVERTED_FILTER_KEYS_MAP,
  AEGION_ONLY_FILTER_KEYS,
  REQUIRED_FILTER_KEYS,
  DEFAULT_FILTER_KEYS,

  DEFAULT_FILTER_KEYS_SORT_LIST
}
