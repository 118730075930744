const TAB = 'PopoutActionPlan';

const _getMethod = (fname) => {
  return ((window?.TabSync || {})[TAB] || {})[fname];
}

const sendRequestReloadState = () => {
  const _sendRequestReloadState = _getMethod('sendRequestReloadState');
  if (_sendRequestReloadState) { 
    setTimeout(() => { 
      _sendRequestReloadState();
    }, 1)
  }
}

const GlobalTabSync = {
  sendRequestReloadState
}

export default GlobalTabSync;

export { 
  GlobalTabSync
}
