import React, { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransLabel from '../../../common/TransLabel';

import {
	clearFromQueryString,
	createNewQueryString
} from '../../../../utils/location';
import { setJobsModalVisibility as acSetJobsModalVisibility } from '../../../../actions/mapComponent';

import { AimLink } from '../../../../../commons/components/AimButtons';

import { ROOT_URL } from '../../../../constants';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Line';
const PROP_KEY = 'lineName';

const generateDetailsLink = (lineagg = {}) => {
	const { lineName, lineExternalId } = lineagg;
	const basePath = 'map';

	if (lineExternalId) {
		let queryString = document.location.search;
		queryString = clearFromQueryString('viewAs', queryString);
		queryString = clearFromQueryString('mode', queryString);
		queryString = clearFromQueryString('p_id', queryString);
		queryString = clearFromQueryString('project', queryString);
		queryString = clearFromQueryString('status', queryString);
		queryString = createNewQueryString(['line', lineExternalId], queryString);
		queryString = createNewQueryString(['linename', lineName], queryString);
		return `${ROOT_URL}${basePath}${queryString}`;
	}

	return '#';
};

/** ****************************
 * REDUX
 */

const mapStateToProps = () => ({});

const actionCreators = { setJobsModalVisibility: acSetJobsModalVisibility };
const mapDispatchToProps = dispatch =>
	bindActionCreators(actionCreators, dispatch);

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <TransLabel text={header} abbrv/>;

const TD = ({ item = EMPTY_OBJECT, setJobsModalVisibility } = {}) => {
	const { value, hrefLineDetails } = useMemo(
		() => ({
			value: (item || {})[PROP_KEY] || '',
			hrefLineDetails: generateDetailsLink(item)
		}),
		[item]
	);

	const goToLineDetails = useCallback(() => {
		// const { line, history } = this.props;
		const { lineId } = item;
		if (lineId) {
			const path = hrefLineDetails;
			// @todo - stabilize state changes so we can use react router to switch lines
			// history.push(path);

			// currently we have many intermittent issues when switching from one line to another without reloading page
			// temporarily force page refresh until we can resolve.
			window.location = path;
		}
	}, [hrefLineDetails]);

	const onClick = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			if (setJobsModalVisibility) {
				setJobsModalVisibility(false);
			}
			goToLineDetails();
		},
		[goToLineDetails, setJobsModalVisibility]
	);

	return (
		<td className='w-column-linename'>
			<AimLink
				blType="LINK"
				bsStyle="default"
				href={hrefLineDetails}
				onClick={onClick}
			>
				{value}
			</AimLink>
		</td>
	);
};

const ColLine = { TH, TD, CSV_PROP: PROP_KEY, DEFAULT_HEADER };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ColLine));
