/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE } from '../constants';

const consoleLogDebugger = (txt, debug) => { 
  if (debug) {
    console.log(ROOT_SLICE);
    // debugger;
  }
}

export const selectRootSlicePF = (state, debug = false) => { 
  if (state[ROOT_SLICE]) { 
    consoleLogDebugger('found', debug);
    return state[ROOT_SLICE];
  }

  // else assume state is already root.
  consoleLogDebugger('not found', debug);
  return state;
};
