/* eslint-disable import/prefer-default-export */

import { createSortText } from "aegion_common_utilities/lib/Sort";

export const sortUsers = createSortText('ASC', { getValue: (u) => u?.displayText });

export const formatUser = (user = {}) => { 
  const compositText = (user.fullName && user.userid) ? `${user.fullName} (${user.userid})` : undefined;
  const displayText = compositText || user.fullName || user.userid;
  return {
    ...user,
    displayText
  }
}