/* eslint-disable no-param-reassign */
import { createSortNumeric } from 'aegion_common_utilities/lib/Sort';
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { DateTimeless } from 'aegion_common_utilities/lib/DateTimeless';
import { padNumStart } from 'aegion_common_utilities/lib/format/numeric';
import { selectCustomerRemediationActionTypes } from '../../../AppAnalysisPage/selectors/customer';

// for ref only
// eslint-disable-next-line no-unused-vars
const EXAMPLE_SERVER_REPORT_AGG_ITEM = {
  id: 26,
  parentTypeKey: "LINE",
  parentId: 2547,
  parentName: "(PL-NDL-ACK) Red Hills NGL 8\"",
  lineGuid: "58a89da4-29c6-4a6c-837c-3265f903e667",
  lineName: "(PL-NDL-ACK) Red Hills NGL 8\"",
  projectId: null,
  projectName: null,
  customerId: 31,
  customerName: "Targa",
  entityId: 120,
  entityName: "DOU - Delaware Operating Unit",
  areaId: 11,
  areaName: "NDL-Northern Delaware",
  pipeId: null,
  pipeName: null,
  regulatoryStatusId: 3,
  regulatoryStatusName: "Gas - Regulated",
  jobId: 443,
  jobNumber: 340411036,
  surveyId: 3090,
  surveyGuid: "91c7d711-d55d-4397-84c4-010e3bdfa99c",
  surveyTypeId: 4,
  surveyType: "ON_OFF",
  surveyStartDate: "2023-10-30T00:00:00.000Z",
  surveyEndDate: "2023-10-31T00:00:00.000Z",
  scanlineReportId: 77,
  jobLineId: 1866,
  externalSurveyLineId: null,
  projectSurveyId: null,
  createdByUserId: 348,
  createdDate: "2024-12-20T12:58:43.221Z",
  originalCreatedDate: "2024-12-20T12:51:36.890Z",
  reportTypeId: 1,
  reportTypeKey: "ACTION_PLAN",
  reportTypeName: "Action Plan Report",
  reportStatusId: 1,
  reportStatusKey: "IN_PROGRESS",
  reportStatusName: "In Progress",
  ownerUserId: 348,
  ownerUserid: "wb_targa_supr",
  ownerFullName: null,
  projectManagerUserId: 354,
  projectManagerUserid: null,
  projectManagerFullName: "Will Barnes",
  tags: [
    {
      "name": "bns demo"
    },
    {
      "name": "wb ref x"
    }
  ],
  chartId: "CIS-ON_OFF-91c7d711-d55d-4397-84c4-010e3bdfa99c",
  remediationGroupKeys: [
    "20-53550",
    "56907.5-58195",
    "60492.5-60515",
    "62965-64427.5"
  ],
  remediationActions: [
    {
      "name": "Groundbeds",
      "type": "New Groundbeds & Replacements",
      "group": "20-53550",
      "details": "TWO - GB",
      "type_key": "GROU"
    },
    {
      "name": "Rectifier Adjustments",
      "type": "Rectifier Adjustments",
      "group": "20-53550",
      "details": "TWO - rec adj",
      "type_key": "READ"
    },
    {
      "name": "Recoats",
      "type": "Recoats",
      "group": "20-53550",
      "details": "TWO - Recoat",
      "type_key": "RECO"
    },
    {
      "name": "Broken test leads",
      "type": "Repairs",
      "group": "60492.5-60515",
      "details": "TWO - Broken Test lead",
      "type_key": "BRTL"
    },
    {
      "name": "Malfunctioning rectifiers",
      "type": "Repairs",
      "group": "62965-64427.5",
      "details": "TWO - Mal Rec",
      "type_key": "MARE"
    }
  ],
  txtReportDate: "2025-01-04",
  txtComplianceDate: "2025-01-11"
};


/** ************************************
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const formatDate = (date) => { 
  if (!isDate(date)) {
    return undefined;
  }

  const _date = new DateTimeless(date);
	const day = padNumStart(_date.getDate(), 2);
	const month = padNumStart(_date.getMonth() + 1, 2);
  const year = padNumStart(_date.getYear() + 1900, 4);
  return `${month}/${day}/${year}`;

}

export const createReportKey = (prefix, reportagg) => {
	return `${prefix}-${reportagg.customerId}-${reportagg.parentTypeKey}-${reportagg.parentName}-${reportagg.ownerUserId}`;
};

const convertDateToEpoch = (dt) => { 
  if (!isDefined(dt) || !isDate(dt)) { 
    return -1;
  }

  return new Date(dt).getTime();
}

export const sortBySortEpoch = createSortNumeric('DESC', {
  invalidLast: true,
  getValue: (s) => s.sortEpoch
});

const flipIfLastNameIsFirst = name => {
  if (!name) { 
    return name;
  }
  const partsName = name.split(',').map(v => v.trim());
  partsName.reverse();
  return partsName.join(' ');
}



/** ************************************
 * HELPERS - REMEDIATIONS
 */

const computeReportRemediationsMeta = (reportAgg) => { 
  const result = {
    reportDate: formatDate(convertDateToEpoch(reportAgg.txtReportDate)),
    complianceDate: formatDate(convertDateToEpoch(reportAgg.txtComplianceDate)),

    reportBcGroupCount: (reportAgg.remediationGroupKeys || []).length,

    mapRemediationTypesCount: {},
    listRemediationTypes: [],
    listRemediationTypesWithCounts: []
  };

  const customerRemediationTypes = selectCustomerRemediationActionTypes(reportAgg.customerName);
  // const groupKeys = reportAgg.remediationGroupKeys;
  const actions = reportAgg.remediationActions || [];

  result.mapRemediationTypesCount = customerRemediationTypes.reduce((acc, type) => {
    acc[type] = 0;
    return acc;
  }, {});

  actions.forEach(({ type }) => {
    result.mapRemediationTypesCount[type] += 1;
  });

  const arrRemediationTypes = customerRemediationTypes.filter(rt => result.mapRemediationTypesCount[rt] && result.mapRemediationTypesCount[rt] > 0);
  arrRemediationTypes.forEach(rt => {
    result.listRemediationTypes.push(rt);
    result.listRemediationTypesWithCounts.push(`x${result.mapRemediationTypesCount[rt]} - ${rt}`);
  });

  return result;
}

/** ************************************
 * MAIN
 */

export const createTableItemAndKey = reportagg => {
  if (!reportagg) { 
    return reportagg;
  }
  
  const key = createReportKey('tbl-row', reportagg);
  const tableItem = {
    tblRowKey: key,
    ...reportagg
  };

  // SORT EPOCHS
  tableItem.reportSortEpoch = convertDateToEpoch(reportagg.txtReportDate);
  tableItem.complianceSortEpoch = convertDateToEpoch(reportagg.txtComplianceDate);
  tableItem.surveySortEpoch = Math.max(convertDateToEpoch(reportagg.surveyStartDate), convertDateToEpoch(reportagg.surveyEndDate));
  tableItem.sortEpoch = tableItem.reportSortEpoch;


  // SURVEY PROPS - computed
  tableItem.surveyStartDate = formatDate(tableItem.surveyStartDate);
  tableItem.surveyEndDate = formatDate(tableItem.surveyEndDate);
  tableItem.txtStartEnd = [tableItem.surveyStartDate, tableItem.surveyEndDate]
    .filter((v, idx, src) => { 
      if (idx === 0) {
        return v;
      }

      return v && src[idx - 1] !== src[idx];
    })
    .join(' :: ');


  // OWNER PROPS - computed
  tableItem.ownerUsername = tableItem.ownerUserid;
  delete tableItem.ownerUserid;
  tableItem.ownerName = flipIfLastNameIsFirst(tableItem.ownerFullName) || tableItem.ownerUsername;
  delete tableItem.ownerFullName;


  // PM PROPS - computed
  tableItem.projectManagerUsername = tableItem.projectManagerUserid;
  delete tableItem.projectManagerUserid;
  tableItem.projectManagerName = flipIfLastNameIsFirst(tableItem.projectManagerFullName) || tableItem.projectManagerUsername;
  delete tableItem.projectManagerFullName;

  // REPORT STATE - computed
  const {
    reportDate,
    complianceDate,

    reportBcGroupCount,

    mapRemediationTypesCount,
    listRemediationTypes,
    listRemediationTypesWithCounts
  } = computeReportRemediationsMeta(tableItem);
  tableItem.reportDate = reportDate;
  tableItem.complianceDate = complianceDate;
  tableItem.reportBcGroupCount = reportBcGroupCount;
  tableItem.reportMapRemediationTypesCount = mapRemediationTypesCount;
  tableItem.arrReportRemediationTypes = listRemediationTypes;
  tableItem.reportRemediationTypes = listRemediationTypes.join('~');
  tableItem.reportRemediationTypesWithCounts = listRemediationTypesWithCounts.join('~');
  // tableItem.reportBcGroupsWithRemediationsCount = bcGroupsWithRemediationsCount;

  return tableItem;
}

export default {
  createTableItemAndKey
};