import { updateBrush } from '../../../../../actions/dataRangeAndZoom';
import { selectChartIds } from '../../Charts/Main/selectors';
import { thunkComputeAllShowDecimatedData } from '../../Charts/slices/ChartsSettings/actions';
import { ID_KEY_APPLICATION } from '../constants';
import {
	selectAppZoomXMinMax,
	selectStoreZoomXYMinMaxSlice
} from '../selectors';
import { setStoreZoomXYMinMaxSlice, setZoomXYMinMax } from './actions';

const isChartId = val => {
	if (typeof val !== 'string') {
		return false;
	}
	const parts = val.split('-');
	return parts[0].toLowerCase() === 'sfchart';
};

const thunkSetZoomXYMinMax = (key, { xMin, xMax, yMin, yMax }) => dispatch => {
	dispatch(setZoomXYMinMax(key, { xMin, xMax, yMin, yMax }));
	dispatch(updateBrush({ xMin, xMax }));
};

const _arrRemoveDuplicates = arr => {
	return Object.keys(
		arr.reduce((acc, item) => {
			acc[item] = true;
			return acc;
		}, {})
	);
};

const _thunkSetAllZoomXMinMax = ({ xMin, xMax }) => (dispatch, getState) => {
	const currentStore = selectStoreZoomXYMinMaxSlice(getState().app) || {};

	// Find All Possible Keys
	const chartIds = selectChartIds(getState().app);
	const currentKeys = Object.keys(currentStore);
	const keys = _arrRemoveDuplicates([
		ID_KEY_APPLICATION,
		...chartIds,
		...currentKeys
	]);

	// Compute new store only containing zoom objects with changes
	const storeChanges = keys.reduce((acc, k) => {
		const { xMin: min, xMax: max } = currentStore[k] || {};
		const hasChanges = xMin !== min || xMax !== max;
		if (hasChanges) {
			acc[k] = { xMin, xMax };
		}
		return acc;
	}, {});

	// build new store and set in bulk
	const changedKeys = Object.keys(storeChanges);
	const hasChanges = changedKeys.length > 0;
	if (hasChanges) {
		const newZoomStore = {
			...currentStore,
			...changedKeys.reduce((acc, k) => {
				acc[k] = {
					...(currentStore[k] || {}),
					...storeChanges[k]
				};
				return acc;
			}, {})
		};
		dispatch(setStoreZoomXYMinMaxSlice(newZoomStore));
	}
};

const thunkSetAllZoomXMinMax = ({ xMin, xMax }) => (dispatch, getState) => {
	const { xMin: currXMin, xMax: currXMax } = selectAppZoomXMinMax(
		getState().app
	);
	const isZoomIn = xMin > currXMin || xMax < currXMax;

	if (isZoomIn) {
		dispatch(thunkComputeAllShowDecimatedData());
		dispatch(_thunkSetAllZoomXMinMax({ xMin, xMax }));
	} else {
		dispatch(_thunkSetAllZoomXMinMax({ xMin, xMax }));
		dispatch(thunkComputeAllShowDecimatedData());
	}
};

const thunkHandleZoomSyncEvent = ({
	key,
	yMin,
	yMax,
	xMin,
	xMax
}) => dispatch => {
	if (isChartId(key)) {
		dispatch(thunkSetZoomXYMinMax(key, { xMin, xMax, yMin, yMax }));
	}
};

export {
	thunkSetZoomXYMinMax,
	thunkHandleZoomSyncEvent,
	thunkSetAllZoomXMinMax
};

export default {
	thunkSetZoomXYMinMax,
	thunkHandleZoomSyncEvent,
	thunkSetAllZoomXMinMax
};
