/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react';
import TransLabel from '../../../common/TransLabel';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const LAMBDA = () => {};
const DEFAULT_HEADER = 'Compliance Date';
const PROP_KEY = 'complianceDate';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER, className }) => (
	<div className={className}><TransLabel text={header} abbrv/></div>
);

const TD = ({ item = EMPTY_OBJECT, onClick = LAMBDA, className }) => {
	const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

	return (
		<td className={className} onClick={onClick}>
			{value}
		</td>
	);
};

const SortFunction = item => {
	return new Date(item[PROP_KEY]);
};

const ColComplianceDate = {
	TH,
	TD,
	CSV_PROP: PROP_KEY,
	DEFAULT_HEADER,
	SortFunction
};

export default ColComplianceDate;
