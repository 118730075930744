export const TYPE_TROUBLESHOOTING = 'Troubleshooting'

export const RemediationActionTypes = [TYPE_TROUBLESHOOTING];

const STATUS_NEW = 'New';
const STATUS_IN_PROGRESS = 'Troubleshooting In Progress';
const STATUS_DATA_REVIEWED = 'Data Reviewed';
const STATUS_AC_MITIGATINO_REQUIRED = 'AC Mitigation Required';
const STATUS_GROUND_BED_REQUIRED = 'Groundbed Required';
const STATUS_ISOLATION_REQUIRED = 'Isolation Required';
const STATUS_COMPLETE = 'Complete'; // this should trigger supervisor review process to close
const STATUS_CLOSED_OUT = 'Closed'; // only accessible through approval process

const DEFAULT_STATUSES = [
  STATUS_NEW,
  STATUS_IN_PROGRESS,
  STATUS_DATA_REVIEWED,
  STATUS_AC_MITIGATINO_REQUIRED,
  STATUS_GROUND_BED_REQUIRED,
  STATUS_ISOLATION_REQUIRED,
  STATUS_COMPLETE,
  STATUS_CLOSED_OUT
];

export const RemediationActionFields = {
    [TYPE_TROUBLESHOOTING]: {
        title: 'Troubleshooting',
        subTitle: '(document troubleshooting steps performed when trying to identify cause of low potential area)',
        field: {
            name: 'actionPlan.troubleshooting',
            placeholder: 'Fill as required',
        }
    },
}


export const RemediationActions = {
    // TYPE_TROUBLESHOOTING
    'DATR': {
      key: 'DATR',
      name: 'Data Reviewed',
      type: TYPE_TROUBLESHOOTING
    },
    'PVER': {
      key: 'PVER',
      name: 'Potential Verification',
      type: TYPE_TROUBLESHOOTING
    },
    'AREC': {
      key: 'AREC',
      name: 'Adjusted/Repaired Rectifier',
      type: TYPE_TROUBLESHOOTING
    },
    'INTT': {
      key: 'INTT',
      name: 'Interference Test',
      type: TYPE_TROUBLESHOOTING
    },
    'DPLS': {
      key: 'DPLS',
      name: 'Depol Survey',
      type: TYPE_TROUBLESHOOTING
    },
    'IACS': {
      key: 'IACS',
      name: 'Identified additional current sources',
      type: TYPE_TROUBLESHOOTING
    },
    'BINS': {
      key: 'BINS',
      name: 'Bond Installed',
      type: TYPE_TROUBLESHOOTING
    },
    'RSVY': {
      key: 'RSVY',
      name: 'Resurveyed (CIS, Spot, TP, Datalogging)',
      type: TYPE_TROUBLESHOOTING
    },
    'OTHR': {
      key: 'OTHR',
      name: 'Other',
      type: TYPE_TROUBLESHOOTING
    },
} 

export const RemediationActionsKeys = Object.keys(RemediationActions);

export const LIST_FILTER_REMEDIATION_STATUSES = DEFAULT_STATUSES;
export const LIST_FILTER_REMEDIATION_ACTIONS = RemediationActionsKeys.map(key => ({
  ...RemediationActions[key],
  key
}));