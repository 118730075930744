/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createActions } from 'redux-actions';
import scanlineUtils from '../scanlineUtils';
import { isViewAsMe } from '../redux/AppAnalysisPage/selectors/viewAs';
import { history } from '../history';
import {
	parseQueryString,
	createNewQueryString,
	clearFromQueryString
} from '../utils/location';
import { injectBodyClassName, removeBodyClassName } from '../utils/htmlBody';

const {
	acSetViewAs,
	requestViewAsList,
	receiveViewAsList,
	requestIsPm,
	receiveIsPm,
	setViewAsErrorMessage
} = createActions({
	AC_SET_VIEW_AS: user => ({ user }),
	REQUEST_VIEW_AS_LIST: () => ({}),
	RECEIVE_VIEW_AS_LIST: users => ({ users }),
	REQUEST_IS_PM: () => ({}),
	RECEIVE_IS_PM: isPm => ({ isPm }),
	SET_VIEW_AS_ERROR_MESSAGE: message => ({ message })
});

const doFetchViewAsList = callback => dispatch => {
	dispatch(requestViewAsList());

	return scanlineUtils.getViewAsList((err, res) => {
		if (err) {
			dispatch(receiveViewAsList([]));
		} else {
			dispatch(receiveViewAsList(res.users));
		}
		callback(err, res);
	});
};

const doFetchViewAsApprovers = callback => (/* dispatch */) => {
	// dispatch(requestViewAsList());

	return scanlineUtils.getViewAsApprovers((err, res) => {
		/* if (err) {
			dispatch(receiveViewAsList([]));
		} else {
			dispatch(receiveViewAsList(res.users));
		}
		 */
		callback(err, res);
	});
};

const doFetchViewAsIsPm = (callback = () => {}) => dispatch => {
	dispatch(requestIsPm());

	return scanlineUtils
		.isPm()
		.then(isPm => {
			dispatch(receiveIsPm(isPm));
			callback(undefined, isPm);
		})
		.catch(() => {
			dispatch(receiveIsPm(false));
			callback(undefined, { isPm: false });
		});
};

const seedViewAs = callback => (dispatch, getState) => {
	const { viewAs: urlParamViewAs } = parseQueryString(document.location.search);
	if (urlParamViewAs) {
		dispatch(acSetViewAs({ userId: urlParamViewAs }));
		setTimeout(() => {
			const { app } = getState();
			if (urlParamViewAs) {
				if (isViewAsMe(app)) {
					const qryStr = clearFromQueryString(
						'viewAs',
						document.location.search
					);
					history.push(qryStr);
					removeBodyClassName('view-as-other');
				} else {
					injectBodyClassName('view-as-other');
				}
			}
		}, 0);
	}
	callback(undefined, 'seed complete');
};

const setViewAs = user => (dispatch, getState) => {
	dispatch(acSetViewAs(user));
	setTimeout(() => {
		const { app } = getState();
		if (isViewAsMe(app)) {
			removeBodyClassName('view-as-other');
			const qryStr = clearFromQueryString('viewAs', document.location.search);
			history.push(qryStr);
			document.location.reload();
		} else {
			injectBodyClassName('view-as-other');
			const qryStr = createNewQueryString(
				['viewAs', user.userId],
				document.location.search
			);
			history.push(qryStr);
			document.location.reload();
		}
	}, 0);
};

export {
	acSetViewAs,
	requestViewAsList,
	receiveViewAsList,
	doFetchViewAsList,
	doFetchViewAsApprovers,
	doFetchViewAsIsPm,
	seedViewAs,
	setViewAs,
	setViewAsErrorMessage,
	requestIsPm,
	receiveIsPm
};
