export const TYPE_RECTIFIER = 'Rectifier Adjustments';
export const TYPE_GROUNDBEDS = 'New Groundbeds & Replacements';
export const TYPE_RECOATS = 'Recoats';
export const TYPE_REPAIRS = 'Repairs';
export const TYPE_MITIGATION = 'Other Mitigation Steps';
export const TYPE_NO_ACTION = 'No Action Required';

export const RemediationActionTypes = [
	TYPE_RECTIFIER,
	TYPE_GROUNDBEDS,
	TYPE_RECOATS,
	TYPE_REPAIRS,
	TYPE_MITIGATION,
	TYPE_NO_ACTION
];

export const RemediationActionFields = {
	[TYPE_RECTIFIER]: {
		title: 'Rectifier Adjustments',
		subTitle:
			'(specify Rectifier ID, line, approximate MP/MLV/Series and Stationing)',
		field: {
			name: 'actionPlan.adjustments',
			placeholder: 'Fill as required'
		}
	},

	[TYPE_GROUNDBEDS]: {
		title: 'New Groundbeds &amp; Groundbed Replacements',
		subTitle: '(specify line and approximate location)',
		field: {
			name: 'actionPlan.replacements',
			placeholder: 'Fill as required'
		}
	},

	[TYPE_RECOATS]: {
		title: 'Recoats',
		subTitle:
			'(specify line, length and approximate MP/MLV/Series and Stationing)',
		field: {
			name: 'actionPlan.recoats',
			placeholder: 'Fill as required'
		}
	},

	[TYPE_REPAIRS]: {
		title: 'Repairs',
		subTitle:
			'(Broken test leads, malfunctioning rectifiers, malfunctioning isolation, damaged bonds, etc.)',
		field: {
			name: 'actionPlan.repairs',
			placeholder: 'Fill as required'
		}
	},

	[TYPE_MITIGATION]: {
		title: 'Other Mitigation Steps',
		subTitle:
			'(AC Mitigation, DC Stray Current Mitigation, electrical isolation, etc.)',
		field: {
			name: 'actionPlan.other',
			placeholder: 'Fill as required'
		}
	},

	[TYPE_NO_ACTION]: {
		title: 'No Action Required',
		subTitle: '(Foreign rectifier not interrupted, etc.)',
		field: {
			name: 'actionPlan.noAction',
			placeholder: 'Fill as required'
		}
	}
};

export const RemediationActions = {
	// TYPE_RECTIFIER
	READ: {
    key: 'READ',
		name: 'Rectifier Adjustments',
		type: TYPE_RECTIFIER
	},

	// TYPE_GROUNDBEDS
	GROU: {
    key: 'GROU',
		name: 'Groundbeds',
		type: TYPE_GROUNDBEDS
	},

	// TYPE_RECOATS
	RECO: {
    key: 'RECO',
		name: 'Recoats',
		type: TYPE_RECOATS
	},

	// TYPE_REPAIRS
	BRTL: {
    key: 'BRTL',
		name: 'Broken test leads',
		type: TYPE_REPAIRS
	},
	MARE: {
    key: 'MARE',
		name: 'Malfunctioning rectifiers',
		type: TYPE_REPAIRS
	},
	MAIS: {
    key: 'MAIS',
		name: 'Malfunctioning isolation',
		type: TYPE_REPAIRS
	},
	DABO: {
    key: 'DABO',
		name: 'Damaged bonds',
		type: TYPE_REPAIRS
	},

	// TYPE_MITIGATION
	ACMI: {
    key: 'ACMI',
		name: 'AC Mitigation',
		type: TYPE_MITIGATION
	},
	DSCM: {
    key: 'DSCM',
		name: 'DC Stray Current Mitigation',
		type: TYPE_MITIGATION
	},
	ELIS: {
    key: 'ELIS',
		name: 'Electrical isolation',
		type: TYPE_MITIGATION
	},

	// TYPE_NO_ACTION
	FRNI: {
    key: 'FRNI',
		name: 'Foreign Rectifier Not Interupted',
		type: TYPE_NO_ACTION
	}
};

export const RemediationActionsKeys = Object.keys(RemediationActions);
