/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { selectPageLoadState } from '../AppStateV2PageLoadState/selectors';
import {
	selectChartPrimarySurveyId,
	selectChartDepolSurveyId,
	selectThreshold,
	selectUseThreshold,
	selectUseShadow,
	selectUseInterpolatedDepol,
	selectUseNormalizedDepol,
	selectBelowCriterionComparator,
	selectSeriesVisibilityDataStoreForChart
} from '../../../AppAnalysisPage/slices/Charts/slices/ChartsSettings/selectors';

import {
	selectCustomGroupRanges,
	selectRemediationActions
} from '../ChartsRemediations/selectors';
import scanlineUtils from '../../../../scanlineUtils';
import { selectAppStateData } from './selectors';
import { GlobalTabSync as AnalysisPageGlobalTabSync } from '../../../../utils/BrowserTabSync/Tabs/AnalysisPage/global';
import { GlobalTabSync as PopoutActionPlanGlobalTabSync} from '../../../../utils/BrowserTabSync/Tabs/PopoutActionPlan/global';


const {
	receiveAppState,
	setAppStateFromChartSettings,
	setIsSavingAppState
} = createActions(
	{
		RECEIVE_APP_STATE: appState => ({ appState }),
		SET_APP_STATE_FROM_CHART_SETTINGS: appState => ({ appState }),
		SET_IS_SAVING_APP_STATE: isSavingAppState => ({ isSavingAppState })
	},
	{ prefix: 'AppStateV2' }
);

const loadAppStateV2FromPageLoadState = () => (dispatch, getState) => {
	const { app } = getState();
	const pageLoadSteate = selectPageLoadState(app);
	dispatch(receiveAppState(pageLoadSteate || {}));
};

const thunkSetAppStateFromChartSettings = chartId => (dispatch, getState) => {
	const { app: stateApp } = getState();
	const identifiers = { chartId };

	const primarySurveyId = selectChartPrimarySurveyId(stateApp, identifiers);
	const depolSurveyId = selectChartDepolSurveyId(stateApp, identifiers);
	const threshold = selectThreshold(stateApp, identifiers);

	const bcComparator = selectBelowCriterionComparator(stateApp, identifiers);
	const useThreshold = selectUseThreshold(stateApp, identifiers);
	const useShadow = selectUseShadow(stateApp, identifiers);
	const useInterpolatedDepol = selectUseInterpolatedDepol(
		stateApp,
		identifiers
	);
	const useNormalizedDepol = selectUseNormalizedDepol(stateApp, identifiers);

	const customGroups = selectCustomGroupRanges(stateApp, chartId);
	const remediationActions = selectRemediationActions(stateApp, chartId);

	const seriesVisibility = selectSeriesVisibilityDataStoreForChart(stateApp, {
		chartId
	});

	const appState = {
		chartId,
		surveyId: primarySurveyId,
    primarySurveyId,
    primarySurveyGuid: primarySurveyId ? primarySurveyId.substring(primarySurveyId.length-36) : undefined,
		depolSurveyId,
		threshold,

		bcComparator,
		useThreshold,
		useShadow,
		useInterpolatedDepol,
		useNormalizedDepol,

		seriesVisibility,

		customGroups,
		remediationActions: {
			[primarySurveyId]: remediationActions
		}
	};

	dispatch(setAppStateFromChartSettings(appState));
};

const delayedSaveAppStateV2 = (lineId, projectId, getState, onSaveComplete) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			const { app: stateApp } = getState();
			const appStateV2 = selectAppStateData(stateApp);

			scanlineUtils
				.saveAppStateV2({ lineId, projectId, data: { content: appStateV2 } })
				.then(res => {
          onSaveComplete();
					resolve(res);
				})
				.catch(err => {
					onSaveComplete();
					reject(err);
				});
		}, 1);
	});
};

const thunkPromiseSaveAppSettings = ({ chartId, lineId, projectId, blnSendRequestReloadState = false } = {}) => (
	dispatch,
	getState
) => {
	dispatch(thunkSetAppStateFromChartSettings(chartId));
	dispatch(setIsSavingAppState(true));
	const onSaveComplete = () => {
    dispatch(setIsSavingAppState(false));
    if (blnSendRequestReloadState) { 
      AnalysisPageGlobalTabSync.sendRequestReloadState();
      PopoutActionPlanGlobalTabSync.sendRequestReloadState();
    }
	};
	return delayedSaveAppStateV2(lineId, projectId, getState, onSaveComplete);
};

export {
	loadAppStateV2FromPageLoadState,
	receiveAppState,
	setAppStateFromChartSettings,
	setIsSavingAppState,
	thunkPromiseSaveAppSettings
};
