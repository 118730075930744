/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  clear
} = createActions(
	{
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export {
  clear
};
