/* eslint-disable no-param-reassign */
import scanlineMapUtils from '../../../mapUtils';

const LEGACY_MV_FUZZY_LOGIC_DATE_THRESHOLD = '01/01/2023';

/** ******************
 * HELPERS
 ******************* */

export const isFauxReading = r => !r || r.isGap || r.isFauxDataPoint;

const getPreviousItem = (arr = [], currtIdx = 0) => {
	if (currtIdx > 0) {
		return arr[currtIdx - 1];
	}

	return undefined;
};

// const getNextItem = (arr = [], currtIdx = 0) =>	(currtIdx < arr.length - 1 ? arr[currtIdx + 1] : undefined);

// const toFeet = distance => +(distance * 1000 * 3.28084).toFixed(2);

/** ******************
 * GEO GAP HELPERS
 ******************* */

const getLastGeoGapSegment = newReadingsWithGeoGaps => {
	if (newReadingsWithGeoGaps.length === 0) {
		newReadingsWithGeoGaps.push([]);
	}

	return newReadingsWithGeoGaps[newReadingsWithGeoGaps.length - 1];
};

/** ******************
 * CHART GAP HELPERS
 ******************* */

const createGapReading = (r, idx) => {
	return { id: r.id + 0.01, uuid: `${r.uuid}~${idx}`, isGap: true };
};

/** ******************
 * PUSH
 ******************* */

// READINGS
const pushToNewReadings = (currReading, newReadings) =>
	newReadings.push(currReading);

// READINGS WITH GEO GAPS
const pushToNewReadingsWithGeoGaps = (
	currReading,
	prevReading,
	newReadingsWithGeoGaps,
	newReadingsWithGeoGapsIndexMap,
	surveyMeta
) => {
	if (surveyMeta.hasGap(currReading, prevReading)) {
		newReadingsWithGeoGaps.push([]);
	}

	const lastGeoGapSegment = getLastGeoGapSegment(newReadingsWithGeoGaps);
	lastGeoGapSegment.push(currReading);
	newReadingsWithGeoGapsIndexMap[currReading.uuid] = [
		newReadingsWithGeoGaps.length - 1,
		lastGeoGapSegment.length - 1
	];
};

// READINGS WITH CHART GAPS
const pushToNewReadingsWithChartGapsAndIndexMapAndSeriesAndSeriesIndexMap = (
	currReading,
	prevReading,
	newReadingsWithChartGaps,
	newReadingsWithChartGapsIndexMap,
	newSeries,
	newSeriesIndexMap,
	surveyMeta
) => {
	const addGapReadingToSeries = newReading => {
		surveyMeta.getReadingKeyProps({ all: true }).forEach(prop => {
			const series = newSeries[prop];
			const seriesIndexMap = newSeriesIndexMap[prop];

			series.push(newReading);
			seriesIndexMap[newReading.uuid] = series.length - 1;
		});
	};

	// this replaces cleanReadings
	const addValidReadingToSeries = newReading => {
		surveyMeta.getReadingKeyProps({ all: true }).forEach(prop => {
			const series = newSeries[prop];
			const seriesIndexMap = newSeriesIndexMap[prop];

			const val = newReading[prop];
			if (val) {
				series.push(newReading);
				seriesIndexMap[newReading.uuid] = series.length - 1;
			}
		});
	};

	if (surveyMeta.isSkipReading(currReading)) {
		const gapReading = createGapReading(
			currReading,
			newReadingsWithChartGaps.length
		);
		newReadingsWithChartGaps.push(gapReading);
		newReadingsWithChartGapsIndexMap[gapReading.uuid] =
			newReadingsWithChartGaps.length - 1;
		addGapReadingToSeries(gapReading);
	} else if (surveyMeta.hasGap(currReading, prevReading)) {
		const gapReading = createGapReading(
			prevReading,
			newReadingsWithChartGaps.length
		);
		newReadingsWithChartGaps.push(gapReading);
		newReadingsWithChartGapsIndexMap[gapReading.uuid] =
			newReadingsWithChartGaps.length - 1;
		addGapReadingToSeries(gapReading);
		newReadingsWithChartGaps.push(currReading);
		newReadingsWithChartGapsIndexMap[currReading.uuid] =
			newReadingsWithChartGaps.length - 1;
		addValidReadingToSeries(currReading);
	} else {
		newReadingsWithChartGaps.push(currReading);
		newReadingsWithChartGapsIndexMap[currReading.uuid] =
			newReadingsWithChartGaps.length - 1;
		addValidReadingToSeries(currReading);
	}
};

const pushToNewReadingsMap = (reading, newReadingsMap) => {
	newReadingsMap[reading.id] = reading;
};

const pushToNewReadingsIndexMap = (idx, reading, newReadingsIndexMap) => {
	newReadingsIndexMap[reading.uuid] = idx;
};

const pushToNewReadingsStationIdIntegers = (
	reading,
	newReadingsStationIdIntegers
) => {
	if (!isFauxReading(reading)) {
		const { id } = reading;
		const idInteger = Math.floor(id);
		newReadingsStationIdIntegers[idInteger] =
			newReadingsStationIdIntegers[idInteger] || [];
		newReadingsStationIdIntegers[idInteger].push(id);
	}
};

const _pushWithComputableProps = (
	idx,
	currReading,
	prevReading,
	dataSets,
	surveyMeta
) => {
	const {
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newReadingsWithGeoGapsIndexMap,

		newReadings,
		newReadingsWithGeoGaps,
		newReadingsWithChartGaps,

		newSeries,
		newSeriesIndexMap,

		newReadingsStationIdIntegers
	} = dataSets;

	// push
	pushToNewReadingsMap(currReading, newReadingsMap);
	pushToNewReadingsIndexMap(idx, currReading, newReadingsIndexMap);
	pushToNewReadingsStationIdIntegers(currReading, newReadingsStationIdIntegers);

	pushToNewReadings(currReading, newReadings);
	pushToNewReadingsWithGeoGaps(
		currReading,
		prevReading,
		newReadingsWithGeoGaps,
		newReadingsWithGeoGapsIndexMap,
		surveyMeta
	);
	pushToNewReadingsWithChartGapsAndIndexMapAndSeriesAndSeriesIndexMap(
		currReading,
		prevReading,
		newReadingsWithChartGaps,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap,
		surveyMeta
	);
};

const _push = (idx, currReading, prevReading, dataSets, surveyMeta) => {
	const {
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newReadingsWithGeoGapsIndexMap,

		newReadings,
		newReadingsWithGeoGaps,
		newReadingsWithChartGaps,

		newSeries,
		newSeriesIndexMap,

		newReadingsStationIdIntegers
	} = dataSets;

	// push
	pushToNewReadingsMap(currReading, newReadingsMap);
	pushToNewReadingsIndexMap(idx, currReading, newReadingsIndexMap);
	pushToNewReadingsStationIdIntegers(currReading, newReadingsStationIdIntegers);

	pushToNewReadings(currReading, newReadings);
	pushToNewReadingsWithGeoGaps(
		currReading,
		prevReading,
		newReadingsWithGeoGaps,
		newReadingsWithGeoGapsIndexMap,
		surveyMeta
	);
	pushToNewReadingsWithChartGapsAndIndexMapAndSeriesAndSeriesIndexMap(
		currReading,
		prevReading,
		newReadingsWithChartGaps,
		newReadingsWithChartGapsIndexMap,
		newSeries,
		newSeriesIndexMap,
		surveyMeta
	);
};

/** ******************
 * CORE
 ******************* */

export const _generateReadingDataStructures = (
	survey = {},
	readings = [],
	fnPush
) => {
	const surveyMeta = scanlineMapUtils.getSurveyMeta(survey);
	const newReadings = [];
	const newReadingsWithGeoGaps = [];
	const newReadingsWithChartGaps = [];
	const newReadingsMap = {};
	const newReadingsIndexMap = {};
	const newReadingsWithChartGapsIndexMap = {};
	const newReadingsWithGeoGapsIndexMap = {};
	const newReadingsStationIdIntegers = {};

	const newSeries = surveyMeta
		.getReadingKeyProps({ all: true })
		.reduce((acc, p) => {
			acc[p] = [];
			return acc;
		}, {});
	const newSeriesIndexMap = surveyMeta
		.getReadingKeyProps({ all: true })
		.reduce((acc, p) => {
			acc[p] = {};
			return acc;
		}, {});

	const dataSets = {
		newReadings,
		newReadingsWithGeoGaps,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newReadingsWithGeoGapsIndexMap,
		newSeries,
		newSeriesIndexMap,
		newReadingsStationIdIntegers
	};

	// @todo - @tobedeleted once mvfuzzy logic is removed
	const aggregates = { cntVoltReadings: 0 };
	if (
		surveyMeta.shouldCheckMVOnLoad &&
		!!survey.psql_insert_date &&
		new Date(survey.psql_insert_date) <
			new Date(LEGACY_MV_FUZZY_LOGIC_DATE_THRESHOLD)
	) {
		readings.forEach(currReading => {
			surveyMeta.computeAggregates(currReading, aggregates);
		});
	}
	const blnStandardizeToMV =
		!!survey.psql_insert_date &&
		new Date(survey.psql_insert_date) <
			new Date(LEGACY_MV_FUZZY_LOGIC_DATE_THRESHOLD) &&
		surveyMeta.shouldCheckMVOnLoad &&
		surveyMeta.shouldStandardizeToMV(
			readings.length,
			aggregates.cntVoltReadings
		);
	if (blnStandardizeToMV) {
		readings.forEach(currReading => {
			surveyMeta.standardizeToMV(currReading);
		});
	}

	// compute readings
	readings.forEach((currReading, idx) => {
		if (idx < readings.length) {
			const prevReading = getPreviousItem(readings, idx);
			surveyMeta.computeProps(survey, prevReading, currReading, idx);
		}
	});

	// push readings
	readings.forEach((currReading, idx) => {
		if (idx < readings.length - 1) {
			const prevReading = getPreviousItem(readings, idx);
			fnPush(idx, currReading, prevReading, dataSets, surveyMeta);
		}
	});

	// push last reading
	if (readings.length > 0) {
		const idx = readings.length - 1;
		const currReading = readings[idx];
		const prevReading = getPreviousItem(readings, idx);
		fnPush(idx, currReading, prevReading, dataSets, surveyMeta);
	}

	// check for sawtooth
	if (
		surveyMeta.checkForSawtooth &&
		(survey.cntZeros || 0) > readings.length * 0.9
	) {
		survey.hasSawtoothData = true;
	}

	return [
		newReadings,
		newReadingsWithGeoGaps,
		newReadingsWithChartGaps,
		newReadingsMap,
		newReadingsIndexMap,
		newReadingsWithChartGapsIndexMap,
		newReadingsWithGeoGapsIndexMap,
		newSeries,
		newSeriesIndexMap,
		newReadingsStationIdIntegers
	];
};

/** ******************
 * MAIN
 ******************* */

export const generateReadingDataStructuresWithComputableProps = (
	survey = {},
	readings = []
) => {
	const surveyMeta = scanlineMapUtils.getSurveyMeta(survey);

	const result = _generateReadingDataStructures(
		survey,
		readings,
		_pushWithComputableProps
	);

	const [newReadings] = result;
	const simplifiedReadings = surveyMeta.createSimplifiedReadings({
		readings: newReadings,
		subtype: survey.survey_subtype
	});

	return [...result, simplifiedReadings];
};

// this assumes we are working on a data set that has had it's properties already computed (example: building data structures for a normalized data set)
export const generateReadingDataStructures = (survey = {}, readings = []) => {
	return _generateReadingDataStructures(survey, readings, _push);
};
