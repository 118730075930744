/* eslint-disable import/prefer-default-export */
import { PRIVATE_ALL_POSSIBLE_COLUMN_KEYS as COLUMN_KEYS } from '../../../../redux/LinesProjectsReports/slices/ReportsAggsColumnSettings/constants';

import ColLine from './ColLine';
import ColCustomer from './ColCustomer';
import ColJobNumber from './ColJobNumber';
import ColStartEnd from './ColStartEnd';
import ColEntity from './ColEntity';
import ColArea from './ColArea';
import ColPipeID from './ColPipeID';
import ColRegulatoryStatus from './ColRegulatoryStatus';
import ColSurveyTypes from './ColSurveyTypes';
import ColCTs from './ColCTs';
import ColPMs from './ColPMs';
import ColTags from './ColTags';
import ColReportType from './ColReportType';
import ColReportStatus from './ColReportStatus';
import ColReportDate from './ColReportDate';
import ColComplianceDate from './ColComplianceDate';
import ColRemediationTypes from './ColRemediationTypes';
import ColRemediationTypeWithCounts from './ColRemediationTypeWithCounts';
import ColTargaGroupStatusesWithCounts from './ColTargaGroupStatusesWithCounts';
import ColProjectType from './ColProjectType';


export function getColumnBase(columnKey) {
	switch (columnKey) {
		case COLUMN_KEYS.CUSTOMER:
			return ColCustomer;
		case COLUMN_KEYS.ENTITY:
			return ColEntity;
		case COLUMN_KEYS.AREA:
			return ColArea;
		case COLUMN_KEYS.PIPE:
			return ColPipeID;
		case COLUMN_KEYS.REGULATORY_STATUS:
			return ColRegulatoryStatus;
		case COLUMN_KEYS.JOB_NUMBER:
			return ColJobNumber;
		case COLUMN_KEYS.START_END:
			return ColStartEnd;
		case COLUMN_KEYS.SURVEY_TYPES:
			return ColSurveyTypes;
		case COLUMN_KEYS.PMS:
			return ColPMs;
		case COLUMN_KEYS.CTS:
			return ColCTs;
		case COLUMN_KEYS.TAGS:
			return ColTags;
		case COLUMN_KEYS.REPORT_TYPE:
			return ColReportType;
		case COLUMN_KEYS.REPORT_STATUS:
			return ColReportStatus;
		case COLUMN_KEYS.REPORT_DATE:
			return ColReportDate;
		case COLUMN_KEYS.COMPLIANCE_DATE:
			return ColComplianceDate;
		case COLUMN_KEYS.REMEDIATION_TYPES:
			return ColRemediationTypes;
    case COLUMN_KEYS.REMEDIATION_TYPE_COUNTS:
      return ColRemediationTypeWithCounts;
    case COLUMN_KEYS.TARGA_GROUP_STATUSES:
      return ColTargaGroupStatusesWithCounts;
    case COLUMN_KEYS.PROJECT_TYPE:
			return ColProjectType;
		case COLUMN_KEYS.LINE:
			return ColLine;
		default:
			throw new Error(
				`Failed to match column key to a react component. key: ${columnKey}`
			);
	}
}
