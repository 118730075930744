/* eslint-disable import/prefer-default-export */
import { selectExpandedRowMapById } from '../selectors';
import { 
  collapseAllRows,
  expandRow,
  collapseRow,
  toggleRow,
} from './actions';
import UTIL from './util';

/**
 * EXANDED ROWS
 */

const thunkCollapseAllRows = () => (dispatch, getState) => { 
  const map = selectExpandedRowMapById(getState());
  if (Object.keys(map).length) { 
    dispatch(collapseAllRows());
  }
}

const thunkExpandRow = (item) => (dispatch, getState) => {
  const expandedRowMapById = selectExpandedRowMapById(getState);
  const id = UTIL.selectId(item);
  if (!expandedRowMapById[id]) { 
    dispatch(expandRow(item));
  }
}

const thunkCollapseRow = (item) => (dispatch, getState) => {
  const expandedRowMapById = selectExpandedRowMapById(getState);
  const id = UTIL.selectId(item);
  if (expandedRowMapById[id]) { 
    dispatch(collapseRow(item));
  }
}

const thunkToggleRow = (item) => (dispatch) => {
  dispatch(toggleRow(item));
}

export {
  thunkCollapseAllRows,
  thunkExpandRow,
  thunkCollapseRow,
  thunkToggleRow,
};
