/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { selectDerivedSurvey, selectSurvey } from '../../../AppAnalysisPage/selectors/surveys';

const EMPTY_OBJECT = {};

const isDefined = v => v !== null && v !== undefined;

// APP STATE
export const selectAppState = (stateApp = {}) => {
	const { appStateV2 = {} } = stateApp;

	return appStateV2;
};

// APP STATE DATA
const helperSelectAppStateData = _.memoize((stateAppMeta = EMPTY_OBJECT) => {
	return {
		chartId: stateAppMeta.chartId,
		primarySurveyId: stateAppMeta.surveyId,
    primarySurveyGuid: stateAppMeta.surveyId ? stateAppMeta.surveyId.substring(stateAppMeta.surveyId.length-36) : undefined,
		depolSurveyId: stateAppMeta.depolSurveyId || undefined,

		actionPlanVersion: stateAppMeta.actionPlanVersion,
		bcComparator:
			stateAppMeta.bcComparator || stateAppMeta.actionPlanComparator,

		threshold: stateAppMeta.threshold,
		useThreshold: stateAppMeta.useThreshold,
		useShadow: stateAppMeta.useShadow,

		useNormalizedDepol: stateAppMeta.useNormalizedDepol,
		useInterpolatedDepol: stateAppMeta.useInterpolatedDepol,

		seriesVisibility: stateAppMeta.seriesVisibility,

		customGroups: stateAppMeta.customGroups,
		remediationGroups: stateAppMeta.remediationGroups,
		remediationActions: stateAppMeta.remediationActions,
		remediationActionTypesByGroup: stateAppMeta.remediationActionTypesByGroup
	};
});

export const selectAppStateData = (stateApp = {}) => {
	const appStateV2 = selectAppState(stateApp) || EMPTY_OBJECT;

	return helperSelectAppStateData(appStateV2.meta || appStateV2);
};

// THRESHOLD
export const selectAppStateThreshold = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { threshold = -850 } = data;

	return threshold;
};

// USE THRESHOLD
// @todo - clean up database so that all expected values are stored
// @todo - remove special case code once confident database is realiably in a clean state
export const selectAppStateUseThreshold = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const depolSurveyId = selectAppStateDepolSurveyId(stateApp);
	const useShadow = selectAppStateUseShadow(stateApp);
	const { useThreshold } = data;

	if (!depolSurveyId) {
		return true;
	}

	if (isDefined(useThreshold)) {
		return useThreshold;
	}

	return !useShadow;
};

// USE SHADOW
// @todo - clean up database so that all expected values are stored
// @todo - remove special case code once confident database is realiably in a clean state
export const selectAppStateUseShadow = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const depolSurveyId = selectAppStateDepolSurveyId(stateApp);
	const { useThreshold, useShadow } = data;

	if (!depolSurveyId) {
		return false;
	}

	if (isDefined(useShadow)) {
		return useShadow;
	}
	return isDefined(useThreshold) ? !useThreshold : true;
};

// REMEDIATION ACTIONS
export const selectAppStateRemediationActions = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { remediationActions = {} } = data;

	return remediationActions && Object.keys(remediationActions).length > 0
		? remediationActions
		: undefined;
};

// CUSTOM GROUPS
export const selectAppStateCustomGroups = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { customGroups = [] } = data;

	return customGroups && customGroups.length > 0 ? customGroups : undefined;
};

// USE INTERPOLATED DEPOL
export const selectAppStateUseInterpolatedDepol = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { useInterpolatedDepol = true } = data;

	return useInterpolatedDepol;
};

// USE NORMALIZED DEPOL
export const selectAppStateUseNormalizedDepol = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { useNormalizedDepol = false } = data;

	return useNormalizedDepol;
};

// PRIMARY SURVEY ID
export const selectAppStatePrimarySurveyId = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { primarySurveyId } = data;

	return primarySurveyId;
};

// PRIMARY SURVEY GUID
export const selectAppStatePrimarySurveyGuid = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { primarySurveyGuid } = data;

	return primarySurveyGuid;
};

// PRIMARY CHART ID
export const selectAppStatePrimaryChartId = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { chartId } = data;

	return chartId;
};

// DEPOL SURVEY ID
export const selectAppStateDepolSurveyId = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { primarySurveyId, depolSurveyId } = data;

	if (!primarySurveyId && !depolSurveyId) {
		return undefined;
	}

	if (depolSurveyId) {
		return depolSurveyId;
	}

	const depolSurvey = selectDerivedSurvey(
		stateApp,
		selectSurvey(stateApp, primarySurveyId),
		'DEPOL'
	);
	return (depolSurvey || {}).id;
};

// IS APP STATE LOADED
export const selectIsAppStateLoaded = (stateApp = {}) => {
	const { isAppStateV2LoadedV2 } = stateApp;

	return isAppStateV2LoadedV2;
};

// IS SAVING APP STATE
export const selectIsSavingAppState = (stateApp = {}) => {
	const { isSavingAppStateV2 } = stateApp;

	return isSavingAppStateV2;
};

// INTERPOLATED DEPOL FOR DEFAULT SURVEY
export const selectUseInterpolatedDepolForDefaultSurvey = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { useNormalizedDepol } = data;

	return useNormalizedDepol;
};

// INTERPOLATED DEPOL FOR SURVEY
export const selectUseInterpolatedDepolForSurvey = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { useInterpolatedDepol } = data;

	return useInterpolatedDepol;
};

// NORMALIZED DEPOL FOR DEFAULT SURVEY
export const selectUseNormalizedDepolForDefaultSurvey = (state = {}) => {
	const { defaultSurvey, useNormalizedDepol } = state;
	return getUseNormalizedDepolFromMap(defaultSurvey, useNormalizedDepol);
};

// NORMALIZED DEPOL FOR SURVEY
export const selectUseNormalizedDepolForSurvey = (survey, appState = {}) => {
	const { useNormalizedDepol } = appState;
	return getUseNormalizedDepolFromMap(survey, useNormalizedDepol);
};

// BELOW CRITERION COMPARATOR
export const selectBcComparator = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { bcComparator } = data;

	return bcComparator;
};

// SERIES VISIBILITY
export const selectAppStateSeriesVisibility = (stateApp = {}) => {
	const data = selectAppStateData(stateApp);
	const { seriesVisibility } = data;

	return seriesVisibility;
};

// @deprecated
// @todo - remove once chart table settings modal is refactored out of Map.jsx
const getUseNormalizedDepolFromMap = (survey, useNormalizedDepolMap = {}) => {
	if (!survey) {
		return false;
	}

	let result = useNormalizedDepolMap[survey.id];
	result = result === undefined ? !survey.disable_auto_align : result;
	return result;
};
