"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.YYYY_MM_DD = exports.TESTS = exports.M_D_YYYY = exports.MM_DD_YYYY = exports.MMM_YYYY = exports.MMM_DD_YYYY = exports.HH_mm_tt = void 0;

var _luxon = require("luxon");
/** ****************************
 * HELPERS
 */


var isDefined = function isDefined(v) {
  return v !== undefined && v !== null;
};

var isEmptyString = function isEmptyString(v) {
  return typeof v === 'string' && v.trim() === '';
};

var isValidInput = function isValidInput(v) {
  return isDefined(v) && !isEmptyString(v);
};

var protect = function protect(fn) {
  return function (date) {
    if (!isValidInput(date)) {
      return date;
    }

    return fn(date);
  };
};

var toISO = function toISO(date) {
  return new Date(date).toISOString();
};

var createDT = function createDT(date) {
  var dt = _luxon.DateTime.fromISO(toISO(date));

  dt.setLocale('en-US');
  return dt;
};
/** ****************************
 * EXPORTABLE FUNCTIONS
 */
// @example 1/1/1970


var M_D_YYYY = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('L/d/yyyy');
}); // @example 01/01/1970

exports.M_D_YYYY = M_D_YYYY;
var MM_DD_YYYY = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('LL/dd/yyyy');
}); // @example Jan 1970

exports.MM_DD_YYYY = MM_DD_YYYY;
var MMM_YYYY = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('LLL yyyy');
}); // @example Jan 1, 1970

exports.MMM_YYYY = MMM_YYYY;
var MMM_DD_YYYY = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('LLL d, yyyy');
}); // @example 1970-01-01

exports.MMM_DD_YYYY = MMM_DD_YYYY;
var YYYY_MM_DD = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('yyyy-LL-dd');
}); // eslint-disable-next-line camelcase

exports.YYYY_MM_DD = YYYY_MM_DD;
var HH_mm_tt = protect(function (date) {
  var dt = createDT(date);
  return dt.toFormat('HH:mm a');
});
exports.HH_mm_tt = HH_mm_tt;
var TESTS = {
  toISO: toISO,
  createDT: createDT,
  M_D_YYYY: M_D_YYYY,
  MM_DD_YYYY: MM_DD_YYYY,
  MMM_YYYY: MMM_YYYY,
  MMM_DD_YYYY: MMM_DD_YYYY,
  YYYY_MM_DD: YYYY_MM_DD,
  HH_mm_tt: HH_mm_tt
};
exports.TESTS = TESTS;
var _default = {
  M_D_YYYY: M_D_YYYY,
  MM_DD_YYYY: MM_DD_YYYY,
  MMM_YYYY: MMM_YYYY,
  MMM_DD_YYYY: MMM_DD_YYYY,
  YYYY_MM_DD: YYYY_MM_DD,
  HH_mm_tt: HH_mm_tt,
  TESTS: TESTS
};
exports["default"] = _default;