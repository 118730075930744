const TAB = 'PopoutMap';

const _getMethod = (fname) => {
  return ((window?.TabSync || {})[TAB] || {})[fname];
}

const sendRequestMapPopoutStateSync = () => {
  const _sendRequestMapPopoutStateSync = _getMethod('sendRequestMapPopoutStateSync');
  if (_sendRequestMapPopoutStateSync) { 
    _sendRequestMapPopoutStateSync();
  }
}

const GlobalTabSync = {
  sendRequestMapPopoutStateSync
}

export default GlobalTabSync;

export { 
  GlobalTabSync
}
