import _ from 'lodash';
import { selectMapPopoutState } from '../selectors';
import { selectSurvey, selectSurveys } from '../../../../AppAnalysisPage/selectors/surveys';

/**
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

/**
 * CHECKED SURVEYS
 */

const _createMapCheckedSurveys = (surveys) => { 
  return (surveys || []).reduce((acc, s) => {
    if (isDefined(s?.id)) {
      acc[s.id] = true;
    }
    return acc;
  }, {})
}

export const didCheckedSurveysChange = (state, arrCheckedSurveys) => {
  const { selectedSurveys } = selectMapPopoutState(state);
  const _mapCheckedSurveys = _createMapCheckedSurveys(arrCheckedSurveys);

  const isSame = (selectedSurveys || []).every(s => {
    return (s.checked && _mapCheckedSurveys[s?.id]) || (!s.checked && !_mapCheckedSurveys[s?.id])
  });

  return !isSame;
}

export const computeSurveysUpdateChecked = (state, arrCheckedSurveys) => { 
  const surveys = selectSurveys(state);
  const _mapCheckedSurveys = _createMapCheckedSurveys(arrCheckedSurveys);
  return surveys.map(s => {
    const didChange = (s.checked && !_mapCheckedSurveys[s?.id]) || (!s.checked && _mapCheckedSurveys[s?.id]);
    if (!didChange) {
      return s;
    }

    const newChecked = _mapCheckedSurveys[s?.id] || false;
    return {
      ...s,
      checked: newChecked,
      isCopyListOpen: !newChecked ? false : s.isCopyListOpen,
      isSettingsOpen: !newChecked ? false : s.isSettingsOpen
    };
  });
}

export const injectCheckedSurveyChange = (state, newStateProps, arrCheckedSurveys) => { 
  if (!didCheckedSurveysChange(state, arrCheckedSurveys)) {
    return newStateProps;
  }

  const surveys = computeSurveysUpdateChecked(state, arrCheckedSurveys)

  return {
    ...newStateProps,
    surveys,
  }
}

/**
 * selectedReadingSurvey
 */

export const didSelectedReadingSurveyChange = (state, selectedReadingSurvey) => {
  const { selectedReadingSurvey: _selectedReadingSurvey } = selectMapPopoutState(state);
  return _selectedReadingSurvey?.id !== selectedReadingSurvey?.id;
}

export const injectSelectedReadingSurveyChange = (state, newStateProps, selectedReadingSurvey) => { 
  if (!didSelectedReadingSurveyChange(state, selectedReadingSurvey)) {
    return newStateProps;
  }

  return {
    ...newStateProps,
    selectedReadingSurvey: selectedReadingSurvey?.id ? selectSurvey(state, selectedReadingSurvey.id) : undefined,
  }
}

/**
 * selectedReading
 */

export const didSelectedReadingChange = (state, selectedReadingSurvey, selectedReading) => {
  const { selectedReading: _selectedReading } = selectMapPopoutState(state);
  return didSelectedReadingSurveyChange(state, selectedReadingSurvey) || _selectedReading?.id !== selectedReading?.id;
}

export const injectSelectedReadingChange = (state, newStateProps, selectedReadingSurvey, selectedReading) => { 
  if (!didSelectedReadingChange(state, selectedReadingSurvey, selectedReading)) {
    return newStateProps;
  }

  return {
    ...newStateProps,
    selectedReadingSurvey: selectedReadingSurvey?.id ? selectSurvey(state, selectedReadingSurvey.id) : undefined,
    selectedReading
  }
}

/**
 * focusedChart
 */

export const didFocusedChartChange = (state, focusedChart) => {
  const { focusedChart: _focusedChart } = selectMapPopoutState(state);
  return _focusedChart !== focusedChart;
}

export const injectFocusedChartChange = (state, newStateProps, focusedChart) => { 
  if (!didFocusedChartChange(state, focusedChart)) {
    return newStateProps;
  }

  return {
    ...newStateProps,
    focusedChart
  }
}

/**
 * hoveredChart
 */

export const didHoveredChartChange = (state, hoveredChart) => {
  const { hoveredChart: _hoveredChart } = selectMapPopoutState(state);
  return _hoveredChart !== hoveredChart;
}

export const injectHoveredChartChange = (state, newStateProps, hoveredChart) => { 
  if (!didHoveredChartChange(state, hoveredChart)) {
    return newStateProps;
  }

  return {
    ...newStateProps,
    hoveredChart
  }
}

/**
 * boundaryXMinXMax
 */

export const didBoundaryXMinXMaxChange = (state, boundaryXMinXMax) => { 
  const { boundaryXMinXMax: _boundaryXMinXMax } = selectMapPopoutState(state);
  return _.isEqual(_boundaryXMinXMax, boundaryXMinXMax);
}

export const injectBoundaryXMinXMaxChange = (state, newStateProps, boundaryXMinXMax) => { 
  if (!didBoundaryXMinXMaxChange(state, boundaryXMinXMax)) {
    return newStateProps;
  }

  return {
    ...newStateProps,
    boundaryXMinXMax
  }
}

/**
 * DID MAP STATE CHANGE
 */

export const didMapPopoutStateChange = (state, mapPopoutState) => { 
  const {
    selectedSurveys,
    selectedReadingSurvey,
    selectedReading,
    focusedChart,
    hoveredChart,
    boundaryXMinXMax
  } = mapPopoutState

  return (
     didCheckedSurveysChange(state, selectedSurveys) ||
     didSelectedReadingSurveyChange(state, selectedReadingSurvey) ||
     didSelectedReadingChange(state, selectedReadingSurvey, selectedReading) ||
     didFocusedChartChange(state, focusedChart) ||
     didHoveredChartChange(state, hoveredChart) ||
     didBoundaryXMinXMaxChange(state, boundaryXMinXMax)
  )
}
